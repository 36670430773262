import moment from "moment";
import OpenDeleteButtonCellRenderer from "@/components/table/helper-components/OpenDeleteButtonCellRenderer.vue";
import OpenButtonCellRenderer from "@/components/table/helper-components/OpenButtonCellRenderer";
import { getButtonColumnConfig } from "@/utils/TableConfig";

function momentValueFormatter(params) {
  if (typeof params.value === "number") {
    return moment(params.value).format("MMM DD, YYYY");
  }

  return params.value;
}

function expiredValidFormatter(params) {
  if (params.value.testResult === "positive") {
    return "Invalid";
  }
  return params.value.testValidity ? "Valid" : "Expired";
}

export const CovidTestTableConfig = {
  defaultColDef: {
    minWidth: 150,
    sortable: true,
    resizable: true,
  },

  loadingOverlay:
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  noRowsOverlay: "<span></span>",
  verificationColumns: [
    {
      headerName: "",
      field: "id",
      cellRenderer: "openButtonCellRenderer",
      cellRendererParams: {
        icon: "download",
        title: "Open",
      },
      ...getButtonColumnConfig({ buttons: 1 }),
    },
    {
      headerName: "File Name",
      field: "name",
      minWidth: 170,
    },
    {
      headerName: "Test Date",
      field: "date",
      maxWidth: 130,
      sort: "desc",
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "Valid Through",
      field: "validThrough",
      maxWidth: 130,
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "Validity",
      field: "validity",
      maxWidth: 100,
      valueFormatter: expiredValidFormatter,
      cellStyle: (params) =>
        params.value.testValidity && params.value.testResult === "negative"
          ? { color: "green" }
          : { color: "red" },
    },
    {
      headerName: "Test Result",
      field: "testResult",
      maxWidth: 130,
    },
  ],

  columns: [
    {
      headerName: "",
      field: "id",
      cellRenderer: "openDeleteButtonCellRenderer",
      cellRendererParams: {
        openIcon: "download",
        deleteIcon: "delete",
      },
      ...getButtonColumnConfig({ buttons: 2 }),
    },
    {
      headerName: "File Name",
      field: "name",
      minWidth: 170,
    },
    {
      headerName: "Date",
      field: "date",
      maxWidth: 130,
      sort: "desc",
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "Valid Through",
      field: "validThrough",
      maxWidth: 130,
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "Validity",
      field: "validity",
      maxWidth: 100,
      valueFormatter: expiredValidFormatter,
      cellStyle: (params) =>
        params.value.testValidity && params.value.testResult === "negative"
          ? { color: "green" }
          : { color: "red" },
    },
    {
      headerName: "Test Result",
      field: "testResult",
      maxWidth: 130,
    },
  ],

  frameworkComponents: {
    openDeleteButtonCellRenderer: OpenDeleteButtonCellRenderer,
    openButtonCellRenderer: OpenButtonCellRenderer,
  },
};
