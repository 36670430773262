import { render } from "./CovidTestCompInputs.vue?vue&type=template&id=bd5f0292"
import script from "./CovidTestCompInputs.vue?vue&type=script&lang=js"
export * from "./CovidTestCompInputs.vue?vue&type=script&lang=js"
script.render = render

export default script
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import QStep from 'quasar/src/components/stepper/QStep.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QStepperNavigation from 'quasar/src/components/stepper/QStepperNavigation.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QField from 'quasar/src/components/field/QField.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QStepper,QStep,QForm,QFile,QIcon,QSelect,QInput,QStepperNavigation,QBtn,QField,QRadio});
