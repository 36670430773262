import { render } from "./InnerLoadingComp.vue?vue&type=template&id=1f8ffd2a"
import script from "./InnerLoadingComp.vue?vue&type=script&lang=js"
export * from "./InnerLoadingComp.vue?vue&type=script&lang=js"

import "./InnerLoadingComp.vue?vue&type=style&index=0&id=1f8ffd2a&lang=scss"
script.render = render

export default script
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QSpinnerOval from 'quasar/src/components/spinner/QSpinnerOval.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInnerLoading,QSpinnerOval});
