<template>
  <q-layout view="hHh lpR fFf">
    <auto-logout v-if="userSignedIn" />
    <app-header
      :display-name="user.displayName"
      :email="user.email"
      :google-profile-image="user.profileImage"
      :user-signed-in="userSignedIn"
      @signOutClicked="signOut"
    />
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from "vue";
import { useAuthenticationHelper } from "./utils/helpers/useAuthenticationHelper";
import AppHeader from "@/components/AppHeader";
import NotificationHelper from "@/utils/helpers/NotificationHelper";
import AutoLogout from "./components/AutoLogout";

export default {
  name: "LayoutDefault",
  components: { AppHeader, AutoLogout },
  setup() {
    const { signOut, userSignedIn, user } = useAuthenticationHelper();

    return {
      leftDrawerOpen: ref(false),
      signOut,
      userSignedIn,
      user,
    };
  },
  watch: {
    $route(newRoute) {
      if (newRoute.meta.title) {
        document.title = `Safe Workplace - ${newRoute.meta.title}`;
      } else {
        document.title = `Safe Workplace`;
      }

      NotificationHelper.dismiss();
    },
  },
};
</script>
