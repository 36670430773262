import moment from "moment";
const NUMBER_OF_DAY_TEST_VALID = 7;
const NUMBER_OF_DAY_DOCTORS_NOTE_VALID = 80;
const DAY_UNTIL_VACCINATION_VALID = 15;

const NEGATIVE_TEST_STATUS = "negative";
// const POSITIVE_TEST_STATUS = "positive";

export default {
  testIsValid(documentDate, testStatus) {
    return (
      this.testCurrentlyWithinTimeFrame(documentDate) &&
      testStatus === NEGATIVE_TEST_STATUS
    );
  },
  doctorsNoteIsValid(documentDate) {
    return this.currentlyWithinTimeFrame(
      documentDate,
      NUMBER_OF_DAY_DOCTORS_NOTE_VALID
    );
  },
  vaccinationIsValid(documentDate, vaccinationStatus) {
    const now = moment();
    return (
      vaccinationStatus === "Full" &&
      now.diff(documentDate, "days") >= DAY_UNTIL_VACCINATION_VALID
    );
  },
  testCurrentlyWithinTimeFrame(documentDate) {
    return this.currentlyWithinTimeFrame(
      documentDate,
      NUMBER_OF_DAY_TEST_VALID
    );
  },
  currentlyWithinTimeFrame(startDate, days) {
    const now = moment();
    const expirationDate = this.getExpirationDateAfterNDays(startDate, days);
    return !now.isAfter(expirationDate);
  },
  getTestExpirationDate(startDate) {
    return this.getExpirationDateAfterNDays(
      startDate,
      NUMBER_OF_DAY_TEST_VALID
    );
  },
  getDoctorsClearanceExpirationDate(startDate) {
    return this.getExpirationDateAfterNDays(
      startDate,
      NUMBER_OF_DAY_DOCTORS_NOTE_VALID
    );
  },
  getFullyVaccinatedDate(startDate) {
    return this.getExpirationDateAfterNDays(
      startDate,
      DAY_UNTIL_VACCINATION_VALID
    );
  },
  getExpirationDateAfterNDays(startDate, days) {
    const momentStartDate = moment(startDate);
    return momentStartDate.add(days, "days").endOf("day");
  },
};
