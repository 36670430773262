<template>
  <q-field borderless :label="label" label-color="primary" stack-label>
    <template #control>
      <div class="self-center full-width no-outline">
        {{ value }}
      </div>
    </template>
    <template v-if="$slots.prepend" #prepend>
      <slot name="prepend" />
    </template>
    <template v-if="$slots.append" #append>
      <slot name="append" />
    </template>
    <template v-if="$slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="$slots.hint" #hint>
      <slot name="hint" />
    </template>
  </q-field>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FieldComp",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
});
</script>

<style lang="scss"></style>
