import { render } from "./UserProfileNavigationDropdown.vue?vue&type=template&id=7d49c020"
import script from "./UserProfileNavigationDropdown.vue?vue&type=script&lang=js"
export * from "./UserProfileNavigationDropdown.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnDropdown,QAvatar,QBtn,QSeparator});
