<template>
  <dialog-comp
    v-model="dialogVisible"
    action-button-label="I'm Here"
    max-width="600px"
    modal-title="Still Here?"
    no-cancel-button
    suppress-close-on-action-button-click
    width="90%"
    @cancel-clicked="cancelWarning"
    @confirm-clicked="cancelWarning"
  >
    <template #content>
      <div>
        You will be automatically logged out in
        <span style="font-weight: bold">
          {{ remaining }}
        </span>
        due to inactivity. If you're still here, click to keep the session
        active.
      </div>
    </template>
  </dialog-comp>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import moment from "moment";
import Timer from "@/utils/Timer";
import IdleTimer from "@/utils/IdleTimer";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import DialogComp from "@/components/common/DialogComp";

// Idle warning after 50 minutes
const IDLE_TIMER_MS = 50 * 60 * 1000;
// Logout after 10 more minutes
const LOGOUT_TIMER_MS = 10 * 60 * 1000;

export default defineComponent({
  name: "AutoLogout",
  components: { DialogComp },
  setup() {
    const dialogVisible = ref(false);
    const warningTimer = new IdleTimer(IDLE_TIMER_MS);
    const logoutTimer = new Timer(LOGOUT_TIMER_MS);
    const remaining = ref(null);
    const { signOut } = useAuthenticationHelper();

    warningTimer.addEventListener("end", () => {
      dialogVisible.value = true;

      warningTimer.disable();
      logoutTimer.start();
    });

    logoutTimer.addEventListener("end", () => {
      dialogVisible.value = false;
      signOut();
    });

    function cancelWarning() {
      dialogVisible.value = false;

      warningTimer.enable();
      warningTimer.reset();
      logoutTimer.stop();
    }

    watch(logoutTimer.remaining, (newValue) => {
      if (newValue !== null) {
        remaining.value = moment
          .duration(newValue, "ms")
          .humanize()
          .replace("a few seconds", "less than a minute");
      } else {
        remaining.value = null;
      }
    });

    return {
      dialogVisible,
      cancelWarning,
      remaining,
    };
  },
});
</script>

<style scoped lang="scss"></style>
