<template>
  <q-page class="flex flex-center row q-pa-md">
    <div class="col-xs-12 col-sm-8 col-md-5 col-lg-4">
      <card-comp :fit="false" title="Not Found">
        <template #content>
          <q-card-section>
            <div class="text-subtitle1">
              We couldn't find that resource. Please check the spelling and try
              again, or go back.
            </div>
            <a class="nav-back q-mt-md" href="#" @click="back">
              <svg
                fill="#FFFFFF"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
                />
              </svg>
              <span class="q-ml-sm">Go back</span>
            </a>
          </q-card-section>
        </template>
      </card-comp>
    </div>
  </q-page>
</template>

<script>
import { useRouter } from "vue-router";
import CardComp from "@/components/common/CardComp";

export default {
  name: "NotFoundPage",
  components: {
    CardComp,
  },
  setup() {
    const router = useRouter();

    return {
      back: router.back,
    };
  },
};
</script>

<style scoped lang="scss">
.nav-back {
  display: inline-flex;
  align-items: center;
  padding: 4px 16px 4px 8px;
  // (Back arrow height + (4px padding * 2)) / 2
  border-radius: 16px;
  background: var(--q-primary);
  color: #fff;
  text-decoration: none;
  line-height: unset;
}
</style>
