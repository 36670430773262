<template>
  <q-page class="q-pa-md column">
    <div class="row grow-1" style="flex-grow: 1">
      <div class="col-12">
        <card-comp>
          <template #content>
            <div class="full-height column">
              <div class="col-auto q-pl-md q-pr-md q-pt-md q-pb-none">
                <div class="row justify-between">
                  <div class="col-auto">
                    <span class="text-h4 text-primary">Admin Accounts</span>
                  </div>
                  <div class="col-xs-12 col-md-7 col-lg-5 q-mr-none">
                    <div class="row justify-between q-col-gutter-md">
                      <div class="col">
                        <q-input
                          v-model="tableSearchValue"
                          class="full-height"
                          dense
                          label="Search Admin Accounts"
                          outlined
                        />
                      </div>
                      <div class="col-auto">
                        <q-btn
                          class="full-height full-width"
                          color="primary"
                          :disable="loading"
                          icon="add"
                          label="Add Admin Account"
                          outline
                          @click="addAction"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <q-card-section class="full-height">
                  <table-comp
                    class="ag-theme-alpine grid full-height"
                    :column-defs="computedColumns"
                    :context="context"
                    :default-col-def="defaultColumnDef"
                    :framework-components="frameworkComponents"
                    :loading="loading"
                    :overlay-loading-template="overlayLoadingTemplate"
                    :overlay-no-rows-template="overlayNoRowsTemplate"
                    :row-data="adminTypeAccounts"
                    @grid-ready="loadAdminAccountInformation"
                    ref="adminTypeGridRef"
                  />
                </q-card-section>
              </div>
            </div>
          </template>
        </card-comp>
      </div>
    </div>
  </q-page>
  <admin-dialog
    v-model="openAddEditModal"
    :admin-account="selectedEntry"
    :email="selectedEntry.email"
    @cancel-clicked="cancelClicked"
    @update-clicked="updateClicked"
  />
  <dialog-comp
    v-model="openDeleteConfirm"
    action-button-label="Remove"
    max-width="500px"
    modal-text="Are you sure you want to remove this admin account?"
    modal-title="Remove Account"
    @confirm-clicked="deleteEntry"
  />
</template>

<script>
import _ from "lodash";
import router from "@/router";
import { computed, defineComponent, ref, watch } from "vue";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import * as AccountService from "@/services/AccountService";
import CardComp from "@/components/common/CardComp";
import TableComp from "@/components/table/TableComp";
import { AdminAccountTableConfig } from "@/views/admin/AdminAccountTableConfig";
import AdminDialog from "@/views/admin/AdminDialog";
import DialogComp from "@/components/common/DialogComp";
import { useAdminTableAddEditFunctionality } from "@/utils/helpers/useAdminTableAddEditFunctionality";

export default defineComponent({
  name: "AdminAccounts",
  components: { TableComp, CardComp, AdminDialog, DialogComp },
  setup() {
    const frameworkComponents = AdminAccountTableConfig.frameworkComponents;
    const overlayLoadingTemplate = AdminAccountTableConfig.overlay;
    const overlayNoRowsTemplate = AdminAccountTableConfig.overlay;
    const defaultColumnDef = AdminAccountTableConfig.defaultColDef;

    const adminTypeAccounts = ref([]);
    const loading = ref(false);
    function loadAdminAccountInformation() {
      loading.value = true;
      AccountService.getAdminTypeAccounts()
        .then((response) => {
          adminTypeAccounts.value = convertToFlattenedObject(response);
        })
        .catch((error) => {
          if (error.code === "permission-denied") {
            router.push("/unauthorized");
          }
        })
        .finally(() => (loading.value = false));
    }

    function convertToFlattenedObject(response) {
      return response.map((account) => {
        const newObject = {};
        newObject.email =
          Object.keys(account) && Object.keys(account)[0]
            ? Object.keys(account)[0]
            : null;
        if (
          Object.values(account) &&
          Object.values(account)[0] &&
          Object.values(account)[0].data
        ) {
          _.assign(newObject, Object.values(account)[0].data);
        }
        return newObject;
      });
    }

    const tableSearchValue = ref();
    const adminTypeGridRef = ref();
    watch(
      () => tableSearchValue.value,
      (newVal) => adminTypeGridRef.value.setQuickFilter(newVal)
    );

    const { user } = useAuthenticationHelper();
    const computedColumns = computed(() => {
      return user.value && user.value.email
        ? AdminAccountTableConfig.columns.map((col) => {
            let newCol = col;
            if (col.cellRenderer === "nodeDeleteEditCellRenderer") {
              newCol.cellRendererParams = {
                email: user.value.email,
              };
            }
            return newCol;
          })
        : AdminAccountTableConfig.columns;
    });

    const AdminTable = ref();
    let {
      addAction,
      selectedEntry,
      openAddEditModal,
      updateClicked,
      editAction,
      editMode,
      openDeleteConfirm,
      deleteEntry,
      deleteAction,
    } = useAdminTableAddEditFunctionality(
      AdminTable,
      {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        type: "ADMIN",
      },
      loadAdminAccountInformation,
      loading
    );

    function cancelClicked() {
      openAddEditModal.value = false;
      selectedEntry = null;
    }

    return {
      loadAdminAccountInformation,
      loading,
      tableSearchValue,
      adminTypeAccounts,
      adminTypeGridRef,
      frameworkComponents,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      defaultColumnDef,
      selectedEntry,
      openAddEditModal,
      updateClicked,
      cancelClicked,
      editAction,
      addAction,
      editMode,
      openDeleteConfirm,
      deleteEntry,
      deleteAction,
      computedColumns,
    };
  },
  data() {
    return {
      context: {
        componentParent: this,
      },
    };
  },
  beforeCreate() {
    const { email } = useAuthenticationHelper();
    watch(
      () => email.value,
      (newValue) => {
        AccountService.getAccount(`${newValue}`).then((response) => {
          if (response.type !== AccountService.ADMIN_TYPE || !response.type) {
            router.push("/unauthorized");
          }
        });
      }
    );
  },
});
</script>

<style lang="scss"></style>
