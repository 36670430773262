import { render } from "./OpenButtonCellRenderer.vue?vue&type=template&id=d5d60e52&scoped=true"
import script from "./OpenButtonCellRenderer.vue?vue&type=script&lang=js"
export * from "./OpenButtonCellRenderer.vue?vue&type=script&lang=js"

import "./OpenButtonCellRenderer.vue?vue&type=style&index=0&id=d5d60e52&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-d5d60e52"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn});
