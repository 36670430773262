import { render } from "./VaccinationSummaryCard.vue?vue&type=template&id=63b00bb5&scoped=true"
import script from "./VaccinationSummaryCard.vue?vue&type=script&lang=js"
export * from "./VaccinationSummaryCard.vue?vue&type=script&lang=js"

import "./VaccinationSummaryCard.vue?vue&type=style&index=0&id=63b00bb5&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-63b00bb5"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QList,QItem,QItemSection,QIcon,QItemLabel});
