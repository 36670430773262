<template>
  <dialog-comp
    v-model="modalOpen"
    :action-button-label="actionButtonLabel"
    full-height
    max-width="1200px"
    :modal-title="modalTitle"
    suppress-close-on-action-button-click
    width="100%"
    @cancel-clicked="onReset"
    @confirm-clicked="onSubmit"
  >
    <template #content>
      <div>Please upload a file to add contractors using an excel sheet:</div>
      <div class="q-pa-md">
        <q-file
          v-model="file"
          accept=".xlsx, .xlx,.csv"
          bottom-slots
          filled
          label="Contractors List"
          style="max-width: 300px"
          @change="fileUploaded"
          @rejected="onRejected"
        >
          <template #prepend>
            <q-icon name="attach_file" />
          </template>
        </q-file>
      </div>
      <bulk-dialog-contractor-row
        v-for="(contractor, index) in contractorList"
        v-model:contractor="contractorList[index]"
        :index="index"
        @delete="deleteContractor($event)"
        :key="index"
        :ref="setBulkDialogContractorRowRef"
      />
      <div>
        <q-btn
          class="float-right"
          color="primary"
          icon="add"
          label="Add Row"
          outline
          @click="addContractor"
        />
      </div>
    </template>
  </dialog-comp>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import DialogComp from "../../components/common/DialogComp.vue";
import { useSyncModelValue } from "@/utils/helpers/useSyncModelValue";
import * as AccountService from "@/services/AccountService";
import { useValidateInputs } from "@/utils/helpers/useValidateInputs";
import readXlsxFile from "read-excel-file";
import NotificationHelper from "@/utils/helpers/NotificationHelper";
import BulkDialogContractorRow from "@/views/admin/BulkDialogContractorRow";

export default defineComponent({
  name: "ContractorDialog",
  components: { BulkDialogContractorRow, DialogComp },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    vendorId: {
      type: String,
      default: null,
    },
  },
  emits: [
    "update:modelValue",
    "cancelClicked",
    "updateClicked",
    "update-clicked",
  ],
  setup: function (props, { emit }) {
    const modalOpen = ref(props.modelValue);
    const commentTypeModel = ref();
    const commentTypes = ref([]);

    useSyncModelValue(
      props,
      "modelValue",
      emit,
      "update:modelValue",
      modalOpen
    );

    function cancelClicked() {
      emit("cancelClicked");
    }

    const contractorList = ref([
      {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        vendorId: props.vendorId,
      },
    ]);

    function addContractor() {
      contractorList.value.push({
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        vendorId: props.vendorId,
      });
    }

    function deleteContractor(index) {
      contractorList.value.splice(index, 1);
    }

    function requiredRule(val) {
      return !!val || "Required";
    }

    const requiredRules = [(val) => requiredRule(val)];
    const requiredRulesForEmail = [
      (val) => requiredRule(val),
      (val) =>
        AccountService.doesAccountWithEmailExistsAndIsNotDeleted(val).then(
          (res) => !res || "Email already exists"
        ),
    ];

    function onSubmit() {
      validateAndRunFunctionIfNoError(getEntriesAndEmit);
    }

    function getEntriesAndEmit() {
      const updatedEntriesArray = [];
      contractorList.value.forEach((contractor) => {
        updatedEntriesArray.push({
          email: contractor.email.trim(),
          account: {
            firstName: contractor.firstName,
            lastName: contractor.lastName,
            phoneNumber: contractor.phoneNumber,
            vendorId: props.vendorId,
            type: "CONTRACTOR",
          },
        });
      });
      emit("update-clicked", updatedEntriesArray);
    }

    function onReset() {
      resetValidation();
      cancelClicked();
    }

    function clearInputs() {
      contractorList.value = [
        {
          email: "",
          firstName: "",
          lastName: "",
          phoneNumber: "",
        },
      ];
      file.value = null;
    }

    const modalTitle = "Add Multiple Contractors";
    const actionButtonLabel = "Add All";
    const file = ref(null);

    function fileUploaded() {
      let xlsxfile = file.value;
      readXlsxFile(xlsxfile).then((rows) => {
        for (let i = 1; i < rows.length; i++) {
          if (i === 1) {
            contractorList.value[0] = {
              email: `${rows[i][0]}`,
              firstName: `${rows[i][1]}`,
              lastName: `${rows[i][2]}`,
              phoneNumber: rows[i][3],
              vendorId: props.vendorId,
            };
          } else {
            contractorList.value.push({
              email: `${rows[i][0]}`,
              firstName: `${rows[i][1]}`,
              lastName: `${rows[i][2]}`,
              phoneNumber: rows[i][3],
              vendorId: props.vendorId,
            });
          }
        }
      });
    }

    function onRejected() {
      NotificationHelper.createErrorNotification(
        "Invalid File uploaded. Please upload a xlsx file!"
      );
    }

    watch(
      () => props.modelValue,
      (newVal) => {
        if (!newVal) {
          clearInputs();
        }
      }
    );

    let bulkDialogContractorRows = [];
    const setBulkDialogContractorRowRef = (el) => {
      if (el && !bulkDialogContractorRows.find((item) => item.value === el)) {
        bulkDialogContractorRows.push(ref(el));
      }
    };

    const { resetValidation, validateAndRunFunctionIfNoError, hasError } =
      useValidateInputs(bulkDialogContractorRows);

    return {
      setBulkDialogContractorRowRef,
      modalOpen,
      cancelClicked,
      commentTypes,
      commentTypeModel,
      onSubmit,
      onReset,
      modalTitle,
      actionButtonLabel,
      requiredRules,
      addContractor,
      contractorList,
      deleteContractor,
      file,
      fileUploaded,
      onRejected,
      requiredRulesForEmail,
      hasError,
    };
  },
});
</script>

<style lang="scss"></style>
