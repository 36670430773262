import moment from "moment";
import OpenDeleteButtonCellRenderer from "@/components/table/helper-components/OpenDeleteButtonCellRenderer.vue";
import OpenButtonCellRenderer from "@/components/table/helper-components/OpenButtonCellRenderer";
import { getButtonColumnConfig } from "@/utils/TableConfig";

function momentValueFormatter(params) {
  if (typeof params.value === "number") {
    return moment(params.value).format("MMM DD, YYYY");
  }

  return params.value;
}

export const VaccineTableConfig = {
  defaultColDef: {
    minWidth: 150,
    sortable: true,
    resizable: true,
  },
  loadingOverlay:
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  noRowsOverlay: "<span></span>",
  verificationColumns: [
    {
      headerName: "",
      field: "id",
      cellRenderer: "openButtonCellRenderer",
      cellRendererParams: {
        icon: "download",
        title: "Open",
      },
      ...getButtonColumnConfig({ buttons: 1 }),
    },
    {
      headerName: "File Name",
      field: "name",
      minWidth: 200,
    },
    {
      headerName: "Manufacturer",
      field: "manufacturer",
      minWidth: 150,
    },
    {
      headerName: "1st Dose Date",
      field: "firstDoseDate",
      minWidth: 165,
      sort: "desc",
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "2nd Dose Date",
      field: "secondDoseDate",
      minWidth: 165,
      sort: "desc",
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "Valid From",
      field: "validFrom",
      maxWidth: 130,
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 100,
      cellStyle: (params) => {
        if (params.value === "Full") {
          //mark police cells as red
          return { color: "green" };
        } else {
          return { color: "orangered" };
        }
      },
    },
  ],
  columns: [
    {
      headerName: "",
      field: "id",
      cellRenderer: "openDeleteButtonCellRenderer",
      cellRendererParams: {
        openIcon: "download",
        deleteIcon: "delete",
      },
      ...getButtonColumnConfig({ buttons: 2 }),
    },
    {
      headerName: "File Name",
      field: "name",
      minWidth: 200,
    },
    {
      headerName: "Manufacturer",
      field: "manufacturer",
      minWidth: 150,
    },
    {
      headerName: "1st Dose Date",
      field: "firstDoseDate",
      minWidth: 165,
      sort: "desc",
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "2nd Dose Date",
      field: "secondDoseDate",
      minWidth: 165,
      sort: "desc",
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "Valid From",
      field: "validFrom",
      maxWidth: 130,
      valueFormatter: momentValueFormatter,
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 100,
      cellStyle: (params) => {
        if (params.value === "Full") {
          //mark police cells as red
          return { color: "green" };
        } else {
          return { color: "orangered" };
        }
      },
    },
  ],
  frameworkComponents: {
    openDeleteButtonCellRenderer: OpenDeleteButtonCellRenderer,
    openButtonCellRenderer: OpenButtonCellRenderer,
  },
};
