import { render } from "./ContractorPage.vue?vue&type=template&id=4d0c1f4b&scoped=true"
import script from "./ContractorPage.vue?vue&type=script&lang=js"
export * from "./ContractorPage.vue?vue&type=script&lang=js"

import "./ContractorPage.vue?vue&type=style&index=0&id=4d0c1f4b&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-4d0c1f4b"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QMenu,QStepper});
