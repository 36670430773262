<template>
  <q-page class="flex flex-center row q-pa-md" />
</template>

<script>
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";

export default {
  name: "LandingPage",
  setup() {
    // Redirect user to the correct page
    useAuthenticationHelper({ useLanding: true });
  },
};
</script>

<style lang="scss"></style>
