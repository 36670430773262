<template>
  <card-comp :fit="false" title="Testing History">
    <template #content>
      <q-card-section>
        <div class="row grow-1">
          <div class="col-12">
            <table-comp
              class="ag-theme-alpine grid"
              :column-defs="columns"
              :context="context"
              :default-col-def="defaultColumnDef"
              :framework-components="frameworkComponents"
              :loading="loading"
              :overlay-loading-template="overlayLoadingTemplate"
              :overlay-no-rows-template="overlayNoRowsTemplate"
              :row-data="data"
              style="height: 300px"
              ref="designGrid"
            />
          </div>
        </div>
        <slide-transition-section-with-toggle-button
          v-model:visible="visible"
          label="Add Test"
        >
          <template #transition-content>
            <covid-test-comp-inputs
              :email="email"
              :is-submitting="testIsUploading"
              @submit="submit"
              ref="covidRecordCompInputs"
            />
          </template>
        </slide-transition-section-with-toggle-button>
      </q-card-section>
    </template>
  </card-comp>
  <dialog-comp
    v-model="openModal"
    action-button-label="Remove"
    max-width="500px"
    :modal-text="deleteConfirmationMessage"
    modal-title="Remove Document"
    @cancel-clicked="openModal = false"
    @confirm-clicked="confirmedDeleteAction"
  />
</template>

<script>
import * as DocumentService from "../../services/DocumentService";
import TableComp from "@/components/table/TableComp";
import { CovidTestTableConfig } from "@/views/contractor/CovidTestTableConfig.js";
import NotificationHelper from "@/utils/helpers/NotificationHelper.js";
import { ref, watch, computed } from "vue";
import moment from "moment";
import CardComp from "@/components/common/CardComp";
import SlideTransitionSectionWithToggleButton from "@/components/common/SlideTransitionSectionWithToggleButton";
import DocumentTableHelper from "@/utils/ContractorDocumentTableHelper";
import { useValidateInputs } from "@/utils/helpers/useValidateInputs";
import * as PerformAction from "@/views/contractor/PerformAction";
import DialogComp from "../../components/common/DialogComp.vue";
import CovidTestCompInputs from "@/views/contractor/CovidTestCompInputs";

export default {
  name: "CovidTestComp",
  components: {
    SlideTransitionSectionWithToggleButton,
    CardComp,
    DialogComp,
    TableComp,
    CovidTestCompInputs,
  },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  emits: ["latestTestChange"],
  setup(props, { emit }) {
    const frameworkComponents = CovidTestTableConfig.frameworkComponents;
    const overlayLoadingTemplate = CovidTestTableConfig.loadingOverlay;
    const overlayNoRowsTemplate = CovidTestTableConfig.noRowsOverlay;
    const defaultColumnDef = CovidTestTableConfig.defaultColDef;
    const columns = ref(CovidTestTableConfig.columns);
    const loading = ref(false);
    const designGrid = ref();

    const requiredRules = [(val) => (val !== null && val !== "") || "Required"];

    const testResult = ref("Negative");
    const visible = ref(false);

    const testDate = ref(null);
    const vaccineTestFile = ref(null);
    const positiveTestSelected = computed(
      () => testStatus.value === "positive"
    );

    const dateInputLabel = computed(() =>
      positiveTestSelected.value ? "Quarantine Start Date *" : "Test Date *"
    );

    const testStatus = ref("negative");
    const data = ref([]);

    watch(
      data,
      () => {
        emit("latestTestChange");
      },
      { deep: true, immediate: true }
    );

    const testIsUploading = ref(false);
    function submit(covidTestInputData) {
      const metadata = {
        email: props.email,
        type: DocumentService.DocumentType.TEST_RESULT,
        testDate: moment(covidTestInputData.testDate, "YYYY-MM-DD")
          .startOf("day")
          .valueOf(),
        status: covidTestInputData.testStatus,
        dateOfBirth: moment(
          covidTestInputData.dateOfBirth,
          "YYYY-MM-DD"
        ).valueOf(),
        testingProvider: covidTestInputData.testingProvider,
      };
      testIsUploading.value = true;
      const dismiss = NotificationHelper.createNotification(
        "Processing test result...",
        { timeout: 0, type: "ongoing" }
      );
      DocumentService.uploadDocument(
        props.email,
        covidTestInputData.filename,
        metadata
      )
        .then(() => {
          dismiss();
          NotificationHelper.createSuccessNotification(
            "Test result successfully added!"
          );

          listFiles();
          clearFields();
        })
        .catch(() => {
          dismiss();
          NotificationHelper.createErrorNotification(
            "Test result failed to process. Please try again in a few minutes."
          );
        })
        .finally(() => {
          testIsUploading.value = false;
          resetValidation();
        });
    }

    function listFiles() {
      loading.value = true;
      let rowsToAdd = [];
      DocumentTableHelper.getTestDocumentRows(props.email)
        .then((testDocumentRows) => {
          rowsToAdd = testDocumentRows;
        })
        .finally(() => {
          data.value = rowsToAdd;
          loading.value = false;
        });
    }

    watch(
      () => props.email,
      () => {
        listFiles();
      }
    );

    const covidRecordCompInputs = ref();
    function clearFields() {
      covidRecordCompInputs.value.resetStepper();
    }

    function openAction(cellValue) {
      PerformAction.openAction(
        DocumentService.DocumentType.TEST_RESULT,
        props.email,
        cellValue
      );
    }

    const openModal = ref(false);
    const deleteCellValue = ref(null);
    const deleteConfirmationMessage = ref(null);

    function deleteAction(cellValue) {
      const filename = data.value.find((document) => {
        return document.id === cellValue;
      }).name;
      deleteConfirmationMessage.value = `Are you sure you want to remove this document, "${filename}"?`;
      openModal.value = true;
      deleteCellValue.value = cellValue;
    }

    function confirmedDeleteAction() {
      openModal.value = false;
      deleteConfirmationMessage.value = null;
      loading.value = true;
      PerformAction.deleteAction(
        DocumentService.DocumentType.TEST_RESULT,
        props.email,
        data,
        deleteCellValue.value
      ).then(() => {
        loading.value = false;
      });
    }

    const testDateInput = ref();
    const uploadFileInput = ref();

    const { resetValidation } = useValidateInputs([
      testDateInput,
      uploadFileInput,
    ]);

    return {
      columns,
      defaultColumnDef,
      deleteAction,
      frameworkComponents,
      loading,
      openAction,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      designGrid,
      requiredRules,
      testDateInput,
      uploadFileInput,
      resetValidation,
      data,
      testStatus,
      testDate,
      testResult,
      vaccineTestFile,
      visible,
      submit,
      dateInputLabel,
      testIsUploading,
      openModal,
      confirmedDeleteAction,
      deleteConfirmationMessage,
      covidRecordCompInputs,
    };
  },
  data() {
    return {
      context: {
        componentParent: this,
      },
    };
  },
};
</script>

<style scoped></style>
