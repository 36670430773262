import _ from "lodash";
import * as DocumentService from "@/services/DocumentService";
import moment from "moment";
import DocumentValidityHelper from "@/utils/helpers/ContractorDocumentValidityHelper";

export default {
  getTestDocumentRows(
    email,
    metaData = {
      type: DocumentService.DocumentType.TEST_RESULT,
    },
    fromUploadDate
  ) {
    return DocumentService.getDocuments(email, metaData, fromUploadDate).then(
      (documents) => {
        return this.mapTestDocumentsToRows(documents);
      }
    );
  },
  mapTestDocumentsToRows(documents) {
    return documents.map((document) => {
      return this.getRowObjectForTestDocument(document);
    });
  },
  getFormattedStartDate(document) {
    return moment(
      moment.utc(document.testDate).format("YYYY-MM-DD"),
      "YYYY-MM-DD"
    ).startOf("day");
  },
  getRowObjectForTestDocument(document) {
    const start = this.getFormattedStartDate(document);
    return {
      id: document.id,
      name: document.name,
      type: document.type,
      date: start.valueOf(),
      validThrough:
        document.status === "positive"
          ? "N/A"
          : moment(
              DocumentValidityHelper.getTestExpirationDate(document.testDate)
            ).valueOf(),
      validity: {
        testResult: document.status,
        testValidity: DocumentValidityHelper.testIsValid(
          document.testDate,
          document.status
        ),
      },
      testResult: this.getFormattedTestResultString(document.status),
    };
  },
  getFormattedTestResultString(status) {
    let testResultString = "";
    if (status && _.isString(status)) {
      testResultString = status.charAt(0).toUpperCase() + status.slice(1);
    }
    return testResultString;
  },
  getDoctorsClearanceRows(
    email,
    metaData = {
      type: DocumentService.DocumentType.DOCTOR_CLEARANCE,
    },
    fromUploadDate
  ) {
    return DocumentService.getDocuments(email, metaData, fromUploadDate).then(
      (documents) => {
        return this.mapDoctorsClearanceDocumentsToRows(documents);
      }
    );
  },
  mapDoctorsClearanceDocumentsToRows(documents) {
    return documents.map((document) => {
      return this.getRowObjectForDoctorsClearanceDocument(document);
    });
  },
  getRowObjectForDoctorsClearanceDocument(document) {
    const start = this.getFormattedStartDate(document);
    return {
      id: document.id,
      name: document.name,
      date: start.valueOf(),
      type: document.type,
      validThrough: moment(
        DocumentValidityHelper.getDoctorsClearanceExpirationDate(document.date)
      ).valueOf(),
      validity: DocumentValidityHelper.doctorsNoteIsValid(document.date),
    };
  },
};
