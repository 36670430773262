import { render } from "./DocumentPreviewComponent.vue?vue&type=template&id=43b00db9"
import script from "./DocumentPreviewComponent.vue?vue&type=script&lang=js"
export * from "./DocumentPreviewComponent.vue?vue&type=script&lang=js"
script.render = render

export default script
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSpinner,QItem,QItemSection,QAvatar,QImg,QItemLabel,QTooltip});
