import OpenButtonCellRenderer from "@/components/table/helper-components/OpenButtonCellRenderer.vue";
import { getButtonColumnConfig } from "@/utils/TableConfig";

export const AdminTableConfig = {
  defaultColDef: {
    minWidth: 150,
    sortable: true,
    suppressMenu: true,
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  overlay:
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  columns: [
    {
      headerName: "",
      field: "Contractor_ID",
      cellRenderer: "openButtonCellRenderer",
      cellRendererParams: {
        icon: "folder_open",
        title: "Open",
      },
      ...getButtonColumnConfig({ buttons: 1 }),
    },
    {
      headerName: "Vendor Name",
      field: "Name",
      lockVisible: true,
      minWidth: 185,
      sort: "asc",
    },
    {
      headerName: "No. of Vendor Representatives",
      field: "vendorCount",
      minWidth: 185,
    },
    {
      headerName: "No. of Contractors",
      field: "contractorCount",
      minWidth: 185,
    },
    {
      headerName: "Vendor ID",
      field: "Contractor_ID",
      minWidth: 150,
      tooltipComponent: "customTooltip",
      tooltipField: "projectTitle",
      tooltipComponentParams: { toolTipContentKey: "projectTitle" },
    },
    {
      headerName: "Contractors",
      field: "contractors",
      minWidth: 400,
    },
  ],
  frameworkComponents: {
    openButtonCellRenderer: OpenButtonCellRenderer,
  },
};
