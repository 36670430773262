<template>
  <!-- COVID Test card -->
  <q-card
    v-if="showTestingCard"
    class="justify-between summary-card"
    :class="{
      'no-wrap': true,
      column: true,
      'full-height': true,
      'good-result-card': isAdmittable,
      'bad-result-card': !isAdmittable,
    }"
    flat
  >
    <div>
      <q-card-section class="q-pb-none">
        <div class="text-h6">COVID Test</div>
        <div>
          Taken on
          <span class="text-bold">
            {{ formattedTestDate }}
          </span>
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-list>
          <q-item class="q-py-none q-my-sm">
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(isNotInQuarantine)"
                :name="getCheckMarkOrCrossIcon(isNotInQuarantine)"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ isNotInQuarantine ? "Not in" : "In" }}
                quarantine
              </q-item-label>
              <q-item-label v-if="formattedQuarantineEndDate" caption>
                <span v-if="!isNotInQuarantine">Through</span>
                <span v-else>Last day was</span>
                {{ formattedQuarantineEndDate }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="q-py-none q-my-sm">
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(displayedTestIsBeforeExpiration)"
                :name="getCheckMarkOrCrossIcon(displayedTestIsBeforeExpiration)"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                Test is
                {{ displayedTestIsBeforeExpiration ? "" : "no longer" }}
                valid
              </q-item-label>
              <q-item-label caption>
                <span v-if="displayedTestIsBeforeExpiration">
                  Valid through
                </span>
                <span v-else>Expired on</span>
                {{ formattedTestExpirationDate }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="q-py-none q-my-sm">
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(displayedTestIsVerified)"
                :name="getCheckMarkOrCrossIcon(displayedTestIsVerified)"
              />
            </q-item-section>
            <q-item-section>
              Test document
              {{ displayedTestIsVerified ? "was" : "has not been" }}
              verified
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <q-card-section
        v-if="someTestsRequireValidation"
        class="column justify-end q-pt-none"
      >
        <span class="q-pl-md text-italic">
          Note: Some uploaded documents still need verification
        </span>
      </q-card-section>
    </div>
    <q-card-section class="q-pt-none">
      <document-preview-component
        :file-name="testToDisplay.name"
        loading-text="Loading most recent test document..."
        :thumbnail="thumbnail"
        @open="openAction"
      />
    </q-card-section>
  </q-card>

  <!-- Quarantine/previous infection card -->
  <q-card
    v-else-if="formattedQuarantineStartDate"
    class="justify-between summary-card"
    :class="{
      'no-wrap': true,
      column: true,
      'full-height': true,
      'good-result-card': isAdmittable,
      'bad-result-card': !isAdmittable,
    }"
    flat
  >
    <div>
      <q-card-section class="q-pb-none">
        <div class="text-h6">
          <span v-if="isNotInQuarantine">
            Clearance Based on Previous Infection
          </span>
          <span v-else>In Quarantine</span>
        </div>
        <div v-if="!isNotInQuarantine">
          Quarantine started on
          <span class="text-bold">
            {{ formattedQuarantineStartDate }}
          </span>
        </div>
        <div v-else>
          {{
            isWithinExemptionPeriod ? "Exempt through" : "Exemption expired on"
          }}
          <span class="text-bold">
            {{ formattedExemptionEndDate }}
          </span>
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-list>
          <q-item class="q-py-none q-my-sm">
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(isNotInQuarantine)"
                :name="getCheckMarkOrCrossIcon(isNotInQuarantine)"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ isNotInQuarantine ? "Not in" : "In" }}
                quarantine
              </q-item-label>
              <q-item-label caption>
                <span v-if="!isNotInQuarantine">Through</span>
                <span v-else>Last day was</span>
                {{ formattedQuarantineEndDate }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="q-py-none q-my-sm">
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(isWithinExemptionPeriod)"
                :name="getCheckMarkOrCrossIcon(isWithinExemptionPeriod)"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                COVID-19 recovery exemption
                {{ isWithinExemptionPeriod ? "is" : "is not" }}
                active
              </q-item-label>
              <q-item-label caption>
                <span v-if="!isNotInQuarantine">
                  <!-- Add one day to get the day after quarantine ends -->
                  Considered recovered on
                  {{ formatDate(formattedQuarantineEndDate, 1) }}
                </span>
                <span v-else>
                  {{ isWithinExemptionPeriod ? "Valid through" : "Expired on" }}
                  {{ formattedExemptionEndDate }}
                </span>
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="q-py-none q-my-sm">
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(positiveTestForQuarantineVerified)"
                :name="
                  getCheckMarkOrCrossIcon(positiveTestForQuarantineVerified)
                "
              />
            </q-item-section>
            <q-item-section>
              Test document
              {{ positiveTestForQuarantineVerified ? "was" : "has not been" }}
              verified
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <q-card-section
        v-if="someTestsRequireValidation"
        class="column justify-end q-pt-none"
      >
        <span class="q-pl-md text-italic">
          Note: Some uploaded documents still need verification
        </span>
      </q-card-section>
    </div>
    <q-card-section class="q-pt-none">
      <document-preview-component
        :file-name="quarantineStatus.document.name"
        loading-text="Loading most recent test document..."
        :thumbnail="thumbnail"
        @open="openAction"
      />
    </q-card-section>
  </q-card>
  <q-card
    v-else
    bordered
    class="row items-center full-height summary-card no-result-card"
    flat
  >
    <q-card-section>No uploaded negative test history</q-card-section>
  </q-card>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import TestSummaryService from "@/services/TestSummaryService";
import * as PerformAction from "@/views/contractor/PerformAction";
import * as DocumentService from "@/services/DocumentService";
import DocumentPreviewComponent from "@/views/contractor/profileInformation/DocumentPreviewComponent";

export default defineComponent({
  name: "TestSummaryCard",
  components: { DocumentPreviewComponent },
  props: {
    email: {
      type: String,
      default: "",
    },
    lastDocumentValidationDate: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const testingStatus = ref();

    function getTestSummary() {
      return TestSummaryService.getTestSummary(
        props.email,
        props.lastDocumentValidationDate
      ).then((response) => {
        testingStatus.value = response;
        getThumbnail();
        return response;
      });
    }

    onMounted(() => {
      getTestSummary();
    });

    function getMoment(object) {
      if (object instanceof moment) {
        return object;
      } else if (typeof object === "number") {
        return moment(object);
      } else if (typeof object === "string") {
        return moment(object, "MMM D, YYYY");
      }

      throw new TypeError("getMoment: cannot parse moment date");
    }

    function formatDate(object, dayAdjustment = 0) {
      if (object) {
        return moment
          .utc(getMoment(object).valueOf())
          .add(dayAdjustment, "days")
          .format("MMM D, YYYY");
      }

      return "";
    }

    function getCheckMarkOrCrossIcon(boolean) {
      return boolean ? "done" : "close";
    }

    function getIconColor(boolean) {
      return boolean ? "green" : "red";
    }

    const quarantineStatus = computed(() => {
      return testingStatus.value?.quarantineStatus;
    });

    const isAdmittableBasedOnNegativeTest = computed(() => {
      return testingStatus.value?.isAdmittableBasedOnNegativeTest ?? false;
    });

    const isAdmittableBasedOnExemption = computed(() => {
      return quarantineStatus.value?.isAdmittableBasedOnExemption ?? false;
    });

    const isAdmittable = computed(() => {
      return (
        isAdmittableBasedOnNegativeTest.value ||
        isAdmittableBasedOnExemption.value
      );
    });

    const testToDisplay = computed(() => {
      return testingStatus.value?.testToDisplay ?? null;
    });

    const formattedTestDate = computed(() => {
      return formatDate(testToDisplay.value?.testDate);
    });

    const formattedTestExpirationDate = computed(() => {
      // Subtract one from the end date
      // Allows us to display the final day of validity
      return formatDate(testingStatus.value?.testToDisplayExpirationDate, -1);
    });

    const displayedTestIsBeforeExpiration = computed(() => {
      return testingStatus.value?.testToDisplayIsBeforeExpirationDate ?? false;
    });

    const displayedTestIsVerified = computed(() => {
      return testingStatus.value?.testToDisplayIsVerified ?? false;
    });

    const someTestsRequireValidation = computed(() => {
      return unverifiedTests.value?.length ?? 0 > 0;
    });

    const unverifiedTests = computed(() => {
      return testingStatus.value?.unverifiedTests;
    });

    const isNotInQuarantine = computed(() => {
      return !quarantineStatus.value?.isInQuarantine;
    });

    const isWithinExemptionPeriod = computed(() => {
      return quarantineStatus.value?.isWithinExemptionPeriod ?? false;
    });

    const formattedQuarantineStartDate = computed(() => {
      return formatDate(quarantineStatus.value?.startDate);
    });

    const formattedQuarantineEndDate = computed(() => {
      // Subtract one from the end date
      // Allows us to display the final day in quarantine
      return formatDate(quarantineStatus.value?.endDate, -1);
    });

    const formattedExemptionEndDate = computed(() => {
      // Subtract one from the end date
      // Allows us to display the final day that exemption is valid
      return formatDate(quarantineStatus.value?.testExemptionEndDate, -1);
    });

    const positiveTestForQuarantineVerified = computed(() => {
      return quarantineStatus.value?.verified ?? false;
    });

    function openAction() {
      PerformAction.openAction(
        DocumentService.DocumentType.TEST_RESULT,
        props.email,
        thumbnailId.value
      );
    }

    watch(
      () => testToDisplay.value,
      () => {
        getThumbnail();
      }
    );

    const thumbnailId = computed(() => {
      return showTestingCard.value
        ? testToDisplay.value?.id
        : quarantineStatus.value?.document?.id;
    });

    const showTestingCard = computed(
      () =>
        isAdmittableBasedOnNegativeTest.value ||
        (!formattedQuarantineStartDate.value && testToDisplay.value)
    );

    const thumbnail = ref();
    function getThumbnail() {
      if (thumbnailId.value !== null) {
        DocumentService.getThumbnail(props.email, thumbnailId.value).then(
          (file) => {
            if (file) {
              thumbnail.value = URL.createObjectURL(file);
            }
          }
        );
      } else {
        thumbnail.value = null;
      }
    }

    return {
      showTestingCard,
      getTestSummary,
      formatDate,
      getCheckMarkOrCrossIcon,
      getIconColor,
      quarantineStatus,
      isAdmittableBasedOnNegativeTest,
      isAdmittableBasedOnExemption,
      isAdmittable,
      testToDisplay,
      formattedTestDate,
      formattedTestExpirationDate,
      displayedTestIsBeforeExpiration,
      displayedTestIsVerified,
      someTestsRequireValidation,
      isNotInQuarantine,
      isWithinExemptionPeriod,
      formattedQuarantineStartDate,
      formattedQuarantineEndDate,
      formattedExemptionEndDate,
      positiveTestForQuarantineVerified,
      openAction,
      thumbnail,
    };
  },
});
</script>

<style scoped lang="scss">
.q-item {
  min-height: unset;
}

.q-item__section--avatar {
  min-width: 32px;
}
</style>
