import { render } from "./UnauthorizedPage.vue?vue&type=template&id=58abf02f&scoped=true"
import script from "./UnauthorizedPage.vue?vue&type=script&lang=js"
export * from "./UnauthorizedPage.vue?vue&type=script&lang=js"

import "./UnauthorizedPage.vue?vue&type=style&index=0&id=58abf02f&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-58abf02f"

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCardSection});
