<template>
  <q-inner-loading id="loading-comp" :showing="loading">
    <q-spinner-oval color="primary" size="50px" />
  </q-inner-loading>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "InnerLoadingComp",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss">
#loading-comp {
  // Don't bleed corners over the rounded card
  border-radius: inherit;
  // This ensures that the loading overlay is above the ag grid header (z-index: 1)
  z-index: 2;
}
</style>
