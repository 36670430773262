import { computed, ref } from "vue";
import * as AccountService from "@/services/AccountService";
import NotificationHelper from "@/utils/helpers/NotificationHelper";

export function useAdminTableAddEditFunctionality(
  tableRef,
  initialEntryValues,
  loadAdminAccountInformation,
  tableLoading
) {
  const selectedEntry = ref({});
  const selectedNodeId = ref(null);
  const openAddEditModal = ref(false);
  const openDeleteConfirm = ref(false);

  function loadData() {
    tableRef.value.resetTableSortAndFilters();
  }

  function addAction() {
    selectedEntry.value = initialEntryValues;
    selectedNodeId.value = null;
    openAddEditModal.value = true;
  }

  function deleteEntry() {
    openDeleteConfirm.value = false;
    tableLoading.value = true;
    const dismiss = NotificationHelper.createNotification(
      `Deleting Account for ${selectedEntry.value.email}...`,
      { timeout: 0, type: "ongoing" }
    );
    selectedEntry.value.type = null;
    selectedEntry.value.isDeleted = true;
    return AccountService.updateAccount(
      selectedEntry.value.email,
      selectedEntry.value
    )
      .then(() => {
        loadAdminAccountInformation();
      })
      .finally(() => {
        dismiss();
        tableLoading.value = false;
        NotificationHelper.createSuccessNotification(
          "Account successfully deleted"
        );
      });
  }

  function editAction(node) {
    selectedEntry.value = node.data;
    openAddEditModal.value = true;
  }

  function deleteAction(node) {
    selectedEntry.value = node.data;
    openDeleteConfirm.value = true;
  }

  const editMode = computed(() => selectedNodeId.value !== null);

  function updateClicked(updatedEntry) {
    if (selectedNodeId.value === null) {
      const dismiss = NotificationHelper.createNotification(
        "Processing account...",
        { timeout: 0, type: "ongoing" }
      );
      addEntryIfAccountDoesNotExist(updatedEntry).then(() => {
        dismiss();
      });
    } else {
      updateEntry(updatedEntry);
    }
    openAddEditModal.value = false;
    selectedNodeId.value = null;
  }

  function addEntryIfAccountDoesNotExist(updatedEntry) {
    updatedEntry.email = updatedEntry.email.toLowerCase();
    tableLoading.value = true;
    return AccountService.doesAccountWithEmailExistsAndIsNotDeleted(
      updatedEntry.email
    )
      .then((response) => {
        if (!response || response.isDeleted === true || !response.type) {
          return saveNewAdminAccount(updatedEntry);
        } else {
          NotificationHelper.createErrorNotification(
            "Account with email already exists"
          );
        }
      })
      .finally(() => {
        tableLoading.value = false;
        return loadAdminAccountInformation();
      });
  }

  function saveNewAdminAccount(updatedEntry) {
    return AccountService.saveAccount(
      updatedEntry.email,
      updatedEntry.account
    ).then(() => {
      NotificationHelper.createSuccessNotification(
        "Account successfully saved"
      );
    });
  }

  function updateEntry(updatedEntry) {
    AccountService.updateAccount(updatedEntry.email, updatedEntry.account).then(
      () => {
        loadAdminAccountInformation();
      }
    );
  }

  return {
    loadData,
    addAction,
    selectedEntry,
    selectedNodeId,
    openAddEditModal,
    editAction,
    deleteAction,
    openDeleteConfirm,
    updateClicked,
    deleteEntry,
    editMode,
  };
}
