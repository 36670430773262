<template>
  <card-comp :fit="false" title="Check-in History">
    <template #content>
      <q-card-section>
        <div class="row grow-1">
          <div class="col-12">
            <table-comp
              class="ag-theme-alpine grid"
              :column-defs="columns"
              :context="context"
              :default-col-def="defaultColumnDef"
              :framework-components="frameworkComponents"
              :loading="loading"
              :overlay-loading-template="overlayLoadingTemplate"
              :overlay-no-rows-template="overlayNoRowsTemplate"
              :row-data="rowData"
              style="height: 300px"
              ref="designGrid"
            />
          </div>
        </div>
      </q-card-section>
    </template>
  </card-comp>
</template>

<script>
import TableComp from "@/components/table/TableComp";
import { CheckInRecordTableConfig } from "@/views/contractor/CheckInRecordTableConfig.js";
import { onMounted, ref, watch } from "vue";
import CardComp from "@/components/common/CardComp";
import CheckinService from "@/services/CheckInService";
import moment from "moment";

export default {
  name: "CheckInRecordComp",
  components: {
    CardComp,
    TableComp,
  },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const frameworkComponents = CheckInRecordTableConfig.frameworkComponents;
    const overlayLoadingTemplate = CheckInRecordTableConfig.overlay;
    const overlayNoRowsTemplate = CheckInRecordTableConfig.noRowsOverlay;
    const defaultColumnDef = CheckInRecordTableConfig.defaultColDef;
    const columns = ref(CheckInRecordTableConfig.columns);
    const designGrid = ref();

    watch(
      () => props.email,
      () => {
        loadCheckIns();
      }
    );
    onMounted(() => loadCheckIns());

    const loading = ref(false);
    const rowData = ref([]);
    async function loadCheckIns() {
      if (props.email) {
        loading.value = true;
        const checkInResponse = await CheckinService.getCheckInInfo(
          props.email
        );
        let rowObj = [];
        for (const doc of checkInResponse) {
          rowObj.push({
            timeStamp: moment(doc.timeStamp).format("MM-DD-YYYY: h:mm:ss a"),
            checkInLocation: doc.checkInLocation,
            checkedInBy: doc.checkedInByFullName
              ? doc.checkedInByFullName
              : doc.checkedInBy,
          });
        }
        rowData.value = rowObj;
      }
      loading.value = false;
    }

    return {
      columns,
      defaultColumnDef,
      frameworkComponents,
      loading,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      designGrid,
      rowData,
      loadCheckIns,
    };
  },
  data() {
    return {
      context: {
        componentParent: this,
      },
    };
  },
};
</script>

<style scoped></style>
