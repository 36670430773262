import OpenButtonCellRenderer from "@/components/table/helper-components/OpenButtonCellRenderer";

export const AdminTable = {
  defaultColDef: {
    minWidth: 220,
    sortable: true,
    suppressMenu: true,
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  overlay:
    '<span class="ag-overlay-loading-center">Please wait while your Locations are loading</span>',
  columns: [
    {
      headerName: "Location ID",
      field: "id",
      lockVisible: true,
      width: 40,
      sort: "asc",
    },
    {
      headerName: "Name",
      field: "name",
      lockVisible: true,
      width: 180,
    },
    {
      headerName: "Address",
      field: "address",
      lockVisible: true,
      width: 180,
    },
    {
      headerName: "Zip Code",
      field: "zipcode",
      width: 60,
    },
    {
      headerName: "Type",
      field: "type",
      lockVisible: true,
      width: 60,
    },
    {
      headerName: "Code",
      field: "schoolCode",
      lockVisible: true,
      width: 50,
    },
    {
      headerName: "District",
      field: "district",
      lockVisible: true,
      width: 60,
    },
    {
      headerName: "Complex",
      field: "complex",
      lockVisible: true,
      width: 70,
    },
    {
      headerName: "City",
      field: "city",
      lockVisible: true,
      width: 50,
    },
    {
      headerName: "Latitude",
      field: "lat",
      width: 70,
    },
    {
      headerName: "Longitude",
      field: "lng",
      width: 70,
    },
    {
      headerName: "Contact & Fax",
      field: "phone",
      width: 90,
      tooltipComponent: "customTooltip",
      tooltipField: "projectTitle",
      tooltipComponentParams: { toolTipContentKey: "projectTitle" },
    },
  ],
  frameworkComponents: {
    openButtonCellRenderer: OpenButtonCellRenderer,
  },
};
