<template>
  <div id="CellActions" v-if="showActions">
    <!--Writing the components this way is hacky, but since using quasar in the ag grid appears to be broken this is a stopgap measure -->
    <button
      class="
        q-btn q-btn-item
        non-selectable
        no-outline
        q-btn--flat q-btn--round
        text-negative
        q-btn--actionable
        q-focusable q-hoverable
        q-btn--wrap
      "
      :disabled="disableDelete"
      role="button"
      tabindex="0"
      type="button"
      @click="deleteClickHandler"
    >
      <span class="q-focus-helper" />
      <span class="q-btn__wrapper col row q-anchor--skip">
        <span
          class="
            q-btn__content
            text-center
            col
            items-center
            q-anchor--skip
            justify-center
            row
          "
        >
          <i
            aria-hidden="true"
            class="material-icons document-delete-icon text-red"
            role="img"
            style="font-size: 24px"
            title="Delete"
            >delete</i
          >
        </span>
      </span>
    </button>
    <button
      class="
        q-btn q-btn-item
        non-selectable
        no-outline
        q-btn--flat q-btn--round
        text-dark
        q-btn--actionable
        q-focusable q-hoverable
        q-btn--wrap
      "
      role="button"
      tabindex="0"
      type="button"
      @click="editClickHandler"
    >
      <span class="q-focus-helper" />
      <span class="q-btn__wrapper col row q-anchor--skip">
        <span
          class="
            q-btn__content
            text-center
            col
            items-center
            q-anchor--skip
            justify-center
            row
          "
        >
          <i
            aria-hidden="true"
            class="material-icons"
            role="img"
            style="font-size: 24px"
            title="Edit"
            >edit</i
          >
        </span>
      </span>
    </button>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  // A copy of the ButtonCellRenderer that returns the entire node for delete / edit options
  name: "NodeDeleteEditCellRenderer",
  data() {
    return {
      params: {},
    };
  },
  computed: {
    showActions() {
      return true; //TODO conditional display of actions logic
    },
    disableDelete() {
      return this.params.node.data.email === this.params.email;
    },
  },
  methods: {
    deleteClickHandler() {
      this.params.context.componentParent.deleteAction(this.params.node);
    },
    editClickHandler() {
      this.params.context.componentParent.editAction(this.params.node);
    },
  },
});
</script>

<style scoped lang="scss">
#CellActions {
  .button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: #181d1f;
  }
}
</style>
