<template>
  <dialog-comp
    v-model="openModal"
    :action-button-disabled="disableActionButton"
    :action-button-label="actionButtonLabel"
    max-width="800px"
    modal-title="Check In"
    position="top"
    suppress-close-on-action-button-click
    width="90%"
    @cancel-clicked="cancelHandler"
    @confirm-clicked="confirmClickHandler"
  >
    <template #content>
      <q-stepper
        v-model="step"
        :active-icon="step === 1 ? 'gps_fixed' : 'place'"
        animated
        color="primary"
        flat
        ref="stepper"
      >
        <q-step icon="gps_fixed" :name="1" title="Current Location">
          <div class="row justify-between q-col-gutter-md">
            <div class="col-sm-12 col-md-6">
              <div class="q-mb-md text-bold">
                Please enter your current location
              </div>
              <q-input
                v-model="address.address"
                :dense="true"
                outlined
                placeholder="Address"
              />
              <br />
              <div class="row q-col-gutter-md">
                <div class="col-xs-12 col-sm-4">
                  <q-input
                    v-model="address.city"
                    :dense="true"
                    outlined
                    placeholder="City"
                  />
                </div>
                <div class="col-xs-12 col-sm-4">
                  <q-input
                    v-model="address.state"
                    :dense="true"
                    outlined
                    placeholder="State"
                  />
                </div>
                <div class="col-xs-12 col-sm-4">
                  <q-input
                    v-model="address.pincode"
                    :dense="true"
                    outlined
                    placeholder="Zip-Code"
                  />
                </div>
              </div>
            </div>
            <q-separator
              class="xs-hide sm-hide q-pl-none"
              spaced="md"
              vertical
            />
            <div class="col-auto text-center">
              <div class="row full-height items-center">
                <q-btn
                  color="primary"
                  icon="gps_fixed"
                  label="use my current Location"
                  :loading="grabbingLocationAndAddress"
                  outline
                  @click="getBrowserCoordinatesAndSetCurrentAddress"
                />
              </div>
            </div>
          </div>
        </q-step>
        <q-step icon="place" :name="2" title="Select Location">
          <div class="row">
            <div class="text-center">
              <q-btn
                color="primary"
                icon="arrow_back"
                outline
                @click="$refs.stepper.previous()"
              >
                &nbsp; Search another area
              </q-btn>
            </div>
          </div>
          <div class="row q-pt-xl">
            <span class="text-bold q-pb-sm">
              Please select the location you would to check into
            </span>
            <q-select
              v-model="editableCheckInLocation"
              class="full-width"
              clearable
              :disable="locationDropdownLoading"
              label="Check In Location"
              :loading="locationDropdownLoading"
              menu-anchor="top middle"
              menu-self="bottom middle"
              option-label="name"
              :options="formattedCheckInLocations"
              options-dense
              outlined
              use-input
              @filter="filterFn"
            >
              <template #option="scope">
                <q-item v-bind="scope.itemProps">
                  <q-item-section>
                    <div class="row justify-between q-pt-sm q-pb-sm">
                      <div class="col">
                        <q-item-label>{{ scope.opt.name }}</q-item-label>
                        <q-item-label caption>
                          {{ scope.opt.address }}
                        </q-item-label>
                      </div>
                      <div
                        v-if="scope.opt && scope.opt.distance"
                        class="col-auto"
                      >
                        <div class="row full-height items-center">
                          <q-item-label caption>
                            {{ formatDistanceForDisplay(scope.opt.distance) }}
                            Miles
                          </q-item-label>
                        </div>
                      </div>
                    </div>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
        </q-step>
      </q-stepper>
    </template>
  </dialog-comp>
</template>

<script>
import _ from "lodash";
import { computed, defineComponent, ref, watch } from "vue";
import { useSyncModelValue } from "@/utils/helpers/useSyncModelValue";
import DialogComp from "@/components/common/DialogComp";
import LocationService from "@/services/LocationService";
import {
  getAddressFromLatLongUsingGoogleMaps,
  getBrowserLocationAndExecuteFunction,
  getDistanceBetweenCoordinatesViaHaversine,
  getLatLongFromAddressUsingGoogleMaps,
} from "@/utils/helpers/LocationHelper";
import NotificationHelper from "@/utils/helpers/NotificationHelper";

export default defineComponent({
  name: "CheckInModal",
  dense: ref(false),
  components: { DialogComp },
  emits: ["checkInClicked", "update:modelValue", "update:checkInLocation"],
  setup(props, { emit }) {
    var locations = ref(null);
    LocationService.getLocations().then((response) => {
      locations = response;
    });
    const openModal = ref(false);
    useSyncModelValue(
      props,
      "modelValue",
      emit,
      "update:modelValue",
      openModal
    );

    watch(openModal, (newVal) => {
      if (newVal === false) {
        step.value = 1;
      }
    });

    const editableCheckInLocation = ref("");

    function checkInClicked() {
      emit("checkInClicked", {
        checkInLocation: editableCheckInLocation.value.name,
        locationId: editableCheckInLocation.value.location_id,
      });
    }
    const formattedCheckInLocations = ref([]);
    function filterFn(val, update) {
      if (val === "") {
        update(() => {
          formattedCheckInLocations.value = distances.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        formattedCheckInLocations.value = distances.value.filter(
          (v) => v && v.name.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    watch(
      () => props.modelValue,
      (newVal) => {
        if (!newVal) {
          editableCheckInLocation.value = "";
        }
      }
    );

    function cancelHandler() {
      openModal.value = false;
      _.assign(address.value, {
        address: "",
        city: "",
        state: "",
        pincode: "",
      });
      editableCheckInLocation.value = null;
    }

    const step = ref(1);
    function confirmClickHandler() {
      if (step.value === 1) {
        step.value = step.value + 1;
      } else {
        checkInClicked();
        cancelHandler();
      }
    }

    watch(step, (newVal) => {
      if (newVal === 2) {
        populateDropdownWithLocationsSortedByDistance();
      }
    });

    const actionButtonLabel = computed(() =>
      step.value > 1 ? "Check In" : "Next"
    );

    const disableActionButton = computed(
      () =>
        step.value === 2 &&
        (!editableCheckInLocation.value || !editableCheckInLocation.value.name)
    );

    const distances = ref([]);
    const locationDropdownLoading = ref(false);
    function populateDropdownWithLocationsSortedByDistance() {
      locationDropdownLoading.value = true;
      distances.value = flattenSchoolObject(locations).sort(
        (a, b) => a.name - b.name
      );
      getLatLongFromAddress()
        .then((coordinates) => {
          if (coordinates.lat && coordinates.lng) {
            const locationsWithDistanceCalculatedViaHaversine =
              populateDistanceToLocationsViaHaversine(
                coordinates.lat,
                coordinates.lng
              );
            distances.value = locationsWithDistanceCalculatedViaHaversine.sort(
              (a, b) => sortByNameAndDisplayedDistance(a, b)
            );
          } else {
            NotificationHelper.createErrorNotification(
              "The Address You Entered Could Not Be Found"
            );
            distances.value = distances.value.sort((a, b) =>
              alphaSort(a.name, b.name)
            );
          }
        })
        .catch(() => {
          // An address was not inputted so we can just sort alphabetically
          distances.value = distances.value.sort((a, b) =>
            alphaSort(a.name, b.name)
          );
        })
        .finally(() => (locationDropdownLoading.value = false));
    }
    function sortByNameAndDisplayedDistance(a, b) {
      const displayedDistanceToA = formatDistanceForDisplay(a.distance);
      const displayedDistanceToB = formatDistanceForDisplay(b.distance);
      if (displayedDistanceToA - displayedDistanceToB === 0) {
        return alphaSort(a.name, b.name);
      } else {
        return displayedDistanceToA - displayedDistanceToB;
      }
    }

    function alphaSort(a, b) {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    }

    function flattenSchoolObject(cptMapSchoolLoc) {
      const flatArrayOfSchools = [];
      for (const key in cptMapSchoolLoc) {
        flatArrayOfSchools.push({
          name: [key],
          ...cptMapSchoolLoc[key].data,
        });
      }
      return flatArrayOfSchools;
    }

    function getLatLongFromAddress() {
      const address = getAddressFromInputs();
      if (address) {
        return getLatLongFromAddressUsingGoogleMaps(address).then(
          (coordinates) => coordinates
        );
      } else {
        return Promise.reject("no address");
      }
    }

    function getAddressFromInputs() {
      if (
        address.value.address ||
        address.value.city ||
        address.value.state ||
        address.value.pincode
      ) {
        return `${address.value.address},${address.value.city},${address.value.state},${address.value.pincode}`;
      } else {
        return null;
      }
    }

    function populateDistanceToLocationsViaHaversine(lat, long) {
      const locationsWithDistances = [];
      for (const key in locations) {
        const roughDistanceToLocation =
          getDistanceBetweenCoordinatesViaHaversine(
            lat,
            long,
            locations[key].data.lat,
            locations[key].data.lng
          );
        locationsWithDistances.push({
          name: [key],
          distance: roughDistanceToLocation,
          ...locations[key].data,
        });
      }
      return locationsWithDistances;
    }

    const grabbingLocationAndAddress = ref(false);
    function getBrowserCoordinatesAndSetCurrentAddress() {
      grabbingLocationAndAddress.value = true;
      getBrowserLocationAndExecuteFunction(
        (lat, lng) =>
          setCurrentAddressUsingLatAndLong(lat, lng).then(() => {
            step.value = 2;
            grabbingLocationAndAddress.value = false;
          }),
        () => (grabbingLocationAndAddress.value = false)
      );
    }

    const address = ref({
      address: "",
      city: "",
      state: "",
      pincode: "",
    });
    function setCurrentAddressUsingLatAndLong(lat, long) {
      return getAddressFromLatLongUsingGoogleMaps(lat, long).then(
        (response) => {
          _.assign(address.value, response);
        }
      );
    }

    function formatDistanceForDisplay(rawDistance) {
      const distance = Number(rawDistance);
      let formattedString = "";
      if (distance < 2) {
        formattedString = distance.toFixed(2).toString();
      } else {
        formattedString = Math.round(distance).toString();
      }
      return formattedString;
    }

    return {
      openModal,
      editableCheckInLocation,
      filterFn,
      checkInClicked,
      formattedCheckInLocations,
      step,
      confirmClickHandler,
      getBrowserCoordinatesAndSetCurrentAddress,
      address,
      cancelHandler,
      actionButtonLabel,
      formatDistanceForDisplay,
      disableActionButton,
      locationDropdownLoading,
      grabbingLocationAndAddress,
    };
  },
});
</script>

<style></style>
