<template>
  <card-comp v-if="dataExists" :fit="false" title="Unverified Documents">
    <template #content>
      <q-card-section class="q-pt-none">
        <div v-if="vaccineDataExists" class="row q-my-md grow-1">
          <div class="col-12">
            <document-validation-table
              :context="context"
              :header-text="'Unverified Vaccinations'"
              :loading="loading"
              :ref-name="designGrid"
              :row-data="vaccineData"
              :table-config="vaccineTableConfig"
            />
          </div>
        </div>
        <div v-if="testingDataExists" class="row q-my-md grow-1">
          <div class="col-12">
            <document-validation-table
              :context="context"
              :header-text="'Unverified Tests'"
              :loading="testingDataLoading"
              :ref-name="testingDesignGrid"
              :row-data="testingData"
              :table-config="covidTestTableConfig"
            />
          </div>
        </div>
        <div class="row justify-center q-mt-md grow-1">
          <div class="col-12">
            By clicking Verify All, you attest to the following:
            <br /><br />
            I attest and verify that the documents provided in this form is
            accurate and true to the best of my knowledge. I understand that
            knowingly making a false statement on this form is a crime and can
            be punished by fine or imprisonment or both (HRS § 710-1063). I
            understand that making a false statement on this form may also
            result in disciplinary action, up to and including termination.
          </div>
          <div class="q-pt-md">
            <q-btn
              color="primary"
              hint="required"
              icon="check"
              label="Verify All"
              :loading="verificationInProgress"
              rounded
              @click="submit"
            />
          </div>
        </div>
      </q-card-section>
    </template>
  </card-comp>
</template>

<script>
import _ from "lodash";
import { ref, computed, watch, onMounted } from "vue";
import CardComp from "@/components/common/CardComp";
import moment from "moment";
import { CovidTestTableConfig } from "@/views/contractor/CovidTestTableConfig";
import * as DocumentService from "@/services/DocumentService";
import NotificationHelper from "@/utils/helpers/NotificationHelper";
import * as AccountService from "@/services/AccountService";
import { VaccineTableConfig } from "@/views/contractor/VaccineTableConfig";
import DocumentTableHelper from "@/utils/ContractorDocumentTableHelper";
import DocumentValidationTable from "@/views/contractor/DocumentValidationTable";
import * as PerformAction from "./PerformAction";

export default {
  name: "DocumentValidationComp",
  components: {
    CardComp,
    DocumentValidationTable,
  },
  props: {
    email: {
      type: String,
      default: "",
    },
    lastDocumentValidationDate: {
      type: String,
      default: null,
    },
    accountObj: {
      type: Object,
      default: null,
    },
  },
  emits: ["documents-validation-change", "update-last-validation-timestamp"],
  setup(props, { emit }) {
    const covidTestTableConfig = CovidTestTableConfig;
    const vaccineTableConfig = VaccineTableConfig;
    const testingDesignGrid = ref();
    const designGrid = ref();

    onMounted(() => {
      loadUnverifiedDocuments();
    });

    const testingData = ref([]);
    const vaccineData = ref([]);

    const testingDataExists = computed(() => testingData.value.length > 0);
    const vaccineDataExists = computed(() => vaccineData.value.length > 0);

    const dataExists = computed(
      () => testingDataExists.value || vaccineDataExists.value
    );

    watch(
      () => props.lastDocumentValidationDate,
      () => {
        loadUnverifiedDocuments();
      }
    );

    watch(dataExists, () => {
      emit("documents-validation-change", {
        unvalidatedTestingDocumentsExist: testingData.value.length > 0,
        unvalidatedVaccineDocumentsExist: vaccineData.value.length > 0,
      });
    });

    function loadUnverifiedDocuments() {
      loadUploadedVaccineFiles();
      loadUploadedTestingFiles();
    }

    const testingDataLoading = ref(false);

    function loadUploadedTestingFiles() {
      testingDataLoading.value = true;
      let rowsToAdd = [];
      DocumentTableHelper.getTestDocumentRows(
        props.email,
        {
          type: DocumentService.DocumentType.TEST_RESULT,
        },
        props.lastDocumentValidationDate
      )
        .then((testDocumentRows) => {
          rowsToAdd = testDocumentRows;
          return DocumentTableHelper.getDoctorsClearanceRows(
            props.email,
            {
              type: DocumentService.DocumentType.DOCTOR_CLEARANCE,
            },
            props.lastDocumentValidationDate
          ).then((doctorsClearanceRows) => {
            rowsToAdd = rowsToAdd.concat(doctorsClearanceRows);
          });
        })
        .finally(() => {
          testingData.value = rowsToAdd;
          testingDataLoading.value = false;
        });
    }

    const loading = ref(false);

    function loadUploadedVaccineFiles() {
      loading.value = true;
      DocumentService.getDocuments(
        props.email,
        {
          type: DocumentService.DocumentType.VACCINATION,
        },
        props.lastDocumentValidationDate
      )
        .then((documents) => {
          const tableRows = [];

          for (const document of documents) {
            const received = moment.utc(
              document.secondDoseDate || document.firstDoseDate
            );
            const start = received.clone().add(14, "days");

            tableRows.push({
              id: document.id,
              name: document.name,
              manufacturer: document.manufacturer,
              status: document.status,
              firstDoseDate: document.firstDoseDate,
              secondDoseDate: document.secondDoseDate ?? "N/A",
              validFrom: document.status === "Full" ? start.valueOf() : "N/A",
            });
          }

          vaccineData.value = tableRows;
        })
        .finally(() => {
          // TODO: find a better fix for multiple async calls that set the loading
          setTimeout(() => {
            loading.value = false;
          }, 1000);
        });
    }

    function openAction(cellValue) {
      PerformAction.openAction(null, props.email, cellValue);
    }

    const verificationInProgress = ref(false);
    function submit() {
      verificationInProgress.value = true;
      const accountObj = _.cloneDeep(props.accountObj);
      accountObj.lastDocumentValidationDate = Date.now() + "";
      const dismiss = NotificationHelper.createNotification(
        "Verifying Documents...",
        { timeout: 0, type: "ongoing" }
      );
      AccountService.updateAccount(props.email, accountObj)
        .then(() => {
          verificationInProgress.value = false;
          dismiss();
          NotificationHelper.createSuccessNotification(
            "Successfully verified documents!"
          );
          emit("update-last-validation-timestamp", null);
        })
        .catch(() => {
          NotificationHelper.createErrorNotification(
            "Failed to validate documents. Please try again in a few minutes."
          );
        })
        .finally(() => {
          verificationInProgress.value = false;
          dismiss();
        });
    }

    return {
      covidTestTableConfig,
      vaccineTableConfig,
      testingDataLoading,
      testingDesignGrid,
      loading,
      designGrid,
      testingData,
      vaccineData,
      dataExists,
      loadUnverifiedDocuments,
      openAction,
      submit,
      testingDataExists,
      vaccineDataExists,
      verificationInProgress,
    };
  },
  data() {
    return {
      context: {
        componentParent: this,
      },
    };
  },
};
</script>

<style scoped></style>
