<template>
  <div class="row justify-center align-center q-pt-md">
    <div>
      <q-btn
        color="primary"
        rounded
        @click="editableVisible = !editableVisible"
      >
        <template #default>
          <i
            aria-hidden="true"
            class="
              notranslate
              material-icons
              q-icon
              q-expansion-item__toggle-icon
              on-left
            "
            :class="{
              'q-expansion-item__toggle-icon--rotated': editableVisible,
            }"
            role="presentation"
            >add</i
          >
          {{ label }}
        </template>
      </q-btn>
    </div>
  </div>
  <q-slide-transition :duration="150">
    <div v-show="editableVisible" ref="sectionToScrollTo">
      <slot name="transition-content"></slot>
    </div>
  </q-slide-transition>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import { useSyncModelValue } from "@/utils/helpers/useSyncModelValue";
import { scrollElementIntoViewByRef } from "@/utils/helpers/ScrollHelper";

export default defineComponent({
  name: "SlideTransitionSectionWithToggleButton",
  props: {
    label: {
      type: String,
      default: "Open",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emit: ["update:visible"],
  setup(props, { emit }) {
    const editableVisible = ref();
    useSyncModelValue(
      props,
      "visible",
      emit,
      "update:visible",
      editableVisible
    );

    watch(
      () => editableVisible.value,
      (newVal) => {
        if (newVal) {
          setTimeout(() => scrollToTransitionSection(), 300);
        }
      }
    );

    const sectionToScrollTo = ref();
    function scrollToTransitionSection() {
      scrollElementIntoViewByRef(sectionToScrollTo, -100);
    }

    return {
      editableVisible,
      sectionToScrollTo,
    };
  },
});
</script>

<style lang="scss">
.q-expansion-item__toggle-icon--rotated {
  transform: rotate3d(0, 0, 1, 45deg) !important /* rtl:ignore */;
}
</style>
