import { ref } from "vue";

export function useValidateInputs(inputRefs) {
  const inputHasError = ref(false);

  function validate() {
    inputRefs.forEach((input) => {
      if (input && input.value && input.value.validate !== undefined) {
        input.value.validate();
      } else {
        console.warn(
          "One of the input refs passed could not be validated",
          inputRefs
        );
      }
    });
  }

  function hasError() {
    const inputRefsWithError = inputRefs.filter((input) => {
      return input && input.value
        ? input.value.hasError === true ||
            (typeof input.value.hasError === "function" &&
              input.value.hasError())
        : false;
    });
    const someInputHasError = inputRefsWithError.length > 0;
    inputHasError.value = someInputHasError;
    return someInputHasError;
  }

  function validateAndRunFunctionIfNoError(successFunc, errorFunc) {
    validate();
    setTimeout(() => {
      if (!hasError()) {
        successFunc();
      } else if (errorFunc) {
        errorFunc();
      }
    }, 0);
  }

  function validateAndUpdateInputHasError() {
    validate();
    setTimeout(() => (inputHasError.value = hasError()), 0);
  }

  function resetValidation() {
    setTimeout(() => {
      inputRefs.forEach((input) => {
        if (input && input.value && input.value.resetValidation) {
          input.value.resetValidation();
        }
      });
    }, 0);
  }

  return {
    inputHasError,
    validate,
    hasError,
    validateAndRunFunctionIfNoError,
    validateAndUpdateInputHasError,
    resetValidation,
  };
}
