<template>
  <q-page class="flex flex-center row q-pa-md">
    <div class="col-xs-12 col-sm-8 col-md-5 col-lg-4">
      <card-comp :fit="false" title="No Account Exists">
        <template #content>
          <q-card-section>
            <div class="text-subtitle1">
              An account with this email does not exist. Please contact your
              vendor representative for an account.
            </div>
          </q-card-section>
        </template>
      </card-comp>
    </div>
  </q-page>
</template>

<script>
import CardComp from "@/components/common/CardComp";

export default {
  name: "NoAccountPage",
  components: {
    CardComp,
  },
};
</script>

<style lang="scss"></style>
