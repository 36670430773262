<template>
  <q-card
    v-if="loading || !userAccount"
    class="q-pa-md row justify-center items-center frosted-glass"
    style="min-height: 200px"
  >
    <q-spinner color="primary" size="50px" />
  </q-card>
  <card-comp v-else :fit="false">
    <template #content>
      <admittance-display
        class="row justify-center"
        :is-admittable="isAdmittable"
      />
      <q-card-section class="q-pb-none">
        <div class="row full-width justify-center q-col-gutter-md">
          <div class="col-xs-12 col-sm-10 col-md-8">
            <div class="row">
              <div class="col-6">
                <field-comp
                  v-if="!editMode"
                  label="FIRST NAME"
                  style="font-size: 20px"
                  :value="displayFirstName"
                />
                <q-input
                  v-else
                  v-model="userAccount.firstName"
                  label="FIRST NAME"
                  label-color="primary"
                  stack-label
                />
              </div>
              <div class="col-6">
                <field-comp
                  v-if="!editMode"
                  label="LAST NAME"
                  style="font-size: 20px"
                  :value="displayLastName"
                />
                <q-input
                  v-else
                  v-model="userAccount.lastName"
                  label="LAST NAME"
                  label-color="primary"
                  stack-label
                />
              </div>
              <div class="col-6">
                <field-comp
                  v-if="!editMode"
                  label="PHONE NUMBER"
                  :value="formattedPhoneNumber"
                />
                <q-input
                  v-else
                  v-model="userAccount.phoneNumber"
                  label="PHONE NUMBER"
                  label-color="primary"
                  mask="(###) ### - ####"
                  stack-label
                  unmasked-value
                />
              </div>
              <div class="col-6">
                <field-comp label="EMAIL" :value="email" />
              </div>
              <div v-if="isAdminOrRepTypeAccount" class="col-12">
                <field-comp
                  v-if="!editMode"
                  label="ACCOUNT TYPE"
                  :value="userAccount.type"
                />
                <template v-else>
                  <div class="text-primary q-mt-sm" style="font-size: 12.3px">
                    ACCOUNT TYPE
                  </div>
                  <q-option-group
                    v-model="userAccount.type"
                    color="primary"
                    inline
                    :options="accountTypeOptions"
                  />
                </template>
              </div>
              <div class="col-12 text-right">
                <q-btn
                  v-if="!editMode"
                  class="q-ma-sm q-mb-md"
                  color="primary"
                  flat
                  icon="edit"
                  label="Edit"
                  rounded
                  @click="toggleEditMode"
                />
                <template v-else>
                  <q-btn
                    class="q-ma-sm q-mb-md"
                    color="primary"
                    :disable="saving"
                    flat
                    label="Cancel"
                    rounded
                    @click="cancelEdit"
                  />
                  <q-btn
                    class="q-ma-sm q-mb-md"
                    color="primary"
                    label="Save"
                    :loading="saving"
                    rounded
                    @click="save"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <div class="row q-col-gutter-md">
          <div class="col-xs-12 col-md-6">
            <test-summary-card
              :email="email"
              :last-document-validation-date="lastDocumentValidationDate"
              ref="testSummaryCard"
            />
          </div>
          <div class="col-xs-12 col-md-6">
            <vaccination-summary-card
              :email="email"
              :last-document-validation-date="lastDocumentValidationDate"
              ref="vaccinationSummaryCard"
            />
          </div>
        </div>
        <div v-if="isAdmittable" class="row justify-center">
          <div class="col-auto q-pa-sm">
            <img class="qr-code" :src="qrCode" />
          </div>
          <checkin-button-and-modal-component
            :email="email"
            @checkinsChanged="$emit('checkinsChanged')"
          />
        </div>
      </q-card-section>
    </template>
  </card-comp>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import CardComp from "../../components/common/CardComp.vue";
import AdmittanceDisplay from "@/views/contractor/profileInformation/AdmittanceDisplay";
import CheckinButtonAndModalComponent from "@/views/contractor/profileInformation/CheckinButtonAndModalComponent";
import TestSummaryCard from "@/views/contractor/profileInformation/TestSummaryCard";
import VaccinationSummaryCard from "@/views/contractor/profileInformation/VaccinationSummaryCard";
import FieldComp from "@/components/common/FieldComp";
import _ from "lodash";
import * as AccountService from "@/services/AccountService";
import NotificationHelper from "@/utils/helpers/NotificationHelper";

export default defineComponent({
  name: "ProfileInformationCard",
  components: {
    FieldComp,
    VaccinationSummaryCard,
    TestSummaryCard,
    CheckinButtonAndModalComponent,
    AdmittanceDisplay,
    CardComp,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: "",
    },
    isAdmittable: {
      type: Boolean,
      default: false,
    },
    momentTestDate: {
      type: Object,
      default: () => {},
    },
    momentVaccinationDate: {
      type: Object,
      default: () => {},
    },
    qrCode: {
      type: String,
      default: "",
    },
    isAdminOrRepTypeAccount: {
      type: Boolean,
      default: false,
    },
    lastDocumentValidationDate: {
      type: String,
      default: "",
    },
  },
  emits: ["checkinsChanged", "accountUpdated"],
  setup(props, { emit }) {
    const editMode = ref(false);
    const saving = ref(false);

    function toggleEditMode() {
      editMode.value = !editMode.value;
    }

    const displayFirstName = computed(() =>
      userAccount.value && userAccount.value.firstName
        ? userAccount.value.firstName
        : null
    );

    const displayLastName = computed(() =>
      userAccount.value && userAccount.value.lastName
        ? userAccount.value.lastName
        : null
    );

    const formattedPhoneNumber = computed(() => {
      let phoneNumber = userAccount.value.phoneNumber;
      if (userAccount.value && phoneNumber) {
        if (!isNaN(phoneNumber)) {
          phoneNumber = phoneNumber.toString();
        }
        return (
          "(" +
          phoneNumber.substring(0, 3) +
          ") " +
          phoneNumber.substring(3, 6) +
          " - " +
          phoneNumber.substring(6, 10)
        );
      }
      return "";
    });

    watch(
      () => props.email,
      (newVal) => {
        if (newVal) {
          getAccount();
        }
      },
      { immediate: true }
    );

    const preEditUserAccount = ref();
    const userAccount = ref();
    function getAccount() {
      AccountService.getAccount(props.email).then((response) => {
        userAccount.value = response;
        preEditUserAccount.value = _.cloneDeep(userAccount.value);
      });
    }

    function cancelEdit() {
      userAccount.value = _.cloneDeep(preEditUserAccount.value);
      toggleEditMode();
    }

    async function save() {
      saving.value = true;
      await AccountService.saveAccount(props.email, userAccount.value);

      NotificationHelper.createSuccessNotification(
        "Account successfully edited"
      );
      getAccount();
      toggleEditMode();
      emit("accountUpdated");

      saving.value = false;
    }

    const accountTypeOptions = [
      {
        label: "Contractor",
        value: "CONTRACTOR",
      },
      {
        label: "Vendor Representative",
        value: "VENDOR_REP",
      },
    ];

    const testSummaryCard = ref();
    const vaccinationSummaryCard = ref();

    function reloadTestAndVaccinationSummary() {
      if (testSummaryCard.value) {
        testSummaryCard.value.getTestSummary();
      }
      if (vaccinationSummaryCard.value) {
        vaccinationSummaryCard.value.getVaccinationStatus();
      }
    }

    return {
      editMode,
      saving,
      toggleEditMode,
      userAccount,
      formattedPhoneNumber,
      displayFirstName,
      displayLastName,
      accountTypeOptions,
      save,
      cancelEdit,
      testSummaryCard,
      vaccinationSummaryCard,
      reloadTestAndVaccinationSummary,
    };
  },
});
</script>

<style scoped lang="scss">
.qr-code {
  height: 10rem;
}
</style>
