import { render } from "./ContractorPageAccountDNE.vue?vue&type=template&id=18dd36b9"
import script from "./ContractorPageAccountDNE.vue?vue&type=script&lang=js"
export * from "./ContractorPageAccountDNE.vue?vue&type=script&lang=js"

import "./ContractorPageAccountDNE.vue?vue&type=style&index=0&id=18dd36b9&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCardSection});
