import firebase from "firebase/app";

export default {
  async isAdmittable(email, lastDocumentValidationDate) {
    const isAdmittable = firebase.functions().httpsCallable("isAdmittable");
    // If lastDocumentValidationDate == null, the account's documents were never validated,
    // so set it to a date older than all documents.
    if (lastDocumentValidationDate == null) {
      lastDocumentValidationDate = 1;
    }
    return isAdmittable({
      email,
      lastDocumentValidationDate,
    }).then((result) => {
      return result.data;
    });
  },
  async someDocumentNeedsReview(email, lastDocumentValidationDate) {
    const someDocumentNeedsReview = firebase
      .functions()
      .httpsCallable("someDocumentNeedsReview");
    return someDocumentNeedsReview({
      email,
      lastDocumentValidationDate,
    }).then((result) => {
      return result.data;
    });
  },
};
