export const menuList = [
  {
    icon: "people",
    label: "Manage Admin Accounts",
    path: "/admin/admin-accounts",
  },
  {
    icon: "edit_location",
    label: "View Locations",
    path: "/admin/locations",
  },
  {
    icon: "camera_front",
    label: "Manage Vendors",
    path: "/admin/vendors",
  },
];
