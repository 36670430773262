import _ from "lodash";
import NotificationHelper from "@/utils/helpers/NotificationHelper";

function getBrowserLocationAndExecuteFunction(successFunction, errorFunction) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        successFunction(position.coords.latitude, position.coords.longitude);
      },
      (error) => {
        errorFunction();
        if (error.code === 1) {
          NotificationHelper.createErrorNotification(
            "Please allow location access and reload the page to enable this feature. Or manually input location"
          );
        }
      }
    );
  }
}

function getDistanceBetweenCoordinatesViaHaversine(lat1, lon1, lat2, lon2) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c; // Distance in km
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function getLatLongFromAddressUsingGoogleMaps(address) {
  const location = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&&key=${process.env.VUE_APP_API_KEY}`;
  return fetch(location)
    .then((resp) => resp.json())
    .then((resp) => getLatLongFromGoogleMapsResponse(resp))
    .catch(() => {});
}

function getLatLongFromGoogleMapsResponse(response) {
  const coordinates = { lat: null, lng: null };

  if (
    response.status === "OK" &&
    response.results &&
    response.results.length > 0
  ) {
    _.assign(coordinates, response.results[0].geometry.location);
  }
  return coordinates;
}

function getAddressFromLatLongUsingGoogleMaps(lat, long) {
  const location = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&&key=${process.env.VUE_APP_API_KEY}`;
  return fetch(location)
    .then((resp) => resp.json())
    .then((resp) => {
      return getAddressFromGoogleMapsResponse(resp);
    })
    .catch(() => {});
}

function getAddressFromGoogleMapsResponse(response) {
  const addressObject = {};

  if (response && response.results && response.results.length > 0) {
    const addressComponents = response.results[0].address_components;

    const streetNumber = getAddressComponentNameByType(
      addressComponents,
      "street_number"
    );

    const streetName = getAddressComponentNameByType(
      addressComponents,
      "route"
    );
    addressObject.address = streetName ? `${streetNumber} ${streetName}` : "";

    addressObject.city = getAddressComponentNameByType(
      addressComponents,
      "locality"
    );

    addressObject.state = getAddressComponentNameByType(
      addressComponents,
      "administrative_area_level_1",
      "short_name"
    );

    addressObject.pincode = getAddressComponentNameByType(
      addressComponents,
      "postal_code"
    );
  } else {
    NotificationHelper.createErrorNotification(
      "Could not determine your location from google maps"
    );
  }

  return addressObject;
}

function getAddressComponentNameByType(
  addressComponents,
  type,
  nameKey = "long_name"
) {
  const addressComponent = addressComponents.find((component) =>
    component.types.includes(type)
  );
  return addressComponent && addressComponent[nameKey]
    ? addressComponent[nameKey]
    : "";
}

export {
  getDistanceBetweenCoordinatesViaHaversine,
  getAddressFromLatLongUsingGoogleMaps,
  getLatLongFromAddressUsingGoogleMaps,
  getBrowserLocationAndExecuteFunction,
};
