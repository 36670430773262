import { render } from "./VendorPage.vue?vue&type=template&id=725e1884"
import script from "./VendorPage.vue?vue&type=script&lang=js"
export * from "./VendorPage.vue?vue&type=script&lang=js"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QBtn,QCardSection});
