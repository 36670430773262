<template>
  <q-card-section
    v-if="isAdmittable"
    class="full-width row justify-center bg-positive"
  >
    <div class="col-auto">
      <q-icon
        class="text-white q-px-sm"
        name="done"
        style="font-size: 36px"
        tooltip="Admittable"
      >
        <q-tooltip>Admittable</q-tooltip>
      </q-icon>
    </div>
    <div class="col-auto">
      <span class="text-h4 text-white">Admittable</span>
    </div>
  </q-card-section>

  <q-card-section v-else class="full-width row justify-center bg-negative">
    <div class="col-auto">
      <q-icon
        class="text-white q-px-sm"
        name="clear"
        style="font-size: 36px"
        tooltip="Not Admittable"
      >
        <q-tooltip>Not Admittable</q-tooltip>
      </q-icon>
    </div>
    <div class="col-auto">
      <span class="text-h4 text-white">Not Admittable</span>
    </div>
  </q-card-section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AdmittanceDisplay",
  props: {
    isAdmittable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["checkinsChanged"],
});
</script>

<style lang="scss"></style>
