import "firebase/firestore";
import firebase from "firebase";

export default {
  getCheckInInfo(id) {
    const getCheckInInfo = firebase
      .functions()
      .httpsCallable("getCheckInInfoAppCheckUserCheck");
    return getCheckInInfo({ contractorId: id }).then((result) => {
      return result.data;
    });
  },
  saveCheckInInfo(id, checkInData) {
    const saveCheckInInfo = firebase
      .functions()
      .httpsCallable("saveCheckInInfoAppCheckUserCheck");
    return saveCheckInInfo({ contractorId: id, checkInData: checkInData }).then(
      (result) => {
        return result;
      }
    );
  },
};
