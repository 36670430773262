import { render } from "./AdminDialog.vue?vue&type=template&id=73c72db3"
import script from "./AdminDialog.vue?vue&type=script&lang=js"
export * from "./AdminDialog.vue?vue&type=script&lang=js"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QField});
