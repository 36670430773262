import firebase from "firebase/app";
import router from "@/router";
import { computed, ref } from "vue";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import * as AccountService from "@/services/AccountService";

const user = ref();
const currentUserAccount = ref();

function handleRouting(userAccount, email) {
  if (userAccount.type === AccountService.CONTRACTOR_TYPE) {
    router.push(`/contractor/${email}`);
  }
  if (userAccount.type === AccountService.VENDOR_TYPE) {
    router.push(`/vendor/${userAccount.vendorId}`);
  }
  if (userAccount.type === AccountService.ADMIN_TYPE) {
    router.push("/admin");
  }
}

export function useAuthenticationHelper({ useLanding } = {}) {
  const displayName = computed(() =>
    user.value && user.value.displayName ? user.value.displayName : null
  );
  const googleProfileImage = computed(() =>
    user.value && user.value.photoURL ? user.value.photoURL : null
  );
  const email = computed(() =>
    user.value && user.value.email ? user.value.email : null
  );

  firebase.auth().onAuthStateChanged((newUser) => {
    user.value = newUser;
    if (user.value) {
      AccountService.getAccount(user.value.email)
        .then((response) => {
          currentUserAccount.value = response;

          if (!response.type) {
            router.push("/login/none");
          }
          if (useLanding) {
            handleRouting(currentUserAccount.value, user.value.email);
          }
        })
        .catch(() => {
          router.push("/login/none");
        });
    } else {
      redirectToLoginPage();
    }
  });

  const currentURLAlreadyContainsContinue = computed(() =>
    window.location.href.includes("continue")
  );

  const urlPointsToNonLoginPage = computed(() =>
    ["/contractor", "/vendor", "/admin"].some((pageUrlSubstring) =>
      window.location.href.includes(pageUrlSubstring)
    )
  );

  function getCurrentRoute() {
    return window.location.href.split("#")[1];
  }

  function redirectToLoginPage() {
    if (!currentURLAlreadyContainsContinue.value) {
      if (urlPointsToNonLoginPage.value) {
        router.push(`/login?continue=${getCurrentRoute()}`);
      } else {
        router.push("/login");
      }
    }
  }

  const userSignedIn = computed(() => !!user.value);
  const userAccountExists = computed(
    () => currentUserAccount.value && !!currentUserAccount.value.exists
  );

  function signOut() {
    router.push("/login");

    firebase
      .auth()
      .signOut()
      .catch(() => {
        // An error happened.
      });
  }

  function getContinueRoute() {
    return window.location.href.split("?continue=")[1];
  }

  function loadFirebaseLoginUI(elementSelector) {
    // https://softauthor.com/firebaseui-vue-facebook-google-auth/
    // https://firebase.google.com/docs/auth/web/firebaseui
    let ui = firebaseui.auth.AuthUI.getInstance();
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    const uiConfig = {
      callbacks: {
        signInSuccessWithAuthResult: function () {
          const continueRoute = getContinueRoute();

          // User successfully signed in.
          // Return type determines whether we continue the redirect automatically
          // or whether we leave that to developer to handle.
          router.push(continueRoute ? `${continueRoute}` : "/login/landing");
          return true;
        },
        uiShown: function () {
          // The widget is rendered.
          // Hide the loader.
          document.getElementById("loader").style.display = "none";
        },
        signInSuccessUrl: function () {
          return false;
        },
      },
      // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
      signInFlow: "popup",
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      signInSuccessUrl: "",
    };
    ui.start(elementSelector, uiConfig);
  }

  return {
    user: computed(() => {
      return {
        displayName: user.value?.displayName ?? null,
        profileImage: user.value?.photoURL ?? null,
        email: user.value?.email ?? null,
        isEmailVerified: user.value?.email ?? null,
      };
    }),
    signOut,
    userSignedIn,
    currentUserAccount,
    userAccountExists,
    loadFirebaseLoginUI,
    displayName,
    email,
    googleProfileImage,
    handleRouting,
  };
}
