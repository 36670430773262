<template>
  <q-input
    v-model="internalValue"
    :label="label"
    mask="##/##/####"
    :outlined="outlined"
    :placeholder="placeholder"
    :rules="internalRules"
    stack-label
    ref="inputRef"
  >
    <template #prepend>
      <q-icon name="calendar_month" />
    </template>
    <template #append>
      <q-icon class="cursor-pointer" name="edit_calendar">
        <q-popup-proxy
          anchor="bottom left"
          :cover="false"
          :offset="[-36, 16]"
          self="top right"
          transition-hide="jump-up"
          transition-show="jump-down"
          ref="popupRef"
        >
          <q-date
            v-model="internalValue"
            :disable="disable"
            mask="MM/DD/YYYY"
            :minimal="minimal"
            :navigation-max-year-month="navigationMaxYearMonth"
            :navigation-min-year-month="navigationMinYearMonth"
            :no-unset="noUnset"
            :options="options"
            :readonly="readonly"
            :today-btn="todayBtn"
            @update:modelValue="onSelect"
            ref="dateRef"
          >
            <div v-if="closeBtn" class="row items-center justify-end">
              <q-btn v-close-popup color="primary" flat label="Close" />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import moment from "moment";
import Rule from "@/utils/Rule";

export default defineComponent({
  name: "DatePicker",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: undefined,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "mm/dd/yyyy",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    todayBtn: {
      type: Boolean,
      default: true,
    },
    closeBtn: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    options: {
      type: [Array, Function],
      default: undefined,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    navigationMinYearMonth: {
      type: String,
      default: undefined,
    },
    navigationMaxYearMonth: {
      type: String,
      default: undefined,
    },
    noUnset: {
      type: Boolean,
      default: true,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { expose, emit }) {
    const inputRef = ref(null);
    const popupRef = ref(null);
    const dateRef = ref(null);
    const internalValue = ref(null);
    const internalRules = computed(() => {
      return [Rule.VALID_DATE, ...props.rules];
    });

    function proxyMethod(ref, method) {
      return (...args) => {
        return ref.value[method](...args);
      };
    }

    // Add methods to component
    expose({
      resetValidation: proxyMethod(inputRef, "resetValidation"),
      validate: proxyMethod(inputRef, "validate"),
      focus: proxyMethod(inputRef, "focus"),
      blur: proxyMethod(inputRef, "blur"),
      select: proxyMethod(inputRef, "select"),
      getNativeElement: proxyMethod(inputRef, "getNativeElement"),
      setToday: proxyMethod(dateRef, "setToday"),
      setView: proxyMethod(dateRef, "setView"),
      offsetCalendar: proxyMethod(dateRef, "offsetCalendar"),
      setCalendarTo: proxyMethod(dateRef, "setCalendarTo"),
      setEditingRange: proxyMethod(dateRef, "setEditingRange"),
    });

    watch(internalValue, (newValue, previousValue) => {
      if (newValue === previousValue) {
        return;
      }

      if (newValue === null || newValue === undefined || newValue === "") {
        emit("update:modelValue", "");
        return;
      }

      const date = moment(newValue, "MM/DD/YYYY", true);

      if (date.isValid()) {
        emit("update:modelValue", date.format("YYYY-MM-DD"));
      } else {
        emit("update:modelValue", "");
      }
    });

    function updateInternalValue(value) {
      const date = moment(value, "YYYY-MM-DD", true);

      if (date.isValid()) {
        internalValue.value = date.format("MM/DD/YYYY");
      } else {
        internalValue.value = null;
      }
    }

    watch(
      () => {
        return props.modelValue;
      },
      (newValue, previousValue) => {
        if (newValue === previousValue) {
          return;
        }

        updateInternalValue(newValue);
      }
    );
    // Set initial value
    updateInternalValue(props.modelValue);

    function onSelect() {
      if (props.closeOnSelect) {
        popupRef.value.hide();
      }
    }

    return {
      Rule,
      inputRef,
      popupRef,
      dateRef,
      internalValue,
      internalRules,
      onSelect,
    };
  },
});
</script>

<style></style>
