import "firebase/firestore";
import firebase from "firebase";

export const ADMIN_TYPE = "ADMIN";
export const VENDOR_TYPE = "VENDOR_REP";
export const CONTRACTOR_TYPE = "CONTRACTOR";

export function getAccount(userEmail) {
  const getAccount = firebase.functions().httpsCallable("getAccount");
  if (userEmail) {
    return getAccount({ email: userEmail }).then((result) => {
      return result.data;
    });
  }
}

export function getAdminTypeAccounts() {
  const getAdminTypeAccounts = firebase
    .functions()
    .httpsCallable("getAdminTypeAccounts");

  return getAdminTypeAccounts().then((result) => {
    return result.data;
  });
}

export function saveAccount(email, accountObj) {
  const saveAccount = firebase.functions().httpsCallable("saveAccount");
  return saveAccount({ email: email, accountObj: accountObj }).then(
    (result) => {
      return result.data;
    }
  );
}

export function updateAccount(email, accountObj) {
  const updateAccount = firebase.functions().httpsCallable("updateAccount");
  return updateAccount({ email: email, accountObj: accountObj }).then(
    (result) => {
      return result.data;
    }
  );
}

export function deleteAccount(email) {
  const deleteAccount = firebase.functions().httpsCallable("deleteAccount");
  return deleteAccount({ email: email }).then((result) => {
    return result.data;
  });
}

export async function updateIsDeletedField(email) {
  const updateIsDeletedField = firebase
    .functions()
    .httpsCallable("updateIsDeletedField");
  return updateIsDeletedField({ email: email }).then((result) => {
    return result.data;
  });
}

export function getAccountsWithAdmittanceAndReviewDataWithEmails(userEmails) {
  const getAccountsWithAdmittanceAndReviewDataWithEmails = firebase
    .functions()
    .httpsCallable("getAccountsWithAdmittanceAndReviewDataWithEmails");
  return getAccountsWithAdmittanceAndReviewDataWithEmails(userEmails).then(
    (result) => {
      return result.data;
    }
  );
}

export function doesAccountWithEmailExistsAndIsNotDeleted(email) {
  if (email) {
    const doesAccountWithEmailExistsAndIsNotDeleted = firebase
      .functions()
      .httpsCallable("doesAccountWithEmailExistsAndIsNotDeleted");
    return doesAccountWithEmailExistsAndIsNotDeleted({ email: email }).then(
      (result) => {
        return result.data;
      }
    );
  }
}
