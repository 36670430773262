<template>
  <login-comp v-if="!userIsSignedIn" @user-signed-in="userAuthChanged" />
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

import { ref, computed } from "vue";

import LoginComp from "@/components/LoginComp";

export default {
  name: "Login",
  components: {
    LoginComp,
  },
  setup() {
    const googleAuthProvider = ref(new firebase.auth.GoogleAuthProvider());
    const user = ref(null);
    const userAccount = ref(null);

    const userExists = computed(
      () => userAccount.value && userAccount.value.exists
    );

    const userIsSignedIn = computed(() => !!user.value);

    const userIsVendor = computed(
      () =>
        userIsSignedIn.value &&
        userExists.value &&
        userAccount.value.type === "VENDOR"
    );

    function signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
        })
        .catch(() => {
          // An error happened.
        });
    }

    function userAuthChanged(isSignedIn) {
      userIsSignedIn.value = isSignedIn;
    }

    return {
      googleAuthProvider,
      user,
      userAccount,
      userIsVendor,
      userExists,
      userIsSignedIn,
      signOut,
      userAuthChanged,
    };
  },
};
</script>

<style></style>
