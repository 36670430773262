import firebase from "firebase/app";

export default {
  async getVaccinationStatus(email, lastDocumentValidationDate) {
    const getVaccinationStatus = firebase
      .functions()
      .httpsCallable("getVaccinationStatus");
    // If lastDocumentValidationDate == null, the account's documents were never validated,
    // so set it to a date older than all documents.
    if (lastDocumentValidationDate == null) {
      lastDocumentValidationDate = 1;
    }
    return await getVaccinationStatus({
      email,
      lastDocumentValidationDate,
    }).then((result) => {
      return result.data;
    });
  },
};
