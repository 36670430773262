// This File is WIP until the Mayan environment is setup and tested
// The URL should be switched to the production
import appxios from "@/utils/Appxios";
import URLHelper from "@/utils/helpers/URLHelper";
import FunctionsHelper from "@/utils/helpers/FunctionsHelper";

export default {
  async uploadDocument(email, filename, metadata) {
    const response = await appxios.post(
      FunctionsHelper.getURL(`documents/${email}`),
      { filename, metadata }
    );

    return {
      documentId: response.data.id,
      name: response.data.label,
    };
  },

  async openDocument(email, documentId) {
    const { data } = await appxios.get(
      FunctionsHelper.getURL(`documents/${email}/${documentId}`),
      {
        responseType: "blob",
      }
    );
    const objectUrl = URL.createObjectURL(data);

    URLHelper.goToURLInNewTab(objectUrl);
    // Release resources
    // TODO: Check in multiple browsers to see if calling this immediately after opening prevents page load
    URL.revokeObjectURL(objectUrl);
  },

  async deleteDocument(email, documentId) {
    await appxios.delete(
      FunctionsHelper.getURL(`documents/${email}/${documentId}`),
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
  },

  async getThumbnail(email, documentId) {
    if (documentId) {
      const response = await appxios.get(
        FunctionsHelper.getURL(`documentThumbnail/${email}/${documentId}`),
        {
          responseType: "blob",
        }
      );

      return response.data;
    }
  },
};
