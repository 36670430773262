import { render } from "./ContractorDialog.vue?vue&type=template&id=6dcc22fc"
import script from "./ContractorDialog.vue?vue&type=script&lang=js"
export * from "./ContractorDialog.vue?vue&type=script&lang=js"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QOptionGroup});
