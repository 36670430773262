<template>
  <div class="q-pt-md">
    <q-stepper v-model="step" animated color="primary" flat vertical>
      <q-step
        :done="step > 0"
        icon="file_upload"
        :name="0"
        title="Upload Your Test Document"
      >
        <q-form class="row q-col-gutter-sm" greedy :ref="form[0]">
          <div class="col-xs-12 col-sm-6">
            <q-file
              v-model="file"
              label="Upload Proof *"
              outlined
              :rules="[Rule.REQUIRED]"
              stack-label
            >
              <template #prepend>
                <q-icon name="attach_file" />
              </template>
            </q-file>
          </div>
          <div class="col-xs-12 col-sm-6">
            <q-select
              v-model="provider"
              clearable
              label="Testing Provider *"
              option-label="name"
              :options="providerOptions"
              options-dense
              outlined
              :rules="[Rule.REQUIRED]"
              stack-label
            >
              <template #prepend>
                <q-icon name="business" />
              </template>
            </q-select>
          </div>
          <div v-if="provider === 'Other'" class="col-xs-12">
            <q-input
              v-model="otherProvider"
              label="Provider Name *"
              outlined
              :rules="[Rule.REQUIRED]"
              stack-label
            />
          </div>
        </q-form>
        <q-stepper-navigation>
          <div class="row justify-center">
            <div class="col-auto">
              <q-btn
                color="primary"
                icon="upload"
                label="Upload"
                :loading="isUploading"
                rounded
                @click="uploadForExtraction"
              />
            </div>
          </div>
        </q-stepper-navigation>
      </q-step>
      <q-step
        :done="step > 1"
        icon="edit_note"
        :name="1"
        title="Test Information"
      >
        <q-form class="row q-col-gutter-md" greedy :ref="form[1]">
          <message-box v-if="didPrefill">
            Some information has been filled in for you. Please check the
            following for accuracy and make corrections as necessary. Fields
            marked with an asterisk (*) are required.
          </message-box>
          <div class="col-xs-12 col-sm-7 col-md-6">
            <date-picker
              v-model="dateOfBirth"
              close-btn
              label="Date of Birth *"
              minimal
              :navigation-max-year-month="maxYearMonth"
              :options="isDateAllowed"
              outlined
              :rules="[Rule.REQUIRED, Rule.NO_FUTURE_DATE]"
            />
          </div>
          <div class="col-xs-12 col-sm-7 col-md-6">
            <date-picker
              v-model="testDate"
              close-btn
              label="Test Date *"
              minimal
              :navigation-max-year-month="maxYearMonth"
              :options="isDateAllowed"
              outlined
              :rules="[Rule.REQUIRED, Rule.NO_FUTURE_DATE]"
            />
          </div>
          <div class="col-12">
            <q-field label="Test Result *" outlined stack-label>
              <template class="q-my-auto" #prepend>
                <q-icon name="vaccines" />
              </template>
              <template #control>
                <q-radio v-model="testStatus" label="Negative" val="negative" />
                <q-radio v-model="testStatus" label="Positive" val="positive" />
              </template>
            </q-field>
          </div>
        </q-form>
        <q-stepper-navigation>
          <q-btn
            color="primary"
            label="Continue"
            rounded
            @click="goToStepIfAllowed(2)"
          />
          <q-btn
            class="q-ml-sm"
            color="primary"
            flat
            label="Back"
            rounded
            @click="goToStepIfAllowed(0)"
          />
        </q-stepper-navigation>
      </q-step>
      <q-step :done="step > 2" icon="send" :name="2" title="Final Submission">
        By clicking <span class="text-bold">SUBMIT</span>, you attest to the
        following: <br /><br />
        I attest that the information provided in this form is accurate and true
        to the best of my knowledge. I understand that knowingly making a false
        statement on this form is a crime and can be punished by fine or
        imprisonment or both (HRS § 710-1063). I understand that making a false
        statement on this form may also result in disciplinary action, up to and
        including termination.
        <q-stepper-navigation>
          <q-btn
            color="primary"
            label="Submit"
            :loading="isSubmitting"
            rounded
            @click="submit"
          />
          <q-btn
            class="q-ml-sm"
            color="primary"
            :disable="isSubmitting"
            flat
            label="Back"
            rounded
            @click="goToStepIfAllowed(1)"
          />
        </q-stepper-navigation>
      </q-step>
    </q-stepper>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import moment from "moment";
import MessageBox from "@/components/common/MessageBox";
import DatePicker from "@/components/common/DatePicker";
import NotificationHelper from "@/utils/helpers/NotificationHelper.js";
import * as ExtractionPipelineService from "@/services/ExtractionPipelineService";
import * as AccountService from "@/services/AccountService";
import Rule from "@/utils/Rule";

const stringOptions = ExtractionPipelineService.Provider;
export default defineComponent({
  name: "CovidTestCompInputs",
  components: { MessageBox, DatePicker },
  props: {
    email: {
      type: String,
      default: "",
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const providerOptions = ref([
      ...Object.values(stringOptions).sort(),
      "Other",
    ]);
    const step = ref(0);
    const isUploading = ref(false);
    const form = [ref(null), ref(null)];
    const file = ref();
    const provider = ref();
    const otherProvider = ref();
    const dateOfBirth = ref();
    const testDate = ref();
    const testStatus = ref();
    const filename = ref(null);
    let didPrefill = ref(false);

    function resetFields() {
      file.value = null;
      provider.value = null;
      otherProvider.value = null;
      dateOfBirth.value = null;
      testDate.value = null;
      testStatus.value = "negative";
      didPrefill.value = false;
    }
    resetFields();

    const submissionData = computed(() => {
      return {
        filename: filename.value,
        testingProvider:
          provider.value === "Other" ? otherProvider.value : provider.value,
        dateOfBirth: dateOfBirth.value,
        testDate: testDate.value,
        testStatus: testStatus.value,
      };
    });

    function resetStepper() {
      resetFields();
      step.value = 0;
    }

    async function goToStepIfAllowed(nextStep) {
      if (await inputsOfPreviousStepAreValid(nextStep)) {
        step.value = nextStep;
      }
    }

    async function inputsOfPreviousStepAreValid(currentStep) {
      const previousStep = currentStep - 1;
      const previousFormValidates = await form[previousStep]?.value?.validate();

      return previousFormValidates ?? true;
    }

    function submit() {
      emit("submit", submissionData.value);
    }

    async function uploadForExtraction() {
      const validates = await form[0].value.validate();
      if (!validates) {
        return;
      }

      isUploading.value = true;
      const dismiss = NotificationHelper.createNotification(
        "Uploading test result...",
        { timeout: 0, type: "ongoing" }
      );

      try {
        const response = await ExtractionPipelineService.docAIExtractionUpload(
          file.value,
          {
            type: "TEST",
            firstName: userAccount.value?.firstName ?? null,
            lastName: userAccount.value?.firstName ?? null,
            provider: provider.value,
          }
        );
        filename.value = response.filename;

        if (
          response.extraction.results.status !==
          "Not Approved: Not Trusted Testing Partner"
        ) {
          prepopulateInputsFromResponse(response.extraction);
          didPrefill.value = true;
        }

        step.value = 1;
      } catch (error) {
        NotificationHelper.createErrorNotification(
          "Test result failed to upload. Please try again in a few minutes."
        );
      } finally {
        isUploading.value = false;
        dismiss();
      }
    }

    const maxYearMonth = computed(() => {
      return moment().format("YYYY/MM");
    });

    function isDateAllowed(value) {
      const today = moment().startOf("day");
      const date = moment(value, "YYYY/MM/DD").startOf("day");

      return date.isSameOrBefore(today);
    }

    const userAccount = ref();
    function getAccount() {
      if (props.email) {
        AccountService.getAccount(props.email).then((response) => {
          userAccount.value = response;
        });
      }
    }

    watch(
      () => props.email,
      () => getAccount(),
      { immediate: true }
    );

    function prepopulateInputsFromResponse(response) {
      testDate.value = grabValueAsFormattedDate(response, "dateOfTest");
      testStatus.value =
        grabValueFromResponseIfExists(response, "testResults")?.toLowerCase() ??
        "negative";
      dateOfBirth.value = grabValueAsFormattedDate(response, "dateOfBirth");
    }

    function grabValueAsFormattedDate(response, key) {
      const dateAsUnixString = grabValueFromResponseIfExists(response, key);
      return dateAsUnixString
        ? moment.unix(dateAsUnixString).utc().format("YYYY-MM-DD")
        : null;
    }

    function grabValueFromResponseIfExists(response, key) {
      const results = response.results;
      return results?.[key]?.value ?? null;
    }

    return {
      Rule,
      step,
      maxYearMonth,
      isDateAllowed,
      form,
      file,
      didPrefill,
      submit,
      resetStepper,
      goToStepIfAllowed,
      uploadForExtraction,
      isUploading,
      testStatus,
      provider,
      providerOptions,
      testDate,
      dateOfBirth,
      otherProvider,
    };
  },
});
</script>

<style lang="scss"></style>
