import { ref } from "vue";

export default class Timer extends EventTarget {
  /**
   * @param {number} duration - timer length
   * @param {boolean} [options.updateInterval] - how often the timer should auto-update the remaining time
   * @returns Timer
   */
  constructor(duration, options = {}) {
    super();

    this.options = {
      updateInterval: options.updateInterval ?? 1000,
    };
    this.timeout = null;
    this.interval = null;
    this.duration = duration;
    this.timeStarted = null;
    this.remaining = ref(null);
  }

  start() {
    this.timeout = setTimeout(() => {
      this.trigger();
    }, this.duration);
    this.interval = setInterval(() => {
      this.getRemaining();
    }, this.options.updateInterval);
    this.timeStarted = Date.now();

    // Calculate the remaining time immediately
    this.getRemaining();
  }

  stop() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      clearInterval(this.interval);
      this.timeout = null;
      this.interval = null;
      this.timeStarted = null;
    }
  }

  trigger() {
    this.dispatchEvent(new Event("end"));
  }

  getRemaining() {
    if (this.timeStarted !== null) {
      this.remaining.value = this.duration - (Date.now() - this.timeStarted);
    }

    return this.remaining.value;
  }
}
