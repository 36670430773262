import NotificationHelper from "@/utils/helpers/NotificationHelper";
import * as DocumentService from "@/services/DocumentService";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getMessageString(documentType) {
  switch (documentType) {
    case DocumentService.DocumentType.VACCINATION:
      return "vaccination record";
    case DocumentService.DocumentType.TEST_RESULT:
      return "test result";
    default:
      return "document";
  }
}

function openFile(messageString, email, cellValue) {
  const dismiss = NotificationHelper.createNotification(
    "Opening " + messageString + "...",
    {
      timeout: 0,
      type: "ongoing",
    }
  );

  DocumentService.openDocument(email, cellValue)
    .then(() => {
      dismiss();
    })
    .catch((error) => {
      console.error(error);
      dismiss();
      NotificationHelper.createErrorNotification(
        "Failed to open " +
          messageString +
          ". Please try again in a few minutes."
      );
    });
}

function deleteFile(messageString, email, data, cellValue) {
  const dismiss = NotificationHelper.createNotification(
    "Deleting " + messageString + "...",
    { timeout: 0, type: "ongoing" }
  );

  return DocumentService.deleteDocument(email, cellValue)
    .then(() => {
      dismiss();
      NotificationHelper.createSuccessNotification(
        capitalizeFirstLetter(messageString) + " successfully deleted!"
      );
      data.value = data.value.filter((row) => {
        return row.id !== cellValue;
      });
    })
    .catch((error) => {
      console.error(error);
      dismiss();
      NotificationHelper.createErrorNotification(
        capitalizeFirstLetter(messageString) +
          " failed to delete. Please try again in a few minutes."
      );
    });
}

export function openAction(documentType, email, cellValue) {
  const messageString = getMessageString(documentType);

  openFile(messageString, email, cellValue);
}

export function deleteAction(documentType, email, data, cellValue) {
  const messageString = getMessageString(documentType);

  return deleteFile(messageString, email, data, cellValue);
}
