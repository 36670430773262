import { render } from "./ProfileInformationCard.vue?vue&type=template&id=4c80f026&scoped=true"
import script from "./ProfileInformationCard.vue?vue&type=script&lang=js"
export * from "./ProfileInformationCard.vue?vue&type=script&lang=js"

import "./ProfileInformationCard.vue?vue&type=style&index=0&id=4c80f026&scoped=true&lang=scss"
script.render = render
script.__scopeId = "data-v-4c80f026"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QSpinner,QCardSection,QInput,QOptionGroup,QBtn});
