import moment from "moment";
import { doesAccountWithEmailExistsAndIsNotDeleted } from "@/services/AccountService";

export default {
  REQUIRED(value) {
    return (value !== null && value !== "") || "Required";
  },
  VALID_DATE(value) {
    return (
      value === null ||
      value === "" ||
      moment(value, "MM/DD/YYYY", true).isValid() ||
      "Invalid date (mm/dd/yyyy)"
    );
  },
  NO_FUTURE_DATE(value) {
    const today = moment().startOf("day");
    const date = moment(value, "MM/DD/YYYY").startOf("day");

    return date.isSameOrBefore(today) || "Cannot use future date";
  },
  async UNUSED_EMAIL(value) {
    const result = await doesAccountWithEmailExistsAndIsNotDeleted(value);
    return !result || "Email already in use";
  },
};
