import { render } from "./AdmittanceDisplay.vue?vue&type=template&id=9ff0df7e"
import script from "./AdmittanceDisplay.vue?vue&type=script&lang=js"
export * from "./AdmittanceDisplay.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCardSection,QIcon,QTooltip});
