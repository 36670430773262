<template>
  <div id="admin-page">
    <q-page class="q-pa-md column">
      <div class="row grow-1" style="flex-grow: 1">
        <div class="col-12">
          <card-comp>
            <template #content>
              <div class="full-height column">
                <div class="col-auto q-pl-md q-pr-md q-pt-md q-pb-none">
                  <div class="row justify-between">
                    <div class="col-auto">
                      <span class="text-h4 text-primary">Locations</span>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                      <div class="row justify-end q-col-gutter-md">
                        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-6">
                          <q-input
                            v-model="tableSearchValue"
                            class="full-height"
                            dense
                            label="Search Locations"
                            outlined
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <q-card-section class="full-height">
                    <table-comp
                      class="ag-theme-alpine grid full-height"
                      :column-defs="columns"
                      :context="context"
                      :default-col-def="defaultColumnDef"
                      :framework-components="frameworkComponents"
                      :loading="loading"
                      :overlay-loading-template="overlayLoadingTemplate"
                      :overlay-no-rows-template="overlayNoRowsTemplate"
                      :row-data="locationsdata"
                      @grid-ready="loadData"
                      ref="designGrid"
                    />
                  </q-card-section>
                </div>
              </div>
            </template>
          </card-comp>
        </div>
      </div>
    </q-page>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import TableComp from "@/components/table/TableComp";
import { AdminTable } from "@/views/admin/AdminTable";
import router from "@/router";
import Locations from "@/services/LocationService";
import * as AccountService from "@/services/AccountService";
import CardComp from "@/components/common/CardComp";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import NotificationHelper from "@/utils/helpers/NotificationHelper.js";
import VendorDataExportHelper from "@/utils/helpers/VendorDataExportHelper";

export default defineComponent({
  name: "Location",
  components: { CardComp, TableComp },
  setup() {
    const frameworkComponents = AdminTable.frameworkComponents;
    const overlayLoadingTemplate = AdminTable.overlay;
    const overlayNoRowsTemplate = AdminTable.overlay;
    const defaultColumnDef = AdminTable.defaultColDef;
    const columns = ref(AdminTable.columns);

    const locationsdata = ref([]);
    const data = ref();
    const loading = ref(false);

    function loadData() {
      loading.value = true;
      Locations.getLocations().then((response) => {
        response.map((data) => {
          for (const key in data) {
            locationsdata.value.push({
              id: data[key].location_id,
              name: data[key].name,
              lat: data[key].lat,
              lng: data[key].lng,
              address: data[key].address,
              complex: data[key].complex,
              city: data[key].city,
              district: data[key].district,
              zipcode: data[key].zip,
              schoolPrincipal: data[key].principal,
              website: data[key].website,
              schoolCode: data[key].school_code,
              type: data[key].type,
              phone: data[key].phone,
              docid: data[key].docid,
            });
          }
        });
        loading.value = false;
      });
    }

    const openDrawer = ref(false);

    const tableSearchValue = ref();
    const designGrid = ref();
    watch(
      () => tableSearchValue.value,
      (newVal) => designGrid.value.setQuickFilter(newVal)
    );

    function exportData() {
      if (locationsdata.value && locationsdata.value.length > 0) {
        VendorDataExportHelper.exportVendorData(locationsdata.value);
      } else {
        NotificationHelper.createErrorNotification(
          "There is no data available to export!"
        );
      }
    }

    return {
      filter: ref(""),
      columns,
      data,
      defaultColumnDef,
      frameworkComponents,
      loading,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      openDrawer,
      loadData,
      tableSearchValue,
      designGrid,
      exportData,
      locationsdata,
    };
  },
  data() {
    return {
      context: {
        componentParent: this,
      },
    };
  },
  beforeCreate() {
    const { email } = useAuthenticationHelper();
    watch(
      () => email.value,
      (newValue) => {
        AccountService.getAccount(`${newValue}`).then((response) => {
          if (
            (response.type !== AccountService.ADMIN_TYPE &&
              response.type !== AccountService.VENDOR_TYPE) ||
            !response.type
          ) {
            router.push("/unauthorized");
          }
        });
      }
    );
  },
});
</script>

<style lang="scss">
#admin-page {
  #design-grid {
    flex-grow: 1;

    .grid {
      height: 100%;
    }
  }
}
</style>
