import "firebase/firestore";
import { db } from "@/main";

class Vendor {
  // TODO remove name field
  constructor(vendorObject) {
    this.contractors =
      vendorObject && vendorObject.contractors
        ? vendorObject.contractors
        : null;
    this.name = vendorObject && vendorObject.name ? vendorObject.name : null;
  }
  toString() {
    return this.contractors + ", " + this.name;
  }
}

const vendorConverter = {
  // TODO remove name field
  toFirestore: function (vendor) {
    return {
      contractors: vendor.contractors,
      name: vendor.name,
    };
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new Vendor(data.contractors, data.name);
  },
};

export default {
  getVendor(vendorId) {
    vendorId = vendorId.toString();
    return db
      .collection("Vendors")
      .doc(vendorId)
      .get()
      .then((response) => {
        return response;
      });
  },
  doesVendorExist(vendorId) {
    return this.getVendor(vendorId).then((response) => response.exists);
  },
  saveVendor(vendorId, vendorData) {
    vendorId = vendorId.toString();
    return db
      .collection("Vendors")
      .doc(vendorId)
      .withConverter(vendorConverter)
      .set(new Vendor(vendorData))
      .catch((error) => {
        console.error("Error ", error);
      });
  },
  removeEmailFromContractor(vendorId, contractorEmailToRemove) {
    return this.getVendor(vendorId).then((response) => {
      let contractorArray = response.data().contractors;
      contractorArray = contractorArray.filter(
        (entry) => entry !== contractorEmailToRemove
      );
      return this.updateVendorContractors(vendorId, contractorArray);
    });
  },
  updateVendorContractors(vendorId, newContractorsArray) {
    return db
      .collection("Vendors")
      .doc(vendorId)
      .update({
        contractors: newContractorsArray,
      })
      .catch((error) => {
        console.error("Error ", error);
      });
  },
};
