import { createRouter, createWebHashHistory } from "vue-router";
import AdminPage from "@/views/admin/AdminPage";
import Location from "@/views/admin/Location";
import LoginPage from "@/views/LoginPage";
import LandingPage from "@/views/LandingPage";
import NoAccountPage from "@/views/NoAccountPage";
import ContractorPage from "@/views/contractor/ContractorPage";
import VendorPage from "@/views/VendorPage";
import UnauthorizedPage from "@/views/UnauthorizedPage";
import NotFoundPage from "@/views/NotFoundPage";
import AdminAccounts from "@/views/admin/AdminAccounts";
import AdminNavigatorPage from "@/views/admin/AdminNavigatorPage";

const routes = [
  {
    path: "/",
    name: "Index",
    component: LandingPage,
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { title: "Login" },
  },
  {
    path: "/login/landing",
    name: "Landing",
    component: LandingPage,
    meta: { title: "Landing" },
  },
  {
    path: "/login/none",
    name: "NoAccount",
    component: NoAccountPage,
    meta: { title: "No Account Exists" },
  },
  {
    path: "/admin",
    name: "Admin Navigation",
    component: AdminNavigatorPage,
    meta: { title: "Admin Navigation" },
  },
  {
    path: "/admin/vendors",
    name: "Admin",
    component: AdminPage,
    meta: { title: "Vendor List" },
  },
  {
    path: "/admin/locations",
    name: "Location",
    component: Location,
    meta: { title: "Location List" },
  },
  {
    path: "/admin/admin-accounts",
    name: "Admin Account Creation",
    component: AdminAccounts,
    meta: { title: "Admin Account Creation" },
  },
  {
    path: "/contractor/:email",
    name: "ContractorPage",
    component: ContractorPage,
    meta: { title: "Contractor" },
  },
  {
    path: "/vendor/:vendorId",
    name: "VendorPage",
    component: VendorPage,
    meta: { title: "Contractor List" },
  },
  {
    path: "/unauthorized",
    name: "UnauthorizedPage",
    component: UnauthorizedPage,
    meta: { title: "Unauthorized" },
  },
  {
    path: "/:pathMatch(.*)*",
    component: NotFoundPage,
    meta: { title: "Not Found" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
