<template>
  <q-page class="row flex-center q-pa-md">
    <div
      class="justify-center text-center col-xs-12 col-sm-9 col-md-6 col-lg-4"
    >
      <div>
        <card-comp>
          <template #content>
            <q-card-section>
              <div class="col justify-center q-col-gutter-md">
                <div class="col-12 text-center">
                  <div class="text-h4 q-mb-sm">Admin Home</div>
                </div>
                <div class="column q-gutter-md">
                  <template v-for="(item, i) in menuList" :key="i">
                    <q-btn
                      color="primary"
                      :icon="item.icon"
                      :label="item.label"
                      outline
                      @click="redirect(item.path)"
                    />
                  </template>
                </div>
              </div>
            </q-card-section>
          </template>
        </card-comp>
      </div>
    </div>
  </q-page>
</template>

<script>
import * as AccountService from "@/services/AccountService";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import router from "@/router";
import { watch } from "vue";
import { menuList } from "@/utils/NavigationComponentHelper";
import CardComp from "../../components/common/CardComp.vue";

export default {
  components: { CardComp },
  setup() {
    function redirect(value) {
      router.push(value);
    }
    return { menuList, redirect };
  },
  beforeCreate() {
    const { email } = useAuthenticationHelper();
    watch(
      () => email.value,
      (newValue) => {
        AccountService.getAccount(`${newValue}`).then((response) => {
          if (response.type !== AccountService.ADMIN_TYPE || !response.type) {
            router.push("/unauthorized");
          }
        });
      }
    );
  },
};
</script>
<style lang="scss" scoped></style>
