import { render } from "./LoginComp.vue?vue&type=template&id=505f1b00"
import script from "./LoginComp.vue?vue&type=script&lang=js"
export * from "./LoginComp.vue?vue&type=script&lang=js"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCardSection,QImg,QBtn});
