<template>
  <div v-if="!thumbnail">
    <q-spinner color="primary" size="32px" />
    <span class="q-pl-md text-italic">
      {{ loadingText }}
    </span>
  </div>
  <q-item v-else>
    <q-item-section side>
      <q-avatar size="xl" square>
        <q-img :src="thumbnail" />
      </q-avatar>
    </q-item-section>
    <q-item-section>
      <q-item-label lines="1">
        {{ fileName }}
        <q-tooltip>
          {{ fileName }}
        </q-tooltip>
      </q-item-label>
    </q-item-section>
    <q-item-section class="text-black" side>
      <i
        aria-hidden="true"
        class="material-icons"
        role="img"
        style="font-size: 24px; cursor: pointer"
        title="Open"
        @click="$emit('open')"
        >download</i
      >
    </q-item-section>
  </q-item>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DocumentPreviewComponent",
  props: {
    fileName: {
      type: String,
      default: "",
    },
    thumbnail: {
      type: String,
      default: "",
    },
    loadingText: {
      type: String,
      default: "",
    },
  },
  emits: ["open"],
});
</script>

<style lang="scss"></style>
