<template>
  <q-card
    v-if="vaccinationToDisplay"
    class="justify-between summary-card"
    :class="{
      'no-wrap': true,
      column: true,
      'full-height': true,
      'good-result-card': isAdmittable,
      'bad-result-card': !isAdmittable,
    }"
    flat
  >
    <div>
      <q-card-section class="q-pb-none">
        <div class="text-h6">
          {{ vaccinationManufacturer }}
          Vaccine
        </div>
        <div>
          Administered on
          <span class="text-bold">
            {{ formattedVaccineAdministrationDate }}
          </span>
        </div>
      </q-card-section>
      <q-card-section class="q-pt-none">
        <q-list>
          <q-item class="q-py-none q-my-sm">
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(isNotInQuarantine)"
                :name="getCheckMarkOrCrossIcon(isNotInQuarantine)"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ isNotInQuarantine ? "Not in" : "In" }}
                quarantine
              </q-item-label>
              <q-item-label v-if="formattedQuarantineEndDate" caption>
                <span v-if="!isNotInQuarantine">Through</span>
                <span v-else>Last day was</span>
                {{ formattedQuarantineEndDate }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item class="q-py-none q-my-sm">
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(!displayedVaccinationIsPartial)"
                :name="getCheckMarkOrCrossIcon(!displayedVaccinationIsPartial)"
              />
            </q-item-section>
            <q-item-section>
              {{ displayedVaccinationIsPartial ? "Not all" : "All" }}
              doses administered
            </q-item-section>
          </q-item>
          <q-item
            v-if="!displayedVaccinationIsPartial"
            class="q-py-none q-my-sm"
          >
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(!vaccinationIsLessThan2WeeksOld)"
                :name="getCheckMarkOrCrossIcon(!vaccinationIsLessThan2WeeksOld)"
              />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{
                  !vaccinationIsLessThan2WeeksOld
                    ? "Considered"
                    : "Not considered"
                }}
                fully vaccinated
              </q-item-label>
              <q-item-label caption>
                {{ vaccinationIsLessThan2WeeksOld ? "Will be on" : "Since" }}
                {{ formattedFullyVaccinatedDate }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            v-if="!displayedVaccinationIsPartial"
            class="q-py-none q-my-sm"
          >
            <q-item-section avatar>
              <q-icon
                :color="getIconColor(displayedVaccinationIsVerified)"
                :name="getCheckMarkOrCrossIcon(displayedVaccinationIsVerified)"
              />
            </q-item-section>
            <q-item-section>
              Vaccination document
              {{ displayedVaccinationIsVerified ? "was" : "has not been" }}
              verified
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <q-card-section
        v-if="someVaccinationsRequireValidation"
        class="column justify-end q-pt-none"
      >
        <span class="q-pl-md text-italic">
          Note: Some uploaded documents still need verification
        </span>
      </q-card-section>
    </div>
    <q-card-section class="q-pt-none">
      <document-preview-component
        :file-name="vaccinationToDisplay.name"
        loading-text="Loading most recent vaccination document..."
        :thumbnail="thumbnail"
        @open="openAction"
      />
    </q-card-section>
  </q-card>
  <q-card
    v-else
    bordered
    class="row items-center full-height summary-card no-result-card"
    flat
  >
    <q-card-section>No uploaded vaccination history</q-card-section>
  </q-card>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import VaccinationSummaryService from "@/services/VaccinationSummaryService";
import * as PerformAction from "@/views/contractor/PerformAction";
import * as DocumentService from "@/services/DocumentService";
import DocumentPreviewComponent from "@/views/contractor/profileInformation/DocumentPreviewComponent";

export default defineComponent({
  name: "VaccinationSummaryCard",
  components: { DocumentPreviewComponent },
  props: {
    email: {
      type: String,
      default: "",
    },
    lastDocumentValidationDate: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const vaccinationStatus = ref();

    function getVaccinationStatus() {
      return VaccinationSummaryService.getVaccinationStatus(
        props.email,
        props.lastDocumentValidationDate
      ).then((response) => {
        vaccinationStatus.value = response;
        return response;
      });
    }

    onMounted(() => {
      getVaccinationStatus();
    });

    function getMoment(object) {
      if (object instanceof moment) {
        return object;
      } else if (typeof object === "number") {
        return moment(object);
      } else if (typeof object === "string") {
        return moment(object, "MMM D, YYYY");
      }

      throw new TypeError("getMoment: cannot parse moment date");
    }

    function formatDate(object, dayAdjustment = 0) {
      if (object) {
        return moment
          .utc(getMoment(object).valueOf())
          .add(dayAdjustment, "days")
          .format("MMM D, YYYY");
      }

      return "";
    }

    function getCheckMarkOrCrossIcon(boolean) {
      return boolean ? "done" : "close";
    }

    function getIconColor(boolean) {
      return boolean ? "green" : "red";
    }

    const isAdmittable = computed(() => {
      return (
        vaccinationStatus.value?.isAdmittableBasedOnVaccinationStatus ?? false
      );
    });

    const vaccinationToDisplay = computed(() => {
      return vaccinationStatus.value?.vaccinationToDisplay ?? null;
    });

    const vaccinationManufacturer = computed(() => {
      return vaccinationToDisplay.value?.manufacturer ?? "";
    });

    const formattedVaccineAdministrationDate = computed(() => {
      return formatDate(
        vaccinationToDisplay.value?.secondDoseDate ??
          vaccinationToDisplay.value?.firstDoseDate
      );
    });

    const formattedFullyVaccinatedDate = computed(() => {
      return formatDate(
        vaccinationStatus.value?.vaccinationToDisplayWaitingPeriodEndDate
      );
    });

    const vaccinationIsLessThan2WeeksOld = computed(() => {
      return !vaccinationStatus.value
        ?.vaccinationToDisplayHasPassedWaitingPeriod;
    });

    const displayedVaccinationIsPartial = computed(() => {
      return vaccinationStatus.value?.vaccinationToDisplayIsPartial ?? false;
    });

    const displayedVaccinationIsVerified = computed(() => {
      return vaccinationStatus.value?.vaccinationToDisplayIsVerified ?? false;
    });

    const someVaccinationsRequireValidation = computed(() => {
      return unverifiedVaccinations.value?.length > 0;
    });

    const unverifiedVaccinations = computed(() => {
      return vaccinationStatus.value?.unverifiedVaccinations;
    });

    const quarantineStatus = computed(() => {
      return vaccinationStatus.value?.quarantineStatus;
    });

    const isNotInQuarantine = computed(() => {
      return !quarantineStatus.value?.isInQuarantine;
    });

    const formattedQuarantineEndDate = computed(() => {
      // Subtract one from the end date
      // Allows us to display the final day in quarantine
      return formatDate(quarantineStatus.value?.endDate, -1);
    });

    function openAction() {
      PerformAction.openAction(
        DocumentService.DocumentType.VACCINATION,
        props.email,
        vaccinationToDisplay.value.id
      );
    }

    watch(
      () => vaccinationToDisplay.value,
      () => {
        getThumbnail();
      }
    );

    const thumbnail = ref();
    function getThumbnail() {
      if (vaccinationToDisplay.value) {
        DocumentService.getThumbnail(
          props.email,
          vaccinationToDisplay.value.id
        ).then((file) => {
          if (file) {
            thumbnail.value = URL.createObjectURL(file);
          }
        });
      } else {
        thumbnail.value = null;
      }
    }

    return {
      getVaccinationStatus,
      formatDate,
      getCheckMarkOrCrossIcon,
      getIconColor,
      isAdmittable,
      vaccinationToDisplay,
      vaccinationManufacturer,
      formattedVaccineAdministrationDate,
      formattedFullyVaccinatedDate,
      vaccinationIsLessThan2WeeksOld,
      displayedVaccinationIsPartial,
      displayedVaccinationIsVerified,
      someVaccinationsRequireValidation,
      isNotInQuarantine,
      formattedQuarantineEndDate,
      openAction,
      thumbnail,
    };
  },
});
</script>

<style scoped lang="scss">
.q-item {
  min-height: unset;
}

.q-item__section--avatar {
  min-width: 32px;
}
</style>
