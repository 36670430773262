import QRCode from "qrcode";

function getQRCodeForData(data) {
  return QRCode.toDataURL(
    [
      {
        data: data,
        mode: "byte",
      },
    ],
    {
      errorCorrectionLevel: "high",
      margin: 2,
      scale: 4,
      color: {
        light: "#FFFFFF",
      },
    }
  ).then((url) => {
    return url;
  });
}

export { getQRCodeForData };
