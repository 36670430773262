<template>
  <dialog-comp
    v-model="modalOpen"
    :action-button-label="actionButtonLabel"
    max-width="600px"
    :modal-title="modalTitle"
    suppress-close-on-action-button-click
    width="100%"
    @cancel-clicked="onReset"
    @confirm-clicked="onSubmit"
  >
    <template #content>
      <div class="row q-col-gutter-md q-px-md q-pt-md">
        <div class="col-12">
          <q-input
            v-model="editableEmail"
            dense
            label="Email *"
            lazy-rules
            :rules="[Rule.REQUIRED, Rule.UNUSED_EMAIL]"
            ref="emailInput"
          />
        </div>
        <div class="col-12">
          <q-input
            v-model="inputs.firstName"
            dense
            label="First Name *"
            :rules="[Rule.REQUIRED]"
            ref="firstNameInput"
          />
        </div>
        <div class="col-12">
          <q-input
            v-model="inputs.lastName"
            dense
            label="Last Name *"
            :rules="[Rule.REQUIRED]"
            ref="lastNameInput"
          />
        </div>
        <div class="col-12">
          <q-input
            v-model="inputs.phoneNumber"
            dense
            label="Phone Number"
            mask="(###) ### - ####"
            unmasked-value
            ref="phoneNumberInput"
          />
        </div>
        <div class="col-12">
          <q-option-group
            v-model="inputs.type"
            color="primary"
            inline
            :options="options"
            style="text-align: center"
          />
        </div>
      </div>
    </template>
  </dialog-comp>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import DialogComp from "../../components/common/DialogComp.vue";
import { useSyncModelValue } from "@/utils/helpers/useSyncModelValue";
import { useValidateInputs } from "@/utils/helpers/useValidateInputs";
import Rule from "@/utils/Rule";

export default defineComponent({
  name: "ContractorDialog",
  components: { DialogComp },
  props: {
    contractorAccount: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    vendorId: {
      type: String,
      default: null,
    },
    email: {
      type: String,
      default: null,
    },
  },
  emits: [
    "update:modelValue",
    "update:email",
    "cancelClicked",
    "updateClicked",
    "update-clicked",
  ],
  setup: function (props, { emit }) {
    const modalOpen = ref(props.modelValue);
    const commentTypeModel = ref();
    const commentTypes = ref([]);

    const options = [
      {
        label: "Contractor",
        value: "CONTRACTOR",
      },
      {
        label: "Vendor Representative",
        value: "VENDOR_REP",
      },
    ];

    const inputs = ref({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      type: "CONTRACTOR",
    });

    watch(
      () => [props.contractorAccount, props.vendorId],
      () => {
        inputs.value = props.contractorAccount;
        inputs.value.vendorId = props.vendorId;
      }
    );

    useSyncModelValue(
      props,
      "modelValue",
      emit,
      "update:modelValue",
      modalOpen
    );

    const editableEmail = ref();
    useSyncModelValue(props, "email", emit, "update:email", editableEmail);

    function cancelClicked() {
      inputs.value = props.comment;
      emit("cancelClicked");
    }

    function updateClicked() {
      emit("update-clicked", {
        email: editableEmail.value.trim(),
        account: inputs.value,
      });
    }

    const emailInput = ref();
    const firstNameInput = ref();
    const lastNameInput = ref();
    const phoneNumberInput = ref();
    const inputRefs = [
      emailInput,
      firstNameInput,
      lastNameInput,
      phoneNumberInput,
    ];
    const { validateAndRunFunctionIfNoError, resetValidation } =
      useValidateInputs(inputRefs);

    function onSubmit() {
      validateAndRunFunctionIfNoError(updateClicked);
    }

    function onReset() {
      resetValidation();
      cancelClicked();
    }

    function clearInputs() {
      inputs.value = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        type: "CONTRACTOR",
      };
      editableEmail.value = null;
    }

    const modalTitle = "Add Contractor or Representative";
    const actionButtonLabel = "Add";

    watch(
      () => props.modelValue,
      (newVal) => {
        if (!newVal) {
          clearInputs();
        }
      }
    );

    return {
      modalOpen,
      cancelClicked,
      updateClicked,
      commentTypes,
      commentTypeModel,
      options,
      inputs,
      editableEmail,
      onSubmit,
      onReset,
      modalTitle,
      actionButtonLabel,
      emailInput,
      firstNameInput,
      lastNameInput,
      phoneNumberInput,
      Rule,
    };
  },
});
</script>

<style lang="scss"></style>
