<template>
  <q-page class="q-pa-md column">
    <div class="row q-pb-md q-col-gutter-md">
      <card-comp title="Vendor Information">
        <template #content>
          <div class="row q-col-gutter-md q-pa-md">
            <div
              class="col-xs-12 col-md-6 col-lg-auto"
              style="min-width: 300px"
            >
              <field-comp
                class="full-width"
                label="Vendor Name"
                style="max-width: 350px"
                :value="vendorName"
              />
            </div>
            <div
              class="col-xs-12 col-md-6 col-lg-auto"
              style="min-width: 300px"
            >
              <field-comp
                label="Phone Number"
                style="max-width: 350px"
                :value="phoneNumber"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg">
              <field-comp label="Address Line 1" :value="addressLine1" />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg">
              <field-comp label="Address Line 2" :value="addressLine2" />
            </div>
            <div
              class="col-xs-12 col-sm-6 col-lg-auto"
              style="min-width: 200px"
            >
              <field-comp label="City" :value="city" />
            </div>
            <div class="col-auto" style="min-width: 85px">
              <field-comp label="State" :value="state" />
            </div>
            <div class="col-auto" style="min-width: 150px">
              <field-comp label="Zipcode" :value="zipcode" />
            </div>
          </div>
          <inner-loading-comp :loading="vendorInfoLoading" />
        </template>
      </card-comp>
    </div>
    <div class="row" style="flex-grow: 1; min-height: 650px">
      <div class="col-12">
        <card-comp>
          <template #content>
            <div class="full-height column">
              <div class="col-auto q-pl-md q-pr-md q-pt-md q-pb-none">
                <div class="row">
                  <div class="text-h6 text-primary">
                    Contractors and Authorized Representatives
                  </div>
                  <div
                    class="row q-col-gutter-md justify-end items-start"
                    style="flex: 1"
                  >
                    <div>
                      <q-btn
                        color="primary"
                        :disable="tableLoading"
                        icon="add"
                        label="Add Multiple Contractors"
                        outline
                        @click="addMultipleContractorsAction"
                      />
                    </div>
                    <div>
                      <q-btn
                        color="primary"
                        :disable="tableLoading"
                        icon="add"
                        label="Add Contractor or Representative"
                        outline
                        @click="addAction"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <q-card-section class="full-height">
                  <table-comp
                    v-if="vendorInfoLoading"
                    class="ag-theme-alpine grid full-height"
                    :column-defs="mapped"
                    :context="context"
                    :default-col-def="defaultColumnDef"
                    :framework-components="frameworkComponents"
                    :loading="tableLoading"
                    :overlay-loading-template="overlayLoadingTemplate"
                    :overlay-no-rows-template="overlayLoadingTemplate"
                    :row-data="tableData"
                    @grid-ready="loadData"
                    ref="contractorTable"
                  />
                  <table-comp
                    v-else
                    class="ag-theme-alpine grid full-height"
                    :column-defs="mapped"
                    :context="context"
                    :default-col-def="defaultColumnDef"
                    :framework-components="frameworkComponents"
                    :loading="tableLoading"
                    :overlay-loading-template="overlayLoadingTemplate"
                    :overlay-no-rows-template="overlayNoRowsTemplate"
                    :row-data="tableData"
                    @grid-ready="loadData"
                    ref="contractorTable"
                  />
                </q-card-section>
              </div>
            </div>
          </template>
        </card-comp>
      </div>
    </div>
  </q-page>
  <contractor-dialog
    v-model="openAddEditModal"
    :contractor-account="selectedEntry"
    :email="selectedEntry.email"
    :vendor-id="vendorId"
    @cancel-clicked="cancelClicked"
    @update-clicked="updateClicked"
  />
  <bulk-dialog-contractor
    v-model="openBulkContractorModal"
    :email="selectedEntry.email"
    :vendor-id="vendorId"
    @update-clicked="addMultipleEntries"
  />
  <dialog-comp
    v-model="openDeleteConfirm"
    action-button-label="Remove"
    max-width="500px"
    modal-text="Are you sure you want to remove this account from the vendor?"
    modal-title="Remove Account"
    @confirm-clicked="deleteEntry"
  />
</template>

<script>
import CardComp from "@/components/common/CardComp";
import { ContractorTableConfig } from "@/views/admin/ContractorTableConfig";
import { computed, ref, watch } from "vue";
import { useContractorTableAddEditFunctionality } from "@/utils/helpers/useContractorTableAddEditFunctionality";
import * as AccountService from "@/services/AccountService";
import AccountUtil from "@/utils/AccountUtil";
import VendorService from "@/services/VendorService";
import TableComp from "@/components/table/TableComp";
import ContractorDialog from "@/views/admin/ContractorDialog";
import BulkDialogContractor from "@/views/admin/BulkDialogContractor";
import DialogComp from "@/components/common/DialogComp";
import { useRoute } from "vue-router";
import CPTService from "@/services/CPTService";
import FieldComp from "@/components/common/FieldComp";
import InnerLoadingComp from "@/components/common/InnerLoadingComp";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import router from "@/router";

export default {
  name: "VendorPage",
  components: {
    InnerLoadingComp,
    FieldComp,
    DialogComp,
    ContractorDialog,
    TableComp,
    CardComp,
    BulkDialogContractor,
  },
  props: {
    userAccount: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const frameworkComponents = ContractorTableConfig.frameworkComponents;
    const overlayLoadingTemplate = ContractorTableConfig.overlay;
    const overlayNoRowsTemplate = ContractorTableConfig.noRowsOverlay;
    const defaultColumnDef = ContractorTableConfig.defaultColDef;

    const { email, user } = useAuthenticationHelper();

    watch(
      () => email.value,
      (newValue) => {
        AccountService.getAccount(`${newValue}`).then((response) => {
          if (!userCanViewPage(response)) {
            router.push("/unauth");
          }
        });
      }
    );

    function userCanViewPage(response) {
      return userIsAdmin(response) || userIsVendorRepWithVendorId(response);
    }

    function userIsAdmin(response) {
      return response.type === AccountService.ADMIN_TYPE;
    }

    function userIsVendorRepWithVendorId(response) {
      return (
        response.type === AccountService.VENDOR_TYPE &&
        response.vendorId === route.params.vendorId
      );
    }

    // Passed the logged in user email so the we can disable the delete button
    const mapped = computed(() => {
      return user.value && user.value.email
        ? ContractorTableConfig.columns.map((col) => {
            let newCol = col;
            if (col.cellRenderer === "nodeDeleteCellRenderer") {
              newCol.cellRendererParams = {
                email: user.value.email,
              };
            }
            return newCol;
          })
        : ContractorTableConfig.columns;
    });

    const route = useRoute();
    const vendorId = computed(() => route.params.vendorId);
    const vendor = ref();
    watch(
      () => vendorId.value,
      async () => {
        await loadPageInformation();
      },
      { immediate: true }
    );

    function loadPageInformation() {
      if (vendorId.value) {
        return loadContractorsForVendor(vendorId.value)
          .then(() => loadContractorInformation())
          .then(() => loadCPTVendorInformation());
      }
    }

    const tableLoading = ref(false);
    function loadPageContractorInformation() {
      tableLoading.value = true;
      if (vendorId.value) {
        return loadContractorsForVendor(vendorId.value)
          .then(() =>
            loadContractorInformation().then(() => (tableLoading.value = false))
          )
          .catch(() => (tableLoading.value = false));
      }
    }

    function loadContractorsForVendor(newVendorId) {
      if (newVendorId) {
        return VendorService.getVendor(newVendorId).then((response) => {
          vendor.value = response.data();
          return response.data();
        });
      }
    }

    function loadContractorInformation() {
      if (vendor.value && vendor.value.contractors) {
        return getAccountsForEmails(vendor.value.contractors);
      }
    }

    function getAccountsForEmails(emails) {
      if (emails && emails.length > 0) {
        return AccountService.getAccountsWithAdmittanceAndReviewDataWithEmails(
          emails
        ).then(async (response) => {
          tableData.value = response.map((account) =>
            AccountUtil.flattenAccount(account)
          );
          return tableData.value;
        });
      } else {
        tableData.value = [];
      }
    }

    const cptVendorInformation = ref({});
    const vendorInfoLoading = ref(true);
    function loadCPTVendorInformation() {
      vendorInfoLoading.value = true;
      CPTService.getVendorDataById(vendorId.value.toString())
        .then((response) => {
          if (response && response.data) {
            cptVendorInformation.value = response.data;
          }
        })
        .finally(() => {
          vendorInfoLoading.value = false;
        });
    }

    function cancelClicked() {
      openAddEditModal.value = false;
      selectedEntry = null;
    }

    const vendorName = computed(() =>
      cptVendorInformation.value ? cptVendorInformation.value.Name : ""
    );
    const phoneNumber = computed(() =>
      cptVendorInformation.value ? cptVendorInformation.value.Phone : ""
    );
    const addressLine1 = computed(() =>
      cptVendorInformation.value
        ? cptVendorInformation.value.Billing_Address_1
        : ""
    );
    const addressLine2 = computed(() =>
      cptVendorInformation.value
        ? cptVendorInformation.value.Billing_Address_2
        : ""
    );
    const city = computed(() =>
      cptVendorInformation.value ? cptVendorInformation.value.Billing_City : ""
    );
    const state = computed(() =>
      cptVendorInformation.value ? cptVendorInformation.value.Billing_State : ""
    );
    const zipcode = computed(() =>
      cptVendorInformation.value
        ? cptVendorInformation.value.Billing_ZipCode
        : ""
    );

    const contractorTable = ref();
    let {
      tableData,
      loadData,
      addAction,
      selectedEntry,
      openAddEditModal,
      updateClicked,
      editAction,
      editMode,
      openDeleteConfirm,
      deleteEntry,
      deleteAction,
    } = useContractorTableAddEditFunctionality(
      contractorTable,
      {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        type: "CONTRACTOR",
      },
      loadPageContractorInformation,
      tableLoading
    );
    let {
      addAction: addMultipleContractorsAction,
      openAddEditModal: openBulkContractorModal,
      addMultipleEntries: addMultipleEntries,
    } = useContractorTableAddEditFunctionality(
      contractorTable,
      {
        email: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        type: "CONTRACTOR",
      },
      loadPageContractorInformation,
      tableLoading
    );

    return {
      frameworkComponents,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      defaultColumnDef,
      tableData,
      loadData,
      addAction,
      selectedEntry,
      openAddEditModal,
      updateClicked,
      contractorTable,
      cancelClicked,
      editAction,
      vendorId,
      editMode,
      openDeleteConfirm,
      deleteEntry,
      deleteAction,
      vendor,
      vendorName,
      mapped,
      phoneNumber,
      addressLine1,
      addressLine2,
      city,
      state,
      zipcode,
      vendorInfoLoading,
      addMultipleContractorsAction,
      openBulkContractorModal,
      addMultipleEntries,
      tableLoading,
    };
  },
  data() {
    return {
      context: {
        componentParent: this,
      },
    };
  },
};
</script>

<style></style>
