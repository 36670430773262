import firebase from "firebase/app";

export default {
  getVendorDataById(id) {
    const getVendorDataById = firebase
      .functions()
      .httpsCallable("getVendorDataById");
    return getVendorDataById({ vendorId: id }).then((result) => {
      return result;
    });
  },
  getCPTVendorDataWithContractorData() {
    const getCPTVendorDataWithContractorData = firebase
      .functions()
      .httpsCallable("getCPTVendorDataWithContractorData");
    return getCPTVendorDataWithContractorData();
  },
};
