<template>
  <q-header class="bg-primary" elevated>
    <q-toolbar>
      <q-btn v-if="admin" flat icon="menu" round @click="drawer = !drawer" />
      <q-toolbar-title>
        <div class="row">
          <q-btn
            class="disable-hover-effect"
            flat
            no-caps
            padding="none"
            @click="goToUserHome"
          >
            <div class="col-auto">
              <q-icon
                class="q-pa-sm"
                name="img:./icons/hidoe-logo.png"
                :style="{ width: 'auto', height: '48px' }"
              />
            </div>
            <div class="col-auto self-center xs-hide text-subtitle2 text-left">
              Hawaii State <br />
              Department of Education
            </div>
          </q-btn>

          <div class="col-auto self-center absolute-center xs-hide">
            <q-btn
              class="disable-hover-effect"
              flat
              no-caps
              padding="none"
              @click="goToUserHome"
            >
              <div class="col-12 text-h5">Safe Workplace</div>
            </q-btn>
          </div>
        </div>
      </q-toolbar-title>
      <user-profile-navigation-dropdown
        :display-name="displayName"
        :email="email"
        :google-profile-image="googleProfileImage"
        :user-signed-in="userSignedIn"
        @signOutClicked="signOutClicked"
      />
    </q-toolbar>
  </q-header>
  <q-drawer
    v-model="drawer"
    bordered
    :breakpoint="500"
    elevated
    overlay
    :width="250"
  >
    <q-scroll-area class="fit">
      <q-list>
        <template v-for="(menuItem, index) in menuList" :key="index">
          <q-item v-ripple class="text-grey-10" clickable :to="menuItem.path">
            <q-item-section avatar>
              <q-icon :name="menuItem.icon" />
            </q-item-section>
            <q-item-section>
              {{ menuItem.label }}
            </q-item-section>
          </q-item>
          <q-separator />
        </template>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
import { defineComponent, ref, watch } from "vue";
import UserProfileNavigationDropdown from "@/components/UserProfileNavigationDropdown";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import * as AccountService from "@/services/AccountService";
import { menuList } from "@/utils/NavigationComponentHelper";

const admin = ref(false);

export default defineComponent({
  name: "AppHeader",
  components: { UserProfileNavigationDropdown },
  props: {
    displayName: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    googleProfileImage: {
      type: String,
      default: "",
    },
    userSignedIn: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["signOutClicked"],
  setup(props, { emit }) {
    function signOutClicked() {
      emit("signOutClicked");
    }

    const { email, handleRouting } = useAuthenticationHelper();
    function goToUserHome() {
      AccountService.getAccount(email.value).then((response) => {
        return handleRouting(response, email.value);
      });
    }

    return {
      menuList,
      admin,
      drawer: ref(false),
      signOutClicked,
      goToUserHome,
    };
  },
  beforeCreate() {
    const { email } = useAuthenticationHelper();
    watch(
      () => email.value,
      (newValue) => {
        AccountService.getAccount(`${newValue}`).then((response) => {
          if (response.type === AccountService.ADMIN_TYPE) {
            admin.value = true;
          }
        });
      }
    );
  },
});
</script>

<style lang="scss"></style>
