import { db } from "@/main";
import StorageService from "@/services/MayanStorageService";
import firebase from "firebase/app";

export const DocumentType = {
  VACCINATION: "COVID-19 Vaccination Record",
  TEST_RESULT: "COVID-19 Test Result",
  DOCTOR_CLEARANCE: "COVID-19 Doctor's Clearance",
};

export async function uploadDocument(email, filename, metadata) {
  await StorageService.uploadDocument(email, filename, metadata);
}

export async function getDocumentDetails(email, documentId) {
  if (!email) return [];
  const snapshot = await db
    .collection("Accounts")
    .doc(email)
    .collection("Documents")
    .doc(documentId.toString())
    .get();

  return snapshot.data();
}

export async function openDocument(email, documentId) {
  await StorageService.openDocument(email, documentId);
}

export async function deleteDocument(email, documentId) {
  await StorageService.deleteDocument(email, documentId);
}

export async function deleteAllDocuments(email) {
  const documents = await getDocuments(email);
  const promises = [];

  for (const document of documents) {
    promises.push(this.deleteDocument(email, document.id));
  }

  await Promise.all(promises);
}

export function getDocuments(email, metadata = {}, fromUploadDate) {
  const getDocuments = firebase.functions().httpsCallable("getDocuments");
  return getDocuments({
    email: email,
    metadata: metadata,
    fromUploadDate: fromUploadDate,
  }).then((result) => {
    return result.data;
  });
}

export async function getThumbnail(email, documentId) {
  return await StorageService.getThumbnail(email, documentId);
}
