import { render } from "./AdminAccounts.vue?vue&type=template&id=4f6eda08"
import script from "./AdminAccounts.vue?vue&type=script&lang=js"
export * from "./AdminAccounts.vue?vue&type=script&lang=js"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QInput,QBtn,QCardSection});
