import { onMounted, onUnmounted } from "vue";
import Timer from "@/utils/Timer";

const ACTIVITY_EVENTS = ["mousedown", "mouseup", "keydown", "keyup", "scroll"];

export default class IdleTimer extends Timer {
  constructor(...args) {
    super(...args);

    this.resetBinding = this.reset.bind(this);
    this.channel = null;

    onMounted(() => {
      this.enable();

      // Start the timer
      this.reset();
    });

    onUnmounted(() => {
      this.stop();
      this.disable();
    });
  }

  reset(event) {
    this.stop();
    this.start();

    // Notify other tabs of activity
    if (this.channel && event?.type !== "message") {
      this.channel.postMessage("activity");
    }
  }

  enable() {
    // Add activity event handlers
    for (const event of ACTIVITY_EVENTS) {
      window.addEventListener(event, this.resetBinding);
    }

    try {
      // Listen to activity on other tabs
      if (window.BroadcastChannel) {
        this.channel = new BroadcastChannel("activity_channel");
        this.channel.addEventListener("message", this.resetBinding);
      }
    } catch (err) {
      console.log(err);
    }
  }

  disable() {
    // Remove activity event handlers
    for (const event of ACTIVITY_EVENTS) {
      window.removeEventListener(event, this.resetBinding);
    }

    // Remove tab activity listener
    if (this.channel) {
      this.channel.removeEventListener("message", this.resetBinding);
      this.channel.close();
      this.channel = null;
    }
  }
}
