import { render } from "./BulkDialogContractor.vue?vue&type=template&id=0c4456c9"
import script from "./BulkDialogContractor.vue?vue&type=script&lang=js"
export * from "./BulkDialogContractor.vue?vue&type=script&lang=js"
script.render = render

export default script
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QFile,QIcon,QBtn});
