<template>
  <q-page class="flex flex-center">
    <div class="row justify-center text-center">
      <div class="col-xs-11 col-sm-6 col-md-5">
        <card-comp>
          <template #content>
            <q-card-section>
              <div class="row justify-center q-col-gutter-md">
                <div class="col-xs-3 col-sm-4 col-md-5 col-lg-6">
                  <q-img src="../../public/icons/doe-logo-bw-lg.png" />
                </div>
                <div class="col-12 text-center">
                  <div class="text-h4 q-mb-sm">Sign In</div>
                  <div class="text-subtitle2">
                    to Continue to Safe Workplace
                  </div>
                </div>
                <div class="col-12">
                  <div id="firebaseui-auth-container" />
                  <div id="loader">Loading...</div>
                </div>
                <div class="col-12 q-py-none">
                  <span class="text-subtitle2">Or</span>
                </div>
                <div class="col-12">
                  <q-btn
                    color="secondary"
                    label="Create new Google Account"
                    outline
                    @click="createNewGoogleAccountClicked"
                  />
                </div>
              </div>
            </q-card-section>
          </template>
        </card-comp>
      </div>
    </div>
  </q-page>
</template>

<script>
import URLHelper from "@/utils/helpers/URLHelper";
import { onMounted } from "vue";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import CardComp from "@/components/common/CardComp";

export default {
  name: "LoginComp",
  components: { CardComp },
  setup() {
    const { loadFirebaseLoginUI, userSignedIn } = useAuthenticationHelper();

    onMounted(() => loadFirebaseLoginUI("#firebaseui-auth-container"));

    function createNewGoogleAccountClicked() {
      URLHelper.goToURLInNewTab("https://accounts.google.com/SignUp");
    }

    return {
      userSignedIn,
      createNewGoogleAccountClicked,
    };
  },
};
</script>

<style></style>
