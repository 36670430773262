import { render } from "./SlideTransitionSectionWithToggleButton.vue?vue&type=template&id=c19f2c68"
import script from "./SlideTransitionSectionWithToggleButton.vue?vue&type=script&lang=js"
export * from "./SlideTransitionSectionWithToggleButton.vue?vue&type=script&lang=js"

import "./SlideTransitionSectionWithToggleButton.vue?vue&type=style&index=0&id=c19f2c68&lang=scss"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon,QExpansionItem,QSlideTransition});
