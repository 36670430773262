<template>
  <div id="contractor-page">
    <contractor-page-account-d-n-e v-if="!userAccount" />
    <q-page v-if="userAccount" class="flex row justify-center q-pa-md">
      <div class="col-md-12 col-lg-11">
        <div
          class="row justify-center q-col-gutter-md"
          style="display: flex; align-items: flex-start; overflow: visible"
        >
          <div id="profile-card" class="col-sm-12 col-md-11 col-lg-6 q-pb-md">
            <profile-information-card
              :email="contractorEmail"
              :is-admin-or-rep-type-account="isAdminOrRepTypeAccount"
              :is-admittable="isAdmittable"
              :last-document-validation-date="
                userAccount.lastDocumentValidationDate
              "
              :loading="loadingProfileInfo"
              :qr-code="qrcode"
              @accountUpdated="getAccount"
              @checkinsChanged="loadCheckins"
              ref="profileInformationCard"
            />
          </div>
          <div class="col-sm-12 col-md-11 col-lg-6">
            <div
              v-show="isAdminOrRepTypeAccount"
              :class="dataExists ? 'q-pb-md' : ''"
            >
              <document-validation-comp
                :account-obj="userAccount"
                :email="contractorEmail"
                :last-document-validation-date="
                  userAccount.lastDocumentValidationDate
                "
                @documents-validation-change="recalculateAdmittanceInformation"
                @update-last-validation-timestamp="getAccount"
                ref="documentValidationComp"
              />
            </div>
            <div class="q-pb-md">
              <check-in-record-comp
                :email="contractorEmail"
                ref="checkInRecordComp"
              />
            </div>
            <div class="q-pb-md">
              <vaccine-record-comp
                :email="contractorEmail"
                @latest-vaccine-change="recalculateAdmittanceInformation"
              />
            </div>
            <div class="q-pb-md">
              <covid-test-comp
                :email="contractorEmail"
                @latest-test-change="recalculateAdmittanceInformation"
              />
            </div>
          </div>
        </div>
      </div>
    </q-page>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from "vue";
import * as AccountService from "@/services/AccountService";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import CovidTestComp from "@/views/contractor/CovidTestComp";
import VaccineRecordComp from "@/views/contractor/VaccineRecordComp";
import CheckInRecordComp from "@/views/contractor/CheckInRecordComp";
import router from "@/router";
import DocumentValidationComp from "@/views/contractor/DocumentValidationComp";
import ContractorPageAccountDNE from "@/views/contractor/ContractorPageAccountDNE";
import ProfileInformationCard from "@/views/contractor/ProfileInformationCard";
import { getQRCodeForData } from "@/utils/helpers/QRCodeHelper";
import AdmittanceService from "@/services/AdmittanceService";
import { useRoute } from "vue-router";
import LocationService from "@/services/LocationService";

export default {
  name: "ContractorPage",
  components: {
    ProfileInformationCard,
    ContractorPageAccountDNE,
    CovidTestComp,
    DocumentValidationComp,
    VaccineRecordComp,
    CheckInRecordComp,
  },
  setup() {
    let locations = ref(null);
    LocationService.getLocations().then((response) => {
      locations = response;
    });

    const schoolOptions = Object.keys(locations);

    const checkInLocationOptions = ref(schoolOptions);
    const qrcode = ref(null);

    const options = [
      {
        label: "Contractor",
        value: "CONTRACTOR",
      },
      {
        label: "Vendor Representative",
        value: "VENDOR_REP",
      },
    ];

    watch(
      () => window.location.href,
      (newVal) =>
        getQRCodeForData(newVal).then((response) => (qrcode.value = response)),
      { immediate: true }
    );

    const { currentUserAccount, email } = useAuthenticationHelper();
    const route = useRoute();

    watch(
      () => email.value,
      (newValue) => {
        AccountService.getAccount(`${newValue}`).then((response) => {
          if (
            response &&
            response.type !== AccountService.ADMIN_TYPE &&
            response.type !== AccountService.VENDOR_TYPE &&
            newValue !== route.params.email
          ) {
            router.push("/unauthorized");
          } else if (
            response.type === AccountService.VENDOR_TYPE &&
            response.vendorId !== route.params.vendorId
          ) {
            AccountService.getAccount(`${route.params.email}`).then((res) => {
              if (res?.vendorId !== response?.vendorId) {
                router.push("/unauthorized");
              }
            });
          }
        });
      }
    );

    const isAdminOrRepTypeAccount = computed(
      () =>
        currentUserAccount.value &&
        (currentUserAccount.value.type === AccountService.ADMIN_TYPE ||
          currentUserAccount.value.type === AccountService.VENDOR_TYPE)
    );

    const userAccount = ref({});

    const contractorEmail = ref(null);

    onMounted(() => getAccountAndAdmissionStatus());

    function getAccount() {
      return AccountService.getAccount(route.params.email).then((response) => {
        userAccount.value = response;
        contractorEmail.value = route.params.email;
        return response;
      });
    }

    const isAdmittable = ref(false);

    const checkInRecordComp = ref(null);
    function loadCheckins() {
      checkInRecordComp.value.loadCheckIns();
    }

    function recalculateAdmittanceInformation() {
      loadingProfileInfo.value = true;
      getAccountAndAdmissionStatus();
      reloadTestAndVaccinationSummary();
      loadUnverifiedDocuments();
    }

    function getAccountAndAdmissionStatus() {
      getAccount().then(() => setIsAdmittable());
    }

    const profileInformationCard = ref();
    function reloadTestAndVaccinationSummary() {
      if (profileInformationCard.value) {
        profileInformationCard.value.reloadTestAndVaccinationSummary();
      }
    }

    const loadingProfileInfo = ref(true);
    function setIsAdmittable() {
      return AdmittanceService.isAdmittable(
        contractorEmail.value,
        userAccount.value?.lastDocumentValidationDate
      ).then((response) => {
        isAdmittable.value = response;
        loadingProfileInfo.value = false;
        return response;
      });
    }

    const documentValidationComp = ref(null);
    const dataExists = ref(false);

    function loadUnverifiedDocuments() {
      if (documentValidationComp.value) {
        dataExists.value = documentValidationComp.value.dataExists;

        documentValidationComp.value.loadUnverifiedDocuments();
      }
    }

    // TODO: Remove after looking at the latestDocument code and removing all notions of it
    // const isTestValid = ref(false);
    // const isTestDocumentsVerified = ref(true);
    // const isVaccinationValid = ref(false);
    // const isVaccineDocumentsVerified = ref(true);
    // const isDoctorsClearanceValid = ref(false);
    // const momentVaccinationDate = ref(null);
    // const momentTestDate = ref(null);
    // const showCheckInHistory = ref(false);

    // const doctorsClearance = ref(null);

    // watch(
    //     doctorsClearance,
    //     async (newVal) => {
    //       isDoctorsClearanceValid.value = await DocumentService.isDocumentValid(
    //           newVal
    //       );
    //     },
    //     { deep: true }
    // );
    //
    // const vaccination = ref(null);
    //
    // watch(
    //     vaccination,
    //     async (newVal) => {
    //       isVaccinationValid.value = await DocumentService.isDocumentValid(
    //           newVal
    //       );
    //     },
    //     { deep: true }
    // );

    // function checkForValidatedDocuments(documentValidation) {
    //   isVaccineDocumentsVerified.value =
    //     !documentValidation.unvalidatedVaccineDocumentsExist;
    //   isTestDocumentsVerified.value =
    //     !documentValidation.unvalidatedTestingDocumentsExist;
    // }

    // const isVaccineValidAndVerified = computed(
    //     () => isVaccinationValid.value && isVaccineDocumentsVerified.value
    // );
    //
    // const isTestValidAndVerified = computed(
    //     () => isTestValid.value && isTestDocumentsVerified.value
    // );
    //
    // const isDoctorsClearanceValidAndVerified = computed(
    //     () => isDoctorsClearanceValid.value && isTestDocumentsVerified.value
    // );

    // const testResult = ref(null);
    //
    // watch(
    //     testResult,
    //     async (newVal) => {
    //       isTestValid.value = await DocumentService.isDocumentValid(newVal);
    //     },
    //     { deep: true }
    // );

    // const isTestPositive = computed(
    //     () => testResult.value && testResult.value.status === "positive"
    // );

    // function updateDocuments() {
    //   AccountService.getAccount(route.params.email).then((response) => {
    //     documentValidationComp.value.loadUnverifiedDocuments();
    //     if (
    //       response.latestDocument?.[DocumentService.DocumentType.TEST_RESULT]
    //     ) {
    //       DocumentService.getDocumentDetails(
    //         route.params.email,
    //         response.latestDocument[DocumentService.DocumentType.TEST_RESULT]
    //       );
    //     }
    //
    //     if (
    //       response.latestDocument?.[
    //         DocumentService.DocumentType.DOCTOR_CLEARANCE
    //       ]
    //     ) {
    //       DocumentService.getDocumentDetails(
    //         route.params.email,
    //         response.latestDocument[
    //           DocumentService.DocumentType.DOCTOR_CLEARANCE
    //         ]
    //       ).then((document) => {
    //         doctorsClearance.value = document;
    //         // Use local time
    //         momentTestDate.value = moment(
    //           moment.utc(document.date).format("YYYY-MM-DD"),
    //           "YYYY-MM-DD"
    //         ).startOf("day");
    //       });
    //     } else {
    //       doctorsClearance.value = null;
    //     }
    //
    //     if (
    //       response.latestDocument?.[DocumentService.DocumentType.VACCINATION]
    //     ) {
    //       DocumentService.getDocumentDetails(
    //         route.params.email,
    //         response.latestDocument[DocumentService.DocumentType.VACCINATION]
    //       ).then((document) => {
    //         if (document) {
    //           vaccination.value = document;
    //           momentVaccinationDate.value = moment(
    //             moment.utc(document.date).format("YYYY-MM-DD"),
    //             "YYYY-MM-DD"
    //           ).startOf("day");
    //         }
    //       });
    //     } else {
    //       vaccination.value = null;
    //     }
    //   });
    // }

    return {
      qrcode,
      currentUserAccount,
      checkInLocation: ref(null),
      checkInLocationOptions,
      filterFn(val, update) {
        update(() => {
          const needle = val.toLowerCase();
          checkInLocationOptions.value = schoolOptions.filter(
            (v) => v.toLowerCase().indexOf(needle) > -1
          );
        });
      },
      options,
      getAccount,
      isAdminOrRepTypeAccount,
      isAdmittable,
      loadCheckins,
      checkInRecordComp,
      documentValidationComp,
      contractorEmail,
      userAccount,
      profileInformationCard,
      recalculateAdmittanceInformation,
      email,
      dataExists,
      loadingProfileInfo,

      // TODO: Remove after looking at the latestDocument code and removing all notions of it
      // testResult,
      // vaccination,
      // isTestValid,
      // isTestDocumentsVerified,
      // isVaccinationValid,
      // isVaccineDocumentsVerified: isVaccineDocumentsVerified,
      // isDoctorsClearanceValid,
      // momentVaccinationDate,
      // momentTestDate,
      // doctorsClearance,
      // isTestPositive,
      // isVaccineValidAndVerified,
      // isTestValidAndVerified,
      // isDoctorsClearanceValidAndVerified,
      // checkForValidatedDocuments,
      // updateDocuments,
      // showCheckInHistory,
    };
  },
};
</script>

<style scoped lang="scss">
#contractor-page {
  h3 {
    line-height: 1rem;
  }

  .good-result-card {
    border: solid #4caf50;
  }

  .bad-result-card {
    border: solid #f44336;
  }

  .check-in-btn {
    height: 3rem !important;
    width: 10rem !important;
  }

  .q-menu {
    max-height: 300px !important;
  }

  @media screen and (min-width: 1440px) {
    #profile-card {
      position: sticky;
      top: 64px;
    }
  }
}

#contractor-page {
  :deep(.q-stepper) {
    background: none;
  }

  :deep(.q-stepper .q-stepper__step-inner) {
    padding-bottom: 16px;
  }

  :deep(.q-stepper__nav) {
    padding-top: 8px;
  }
}
</style>
