import { render } from "./Location.vue?vue&type=template&id=afdbe53c"
import script from "./Location.vue?vue&type=script&lang=js"
export * from "./Location.vue?vue&type=script&lang=js"

import "./Location.vue?vue&type=style&index=0&id=afdbe53c&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QInput,QCardSection});
