import firebase from "firebase/app";

export default {
  async getTestSummary(email, lastDocumentValidationDate) {
    const getTestSummary = firebase.functions().httpsCallable("getTestSummary");
    // If lastDocumentValidationDate == null, the account's documents were never validated,
    // so set it to a date older than all documents.
    if (lastDocumentValidationDate == null) {
      lastDocumentValidationDate = 1;
    }
    return getTestSummary({
      email,
      lastDocumentValidationDate,
    }).then((result) => {
      return result.data;
    });
  },
};
