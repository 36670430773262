import { createApp } from "vue";
import Quasar from 'quasar/src/vue-plugin.js';;
import "firebase/app-check";
import "firebase/firestore";
import App from "./App.vue";
import router from "./router";
import quasarUserOptions from "./quasar-user-options";
import firebase from "firebase/app";
import "firebase/storage";
require("firebase/firestore");
require("firebase/functions");

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const appCheck = firebase.appCheck();
appCheck.activate(process.env.VUE_APP_RECAPTCHA_SITE_KEY, true);

if (
  process.env.VUE_APP_USE_EMULATED_FUNCTIONS?.trim()?.toLowerCase() === "true"
) {
  // This should only be set when running cloud functions locally
  firebase.functions().useEmulator("localhost", 5001);
}

export const db = firebase.firestore(firebaseApp);
export const storageRef = firebase.storage().ref();

createApp(App).use(Quasar, quasarUserOptions).use(router).mount("#app");
