<template>
  <ag-grid-vue
    class="ag-theme-alpine grid"
    :column-defs="columnDefs"
    :context="context"
    :framework-components="frameworkComponents"
    :grid-options="internalGridOptions"
    :overlay-loading-template="overlayLoadingTemplate"
    :overlay-no-rows-template="overlayNoRowsTemplate"
    :pagination="pagination"
    :pagination-page-size="paginationPageSize"
    :row-data="rowData"
    :row-selection="rowSelection"
    :tooltip-show-delay="0"
    @filter-changed="filterChanged"
    @grid-ready="$emit('grid-ready', $event)"
    @grid-size-changed="refreshTableSizes"
    @selection-changed="selectionChanged"
    @sort-changed="sortChanged"
    ref="agGrid"
  />
</template>

<script>
import _ from "lodash";
import { AgGridVue } from "ag-grid-vue3";
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "TableComp",
  components: { AgGridVue },
  props: {
    columnDefs: {
      type: Array,
      default: () => [],
    },
    context: {
      type: Object,
      default: () => this,
    },
    defaultColDef: {
      type: Object,
      default: () => ({}),
    },
    defaultFilterValues: {
      type: Object,
      default: null,
    },
    frameworkComponents: {
      type: Object,
      default: () => ({}),
    },
    gridOptions: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    overlayLoadingTemplate: {
      type: String,
      default:
        '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    },
    overlayNoRowsTemplate: {
      type: String,
      default:
        '<span class="ag-overlay-loading-center">There are no rows to show</span>',
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    paginationPageSize: {
      type: Number,
      default: 25,
    },
    rowData: {
      type: Array,
      default: () => [],
    },
    rowSelection: {
      type: String,
      default: "none",
    },
  },
  emits: ["grid-ready", "filterChanged", "selectionChanged", "sortChanged"],
  setup(props, { emit }) {
    const internalGridOptions = ref({});

    let filterModel = null;
    let sortState = null;

    watch(
      () => [props.gridOptions, props.defaultColDef],
      () => {
        internalGridOptions.value = props.gridOptions;
        internalGridOptions.value.defaultColDef = props.defaultColDef;
      },
      { immediate: true }
    );

    watch(
      () => props.loading,
      (newVal) => {
        if (internalGridOptions.value.api) {
          if (newVal) {
            showLoadingOverlay();
          } else {
            hideOverlay();
            sizeColumnsToFit();
          }
        }
      },
      { immediate: true }
    );

    function showLoadingOverlay() {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.showLoadingOverlay();
      }
    }

    function hideOverlay() {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.hideOverlay();
      }
    }

    function sizeColumnsToFit() {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.sizeColumnsToFit();
      }
    }

    function getColumnDefs() {
      if (internalGridOptions.value.api) {
        return internalGridOptions.value.api.getColumnDefs();
      }
      return null;
    }

    function setColumnDefs(colDefs) {
      if (internalGridOptions.value.api) {
        return internalGridOptions.value.api.setColumnDefs(colDefs);
      }
    }

    function getColumnHeaderName(colId) {
      const colDefs = getColumnDefs();
      const col = colDefs ? colDefs.find((c) => c.colId === colId) : null;
      return col ? col.headerName : null;
    }

    function refreshCells() {
      if (internalGridOptions.value.api) {
        return internalGridOptions.value.api.refreshCells({ force: true });
      }
    }

    function redrawRows() {
      if (internalGridOptions.value.api) {
        return internalGridOptions.value.api.redrawRows();
      }
    }

    function getFilterInstance(name) {
      if (internalGridOptions.value.api) {
        return internalGridOptions.value.api.getFilterInstance(name);
      }
    }

    function setFloatingFiltersHeight(height) {
      if (internalGridOptions.value.api && height) {
        return internalGridOptions.value.api.setFloatingFiltersHeight(height);
      }
    }

    function getDisplayedRowAtIndex(index) {
      if (internalGridOptions.value.api) {
        return internalGridOptions.value.api.getDisplayedRowAtIndex(index);
      }
    }

    function getRowNodeByNodeId(nodeId) {
      if (internalGridOptions.value.api) {
        return internalGridOptions.value.api.getRowNode(nodeId);
      }
    }

    function getRowNodeByUUID(uuid) {
      let matching = null;
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.forEachNode((rowNode) => {
          if (rowNode.data.uuid === uuid) {
            matching = rowNode;
          }
        });
      }
      return matching;
    }

    function getSelectedNodes() {
      if (internalGridOptions.value.api) {
        return internalGridOptions.value.api.getSelectedNodes();
      }
    }

    function getSelectedNode() {
      const nodes = getSelectedNodes();
      return nodes && nodes.length > 0 ? nodes[0] : null;
    }

    function filterChanged() {
      emit("filterChanged");
      saveFilterModel();
      resizeHeader();
    }

    function saveFilterModel() {
      if (internalGridOptions.value.api) {
        filterModel = internalGridOptions.value.api.getFilterModel();
      }
    }

    function loadFilterModel() {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.setFilterModel(filterModel);
      }
    }

    function getFilterModel() {
      return filterModel;
    }

    function resizeHeader() {
      if (internalGridOptions.value && internalGridOptions.value.columnDefs) {
        // Check for multiSelectFilter within this tableComp
        // If ...document.getElementsByClassName("multiselect-input") is used for this check the height can be shared between tables
        const hasMultiselectFilter = internalGridOptions.value.columnDefs.some(
          (x) => x.filter === "multiSelectFilter"
        );
        if (hasMultiselectFilter) {
          const multiselectInputs = [
            ...document.getElementsByClassName("multiselect-input"),
          ];
          const maxValue = _.max(
            multiselectInputs.map((element) => element.clientHeight)
          );
          setFloatingFiltersHeight(
            maxValue && maxValue > 48 ? maxValue + 20 : 47
          );
        }
      }
    }

    function refreshTableSizes() {
      resetRowHeights();
      sizeColumnsToFit();
      resizeHeader();
    }

    function showNoRowsOverlay() {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.hideOverlay();
        return internalGridOptions.value.api.showNoRowsOverlay();
      }
    }

    function onFilterChanged() {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.onFilterChanged();
      }
    }

    function selectionChanged() {
      emit("selectionChanged");
    }

    function sortChanged() {
      emit("sortChanged");
      saveSortState();
    }

    function saveSortState() {
      if (internalGridOptions.value.columnApi) {
        const colState = internalGridOptions.value.columnApi.getColumnState();

        sortState = colState
          .filter(function (s) {
            return s.sort != null;
          })
          .map(function (s) {
            return {
              colId: s.colId,
              sort: s.sort,
              sortIndex: s.sortIndex,
            };
          });
      }
    }

    function loadSortState() {
      if (internalGridOptions.value.columnApi) {
        if (!sortState) {
          saveSortState();
        }
        internalGridOptions.value.columnApi.applyColumnState({
          state: sortState,
        });
      }
    }

    function getFilteredRows() {
      const filteredData = ref([]);
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.forEachNodeAfterFilterAndSort((r) => {
          filteredData.value.push(r.data);
        });
      }
      return filteredData.value;
    }

    function ensureIndexVisible(index) {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.ensureIndexVisible(index, "middle");
      }
    }

    function paginationGetPageSize() {
      let page = 0;
      if (internalGridOptions.value.api) {
        page = internalGridOptions.value.api.paginationGetPageSize();
      }
      return page;
    }

    function paginationGoToPage(page) {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.paginationGoToPage(page);
      }
    }

    function ensureIndexVisibleAcrossPages(index) {
      const pageNumber = Math.floor(index / paginationGetPageSize());
      paginationGoToPage(pageNumber);
      ensureIndexVisible(index);
    }

    function ensureSelectedRowVisible() {
      if (internalGridOptions.value.api) {
        const selectedNodes = internalGridOptions.value.api.getSelectedNodes();
        const selectedNode =
          selectedNodes && selectedNodes.length > 0 ? selectedNodes[0] : null;
        if (selectedNode && selectedNode.rowIndex != null) {
          ensureIndexVisibleAcrossPages(selectedNode.rowIndex);
        }
      }
    }

    function resetColumnState() {
      if (internalGridOptions.value.columnApi) {
        internalGridOptions.value.columnApi.resetColumnState();
      }
    }

    function resetFilters() {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.setFilterModel(props.defaultFilterValues);
      }
    }

    function resetTableSortAndFilters() {
      resetColumnState();
      resetFilters();
    }

    function resetRowHeights() {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.resetRowHeights();
      }
    }

    function setQuickFilter(filter) {
      if (internalGridOptions.value.api) {
        internalGridOptions.value.api.setQuickFilter(filter);
      }
    }

    return {
      internalGridOptions,
      filterChanged,
      loadFilterModel,
      getFilterModel,
      getFilterInstance,
      setFloatingFiltersHeight,
      getDisplayedRowAtIndex,
      getSelectedNodes,
      getSelectedNode,
      setColumnDefs,
      showLoadingOverlay,
      hideOverlay,
      sizeColumnsToFit,
      getColumnDefs,
      refreshCells,
      redrawRows,
      refreshTableSizes,
      showNoRowsOverlay,
      onFilterChanged,
      selectionChanged,
      sortChanged,
      loadSortState,
      getFilteredRows,
      ensureIndexVisible,
      paginationGetPageSize,
      paginationGoToPage,
      ensureIndexVisibleAcrossPages,
      ensureSelectedRowVisible,
      resetColumnState,
      resetFilters,
      resetTableSortAndFilters,
      resetRowHeights,
      getRowNodeByNodeId,
      getColumnHeaderName,
      getRowNodeByUUID,
      setQuickFilter,
    };
  },
});
</script>

<style lang="scss">
.ag-root-wrapper {
  @extend .rounded-borders;
  border: 2px solid grey !important;
}
</style>
