import _ from "lodash";

export default {
  flattenAccount(accountObject) {
    let newObject = _.cloneDeep(accountObject);

    function getAccountEmailFromAccountObject(accountObject) {
      let accountEmail = null;
      const accountObjectKeys = Object.keys(accountObject);

      if (accountObjectKeys && accountObjectKeys[0]) {
        accountEmail = accountObjectKeys[0];
      }

      return accountEmail;
    }

    const accountEmail = getAccountEmailFromAccountObject(accountObject);
    if (accountEmail) {
      newObject = { email: accountEmail };
      _.assign(newObject, accountObject[accountEmail]);
    }

    return newObject;
  },
};
