<template>
  <card-comp :fit="false" title="Vaccination Records">
    <template #content>
      <q-card-section>
        <div class="row grow-1">
          <div class="col-12">
            <table-comp
              class="ag-theme-alpine grid"
              :column-defs="columns"
              :context="context"
              :default-col-def="defaultColumnDef"
              :framework-components="frameworkComponents"
              :loading="loading"
              :overlay-loading-template="overlayLoadingTemplate"
              :overlay-no-rows-template="overlayNoRowsTemplate"
              :row-data="data"
              style="height: 300px"
              ref="designGrid"
            />
          </div>
        </div>
        <slide-transition-section-with-toggle-button
          v-model:visible="visible"
          label="Add Vaccination"
        >
          <template #transition-content>
            <vaccine-record-comp-inputs
              :email="email"
              :is-submitting="vaccineIsUploading"
              @submit="submit"
              ref="vaccineRecordCompInputs"
            />
          </template>
        </slide-transition-section-with-toggle-button>
      </q-card-section>
    </template>
  </card-comp>
  <dialog-comp
    v-model="openModal"
    action-button-label="Remove"
    max-width="500px"
    :modal-text="deleteConfirmationMessage"
    modal-title="Remove Document"
    @cancel-clicked="openModal = false"
    @confirm-clicked="confirmedDeleteAction"
  />
</template>

<script>
import * as DocumentService from "@/services/DocumentService";
import TableComp from "@/components/table/TableComp";
import { VaccineTableConfig } from "@/views/contractor/VaccineTableConfig";
import NotificationHelper from "@/utils/helpers/NotificationHelper.js";
import { ref, watch } from "vue";
import moment from "moment";
import CardComp from "@/components/common/CardComp";
import SlideTransitionSectionWithToggleButton from "@/components/common/SlideTransitionSectionWithToggleButton";
import { useValidateInputs } from "@/utils/helpers/useValidateInputs";
import * as PerformAction from "@/views/contractor/PerformAction";
import DialogComp from "../../components/common/DialogComp.vue";
import VaccineRecordCompInputs from "@/views/contractor/VaccineRecordCompInputs";

export default {
  name: "VaccineRecordComp",
  components: {
    VaccineRecordCompInputs,
    SlideTransitionSectionWithToggleButton,
    CardComp,
    DialogComp,
    TableComp,
  },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  emits: ["latestVaccineChange"],
  setup(props, { emit }) {
    const frameworkComponents = VaccineTableConfig.frameworkComponents;
    const overlayLoadingTemplate = VaccineTableConfig.loadingOverlay;
    const overlayNoRowsTemplate = VaccineTableConfig.noRowsOverlay;
    const defaultColumnDef = VaccineTableConfig.defaultColDef;
    const columns = ref(VaccineTableConfig.columns);
    const designGrid = ref();
    const visible = ref(false);

    const requiredRules = [(val) => (val !== null && val !== "") || "Required"];

    function openAction(cellValue) {
      PerformAction.openAction(
        DocumentService.DocumentType.VACCINATION,
        props.email,
        cellValue
      );
    }

    const openModal = ref(false);
    const deleteCellValue = ref(null);
    const deleteConfirmationMessage = ref(null);

    function deleteAction(cellValue) {
      const filename = data.value.find((document) => {
        return document.id === cellValue;
      }).name;
      deleteConfirmationMessage.value = `Are you sure you want to remove this document, "${filename}"?`;
      openModal.value = true;
      deleteCellValue.value = cellValue;
    }

    function confirmedDeleteAction() {
      openModal.value = false;
      loading.value = true;
      deleteConfirmationMessage.value = null;
      PerformAction.deleteAction(
        DocumentService.DocumentType.VACCINATION,
        props.email,
        data,
        deleteCellValue.value
      ).then(() => (loading.value = false));
    }

    const vaccineDateInput = ref();
    const vaccineFileInput = ref();

    const { resetValidation } = useValidateInputs([
      vaccineDateInput,
      vaccineFileInput,
    ]);

    const data = ref([]);
    const loading = ref(false);

    function loadUploadedFiles() {
      loading.value = true;

      DocumentService.getDocuments(props.email, {
        type: DocumentService.DocumentType.VACCINATION,
      })
        .then((documents) => {
          const tableRows = [];

          for (const document of documents) {
            const received = moment.utc(
              document.secondDoseDate || document.firstDoseDate
            );
            const start = received.clone().add(15, "days");

            tableRows.push({
              id: document.id,
              name: document.name,
              manufacturer: document.manufacturer,
              status: document.status,
              date: received.valueOf(),
              firstDoseDate: document.firstDoseDate,
              secondDoseDate: document?.secondDoseDate ?? "N/A",
              validFrom: document.status === "Full" ? start.valueOf() : "N/A",
            });
          }

          data.value = tableRows;
        })
        .finally(() => {
          loading.value = false;
        });
    }

    watch(
      () => props.email,
      () => {
        loadUploadedFiles();
      }
    );

    watch(
      data,
      () => {
        emit("latestVaccineChange");
      },
      { deep: true, immediate: true }
    );

    const vaccineIsUploading = ref(false);
    function submit(vaccineInputData) {
      vaccineIsUploading.value = true;
      const dismiss = NotificationHelper.createNotification(
        "Processing vaccination record...",
        { timeout: 0, type: "ongoing" }
      );

      const metadata = {
        email: props.email,
        type: DocumentService.DocumentType.VACCINATION,
        manufacturer: vaccineInputData.vaccinationType,
        status: vaccineInputData.vaccinationAmount,
        firstDoseDate: moment(vaccineInputData.firstDoseDate, "YYYY-MM-DD")
          .startOf("day")
          .valueOf(),
        firstDoseLotNumber: vaccineInputData.firstDoseLotNumber,
        secondDoseDate: moment(vaccineInputData.secondDoseDate, "YYYY-MM-DD")
          .startOf("day")
          .valueOf(),
        secondDoseLotNumber: vaccineInputData.secondDoseLotNumber,
        dateOfBirth: moment(
          vaccineInputData.dateOfBirth,
          "YYYY-MM-DD"
        ).valueOf(),
      };

      DocumentService.uploadDocument(
        props.email,
        vaccineInputData.filename,
        metadata
      )
        .then(() => {
          dismiss();
          NotificationHelper.createSuccessNotification(
            "Vaccination record successfully added!"
          );
          loadUploadedFiles();
          clearFields();
        })
        .catch(() => {
          dismiss();
          NotificationHelper.createErrorNotification(
            "Vaccination record failed to process. Please try again in a few minutes."
          );
        })
        .finally(() => {
          vaccineIsUploading.value = false;
          resetValidation();
        });
    }

    const vaccineRecordCompInputs = ref();
    function clearFields() {
      vaccineRecordCompInputs.value.resetStepper();
    }

    return {
      filter: ref(""),
      columns,
      deleteAction,
      defaultColumnDef,
      frameworkComponents,
      loading,
      openAction,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      designGrid,
      requiredRules,
      vaccineDateInput,
      vaccineFileInput,
      resetValidation,
      data,
      visible,
      loadUploadedFiles,
      submit,
      vaccineIsUploading,
      openModal,
      confirmedDeleteAction,
      deleteConfirmationMessage,
      vaccineRecordCompInputs,
    };
  },
  data() {
    return {
      context: {
        componentParent: this,
      },
    };
  },
};
</script>

<style scoped></style>
