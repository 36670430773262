// axios with AppCheck verification

import axios from "axios";
import firebase from "firebase/app";
import "firebase/app-check";
import _ from "lodash";

async function appxios(...args) {
  let config;

  if (typeof args[0] === "string") {
    // Allow appxios(url, config)
    config = {
      ...args[1],
      url: args[0],
    };
  } else {
    // Allow appxios(config)
    config = args[0] ?? {};
  }

  const appCheckToken = (
    await firebase.appCheck().getToken(/* forceRefresh= */ false)
  ).token;
  const authToken = await firebase.auth().currentUser.getIdToken();

  return await axios(
    _.merge(
      {
        headers: {
          "X-Firebase-AppCheck": appCheckToken,
          Authorization: `Bearer ${authToken}`,
        },
      },
      config
    )
  );
}

const exports = {
  request: appxios,
};

["delete", "get", "head", "options"].forEach((method) => {
  exports[method] = (url, config) => {
    return appxios({
      method,
      url,
      ...config,
    });
  };
});

["post", "put", "patch"].forEach((method) => {
  exports[method] = (url, data, config) => {
    return appxios({
      method,
      url,
      data,
      ...config,
    });
  };
});

export default exports;
