import NodeDeleteEditCellRenderer from "@/components/table/helper-components/NodeDeleteEditCellRenderer";
import { getButtonColumnConfig } from "@/utils/TableConfig";

export const AdminAccountTableConfig = {
  defaultColDef: {
    minWidth: 150,
    sortable: true,
    suppressMenu: true,
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },
  overlay:
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  columns: [
    {
      headerName: "",
      field: "email",
      cellRenderer: "nodeDeleteEditCellRenderer",
      cellStyle: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      ...getButtonColumnConfig({ buttons: 2 }),
    },
    {
      headerName: "Email",
      field: "email",
      lockVisible: true,
      minWidth: 185,
    },
    {
      headerName: "First Name",
      field: "firstName",
      lockVisible: true,
      minWidth: 185,
    },
    {
      headerName: "Last Name",
      field: "lastName",
      minWidth: 150,
      tooltipComponent: "customTooltip",
      tooltipField: "projectTitle",
      tooltipComponentParams: { toolTipContentKey: "projectTitle" },
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      minWidth: 150,
      tooltipComponent: "customTooltip",
      tooltipField: "projectTitle",
      tooltipComponentParams: { toolTipContentKey: "projectTitle" },
    },
  ],
  frameworkComponents: {
    nodeDeleteEditCellRenderer: NodeDeleteEditCellRenderer,
  },
};
