import OpenButtonCellRenderer from "@/components/table/helper-components/OpenButtonCellRenderer.vue";
import NodeDeleteEditCellRenderer from "@/components/table/helper-components/NodeDeleteEditCellRenderer.vue";
import { getButtonColumnConfig } from "@/utils/TableConfig";

function typeValueFormatter(params) {
  if (params.value) {
    const words = params.value.split("_");

    return words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase();
      })
      .join(" ");
  }

  return params.value;
}

function admissionStatusFormatter(params) {
  return params.value ? "Admittable" : "Not Admittable";
}

function reviewStatusFormatter(params) {
  return params.value ? "Needs Review" : "";
}

export const ContractorTableConfig = {
  defaultColDef: {
    minWidth: 150,
    sortable: true,
    suppressMenu: true,
    resizable: true,
    floatingFilter: true,
    filter: "agTextColumnFilter",
    filterParams: {
      inRangeInclusive: true,
    },
  },

  overlay:
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',

  noRowsOverlay:
    '<span class="ag-overlay-loading-center">No contractors or representatives for vendor</span>',

  columns: [
    {
      headerName: "",
      field: "email",
      cellRenderer: "nodeDeleteEditCellRenderer",
      cellStyle: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
      ...getButtonColumnConfig({ buttons: 2 }),
    },
    {
      headerName: "Email",
      field: "email",
      lockVisible: true,
      minWidth: 185,
    },
    {
      headerName: "First Name",
      field: "firstName",
      lockVisible: true,
      minWidth: 185,
    },
    {
      headerName: "Last Name",
      field: "lastName",
      minWidth: 150,
      tooltipComponent: "customTooltip",
      tooltipField: "projectTitle",
      tooltipComponentParams: { toolTipContentKey: "projectTitle" },
    },
    {
      headerName: "Phone Number",
      field: "phoneNumber",
      minWidth: 150,
      tooltipComponent: "customTooltip",
      tooltipField: "projectTitle",
      tooltipComponentParams: { toolTipContentKey: "projectTitle" },
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 150,
      tooltipComponent: "customTooltip",
      tooltipField: "projectTitle",
      tooltipComponentParams: { toolTipContentKey: "projectTitle" },
      valueFormatter: typeValueFormatter,
    },
    {
      headerName: "Admission Status",
      field: "admittable",
      minWidth: 150,
      valueFormatter: admissionStatusFormatter,
    },
    {
      headerName: "Review Status",
      field: "needsReview",
      minWidth: 150,
      valueFormatter: reviewStatusFormatter,
    },
  ],
  frameworkComponents: {
    openButtonCellRenderer: OpenButtonCellRenderer,
    nodeDeleteEditCellRenderer: NodeDeleteEditCellRenderer,
  },
};
