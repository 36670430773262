import { computed, ref } from "vue";
import * as AccountService from "@/services/AccountService";
import NotificationHelper from "@/utils/helpers/NotificationHelper";
import VendorService from "@/services/VendorService";
import router from "@/router";

export function useContractorTableAddEditFunctionality(
  tableRef,
  initialEntryValues,
  loadPageContractorInformation,
  tableLoading
) {
  const tableData = ref([]);
  const selectedEntry = ref({});
  const selectedNodeId = ref(null);
  const openAddEditModal = ref(false);
  const openDeleteConfirm = ref(false);

  function loadData() {
    tableRef.value.resetTableSortAndFilters();
  }

  function addAction() {
    selectedEntry.value = initialEntryValues;
    selectedNodeId.value = null;
    openAddEditModal.value = true;
  }

  function editAction(node) {
    const editEmail = node.data.email;
    router.push(`/contractor/${editEmail}`);
  }

  function deleteAction(node) {
    selectedEntry.value = node.data;
    openDeleteConfirm.value = true;
  }

  function deleteEntry() {
    openDeleteConfirm.value = false;
    tableLoading.value = true;
    const dismiss = NotificationHelper.createNotification(
      `Deleting Account for ${selectedEntry.value.email}...`,
      { timeout: 0, type: "ongoing" }
    );
    AccountService.updateIsDeletedField(selectedEntry.value.email)
      .then(() => {
        return VendorService.removeEmailFromContractor(
          selectedEntry.value.vendorId,
          selectedEntry.value.email
        ).then(() => {
          loadPageContractorInformation();
        });
      })
      .finally(() => {
        dismiss();
        tableLoading.value = false;
        NotificationHelper.createSuccessNotification(
          "Account successfully deleted"
        );
      });
  }

  const editMode = computed(() => selectedNodeId.value !== null);

  function updateClicked(updatedEntry) {
    if (selectedNodeId.value === null) {
      const dismiss = NotificationHelper.createNotification(
        "Adding Account...",
        { timeout: 0, type: "ongoing" }
      );
      addEntry(updatedEntry).then(() => {
        dismiss();
      });
    } else {
      updateEntry(updatedEntry);
    }
    openAddEditModal.value = false;
    selectedNodeId.value = null;
  }

  function addEntry(updatedEntry) {
    return ifAccountDoesNotExistAddToVendor(updatedEntry).then(() => {
      return loadPageContractorInformation();
    });
  }

  function ifAccountDoesNotExistAddToVendor(updatedEntry) {
    updatedEntry.email = updatedEntry.email.toLowerCase();
    tableLoading.value = true;
    return AccountService.getAccount(updatedEntry.email)
      .then((response) => {
        if (!response || response.isDeleted === true || !response.type) {
          return saveNewAccountAndAddToVendor(updatedEntry);
        } else {
          NotificationHelper.createErrorNotification(
            "Account with email already exists"
          );
        }
      })
      .finally(() => {
        tableLoading.value = false;
        return loadPageContractorInformation();
      });
  }

  function saveNewAccountAndAddToVendor(updatedEntry) {
    return AccountService.saveAccount(
      updatedEntry.email,
      updatedEntry.account
    ).then(() => {
      NotificationHelper.createSuccessNotification(
        "Account successfully added"
      );
      return addEmailToVendor(
        updatedEntry.account.vendorId,
        updatedEntry.email
      );
    });
  }

  function addEmailToVendor(vendorId, email) {
    let vendor = null;
    return VendorService.getVendor(vendorId).then((response) => {
      vendor = response.data();
      if (
        vendor.contractors &&
        !vendor.contractors.find((entry) => entry === email)
      ) {
        vendor.contractors.push(email);
      }
      return VendorService.saveVendor(vendorId, vendor);
    });
  }

  function addMultipleEntries(updatedEntries) {
    const executePromiseReturningFunctionsSequentially = (
      functionsThatReturnPromises
    ) =>
      functionsThatReturnPromises.reduce(
        (promise, func) =>
          promise.then((result) =>
            func().then(Array.prototype.concat.bind(result))
          ),
        Promise.resolve([])
      );
    openAddEditModal.value = false;
    const dismiss = NotificationHelper.createNotification(
      "Adding Accounts...",
      {
        timeout: 0,
        type: "ongoing",
      }
    );
    return executePromiseReturningFunctionsSequentially(
      updatedEntries.map((entry) => () => addEntry(entry))
    ).then(() => dismiss());
  }

  function updateEntry(updatedEntry) {
    AccountService.updateAccount(updatedEntry.email, updatedEntry.account).then(
      () => {
        loadPageContractorInformation();
      }
    );
  }

  return {
    tableData,
    loadData,
    addAction,
    selectedEntry,
    selectedNodeId,
    openAddEditModal,
    editAction,
    deleteAction,
    openDeleteConfirm,
    updateClicked,
    deleteEntry,
    editMode,
    addMultipleEntries,
  };
}
