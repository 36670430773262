<template>
  <div>
    <div class="text-h6 q-mb-md" style="font-size: 1rem">
      {{ headerText }}
    </div>
    <table-comp
      class="ag-theme-alpine grid"
      :column-defs="columns"
      :context="context"
      :default-col-def="defaultColumnDef"
      :framework-components="frameworkComponents"
      :loading="loading"
      :overlay-loading-template="overlayLoadingTemplate"
      :overlay-no-rows-template="overlayNoRowsTemplate"
      :row-data="rowData"
      style="height: 300px"
      :ref="refName"
    />
  </div>
</template>

<script>
import TableComp from "@/components/table/TableComp";
import { ref } from "vue";

export default {
  components: {
    TableComp,
  },
  props: {
    context: {
      type: Object,
      default: () => {},
    },
    headerText: {
      type: String,
      default: "",
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    refName: {
      type: String,
      default: "",
    },
    rowData: {
      type: Array,
      default: () => [],
    },
    tableConfig: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const frameworkComponents = ref(props.tableConfig.frameworkComponents);
    const overlayLoadingTemplate = ref(props.tableConfig.loadingOverlay);
    const overlayNoRowsTemplate = ref(props.tableConfig.noRowsOverlay);
    const defaultColumnDef = ref(props.tableConfig.defaultColDef);
    const columns = ref(props.tableConfig.verificationColumns);

    return {
      frameworkComponents,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      defaultColumnDef,
      columns,
    };
  },
};
</script>

<style scoped></style>
