import XLSX from "xlsx";

function exportVendorData(rawData) {
  const { vendorData, totalVendorRepresentatives, totalContractors } =
    parseAndTallyTableData(rawData);

  const workbook = getWorkBook(
    totalVendorRepresentatives,
    totalContractors,
    vendorData
  );

  XLSX.writeFile(workbook, "safe-workplace-vendors.xlsx");
}

function parseAndTallyTableData(data) {
  let vendorData = [];
  let totalVendorRepresentatives = 0;
  let totalContractors = 0;

  data.forEach((row) => {
    let contractors = "";
    if (row.contractors.length > 0) {
      contractors = JSON.stringify(row.contractors);
    }

    vendorData.push({
      name: row.Name,
      numberOfVendorRepresentatives: row.vendorCount,
      numberOfContractors: row.contractorCount,
      vendorId: row.Contractor_ID,
      contractors: contractors,
    });

    totalVendorRepresentatives += row.vendorCount;
    totalContractors += row.contractorCount;
  });

  return { vendorData, totalVendorRepresentatives, totalContractors };
}

function getWorkBook(totalVendorRepresentatives, totalContractors, vendorData) {
  const workbook = XLSX.utils.book_new();

  const tallySheet = getTallySheet(
    totalVendorRepresentatives,
    totalContractors
  );

  XLSX.utils.book_append_sheet(workbook, tallySheet, "Tally");

  const vendorSheet = getVendorSheet(vendorData);

  XLSX.utils.book_append_sheet(workbook, vendorSheet, "Vendors");

  return workbook;
}

function getTallySheet(totalVendorRepresentatives, totalContractors) {
  let sheetData = XLSX.utils.json_to_sheet([
    {
      totalVendorRepresentatives: totalVendorRepresentatives,
      totalContractors: totalContractors,
    },
  ]);
  sheetData.A1.v = "Total Vendor Representatives";
  sheetData.B1.v = "Total Contractors";

  return sheetData;
}

function getVendorSheet(vendorData) {
  let sheetData = XLSX.utils.json_to_sheet(vendorData);
  sheetData.A1.v = "Vendor Name";
  sheetData.B1.v = "No. of Vendor Representatives";
  sheetData.C1.v = "No. of Contractors";
  sheetData.D1.v = "Vendor ID";
  sheetData.E1.v = "Contractors";

  return sheetData;
}

export default {
  exportVendorData,
};
