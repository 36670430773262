<template>
  <div class="row full-width justify-center">
    <div class="col-auto">
      <q-btn
        class="check-in-btn"
        color="primary"
        icon="place"
        label="Check-in"
        rounded
        @click="checkInModalToggle = true"
      />
    </div>
  </div>
  <check-in-modal v-model="checkInModalToggle" @checkInClicked="checkIn" />
</template>

<script>
import { defineComponent, ref } from "vue";
import CheckInModal from "../CheckInModal.vue";
import CheckinService from "@/services/CheckInService";
import NotificationHelper from "@/utils/helpers/NotificationHelper";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";

export default defineComponent({
  name: "CheckinButtonAndModalComponent",
  components: { CheckInModal },
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  emits: ["checkinsChanged"],
  setup(props, { emit }) {
    const checkInModalToggle = ref(false);
    const { email, displayName } = useAuthenticationHelper();
    function checkIn(checkInLocationObject) {
      checkInModalToggle.value = false;
      const dismiss = NotificationHelper.createNotification(
        "Saving Check-In Location...",
        { timeout: 0, type: "ongoing" }
      );
      CheckinService.saveCheckInInfo(props.email, {
        checkInLocation: checkInLocationObject.checkInLocation,
        timeStamp: Date.now(),
        checkedInBy: email.value,
        checkedInByFullName: displayName.value,
        locationId: checkInLocationObject.locationId,
      })
        .then(() => {
          dismiss();
          NotificationHelper.createSuccessNotification(
            "Successfully Checked In"
          );
          emit("checkinsChanged");
        })
        .catch((error) => {
          console.error(error);
          NotificationHelper.createErrorNotification(
            "Failed to check in. Please try again later."
          );
        });
    }

    return {
      checkInModalToggle,
      checkIn,
    };
  },
});
</script>

<style lang="scss"></style>
