class FunctionsURLFactory {
  constructor(options) {
    this.options = {
      useEmulator: options?.useEmulator ?? false,
      region: options?.region ?? "us-west2",
    };
  }

  getURL(path) {
    if (this.options.useEmulator) {
      return `http://localhost:5001/${process.env.VUE_APP_PROJECT_ID}/${this.options.region}/${path}`;
    }

    return `https://${this.options.region}-${process.env.VUE_APP_PROJECT_ID}.cloudfunctions.net/${path}`;
  }

  region(region) {
    return new FunctionsURLFactory({
      ...this.options,
      region,
    });
  }

  useEmulator(useEmulator) {
    return new FunctionsURLFactory({
      ...this.options,
      useEmulator,
    });
  }
}

export default new FunctionsURLFactory({
  useEmulator:
    process.env.VUE_APP_USE_EMULATED_FUNCTIONS?.trim()?.toLowerCase() ===
    "true",
});
