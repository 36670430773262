<template>
  <div class="q-pt-md">
    <q-stepper v-model="step" animated color="primary" flat vertical>
      <q-step
        :done="step > 0"
        icon="file_upload"
        :name="0"
        title="Upload Vaccination Document"
      >
        <q-form class="col-xs-12 col-sm-6" greedy :ref="form[0]">
          <q-file
            v-model="file"
            label="Proof of Vaccination *"
            outlined
            :rules="[Rule.REQUIRED]"
            stack-label
          >
            <template #prepend>
              <q-icon name="attach_file" />
            </template>
          </q-file>
        </q-form>
        <q-stepper-navigation>
          <div class="row justify-center">
            <div class="col-auto">
              <q-btn
                color="primary"
                icon="upload"
                label="Upload"
                :loading="isUploading"
                rounded
                @click="uploadForExtraction"
              />
            </div>
          </div>
        </q-stepper-navigation>
      </q-step>
      <q-step
        :done="step > 1"
        icon="edit_note"
        :name="1"
        title="Vaccine Information"
      >
        <q-form class="row q-col-gutter-y-md" greedy :ref="form[1]">
          <message-box>
            Some information has been filled in for you. Please check the
            following for accuracy and make corrections as necessary. Fields
            marked with an asterisk (*) are required.
          </message-box>
          <div class="col-xs-12 col-sm-7 col-md-6">
            <date-picker
              v-model="dateOfBirth"
              close-btn
              label="Date of Birth *"
              minimal
              :navigation-max-year-month="maxYearMonth"
              :options="isDateAllowed"
              outlined
              :rules="[Rule.REQUIRED, Rule.NO_FUTURE_DATE]"
            />
          </div>
          <div class="col-12">
            <q-field label="Vaccine Manufacturer *" outlined stack-label>
              <template class="q-my-auto" #prepend>
                <q-icon name="vaccines" />
              </template>
              <template #control>
                <q-radio
                  v-model="manufacturer"
                  label="Pfizer"
                  :val="'Pfizer'"
                />
                <q-radio
                  v-model="manufacturer"
                  label="Moderna"
                  :val="'Moderna'"
                />
                <q-radio
                  v-model="manufacturer"
                  label="Johnson &amp; Johnson"
                  :val="'Johnson & Johnson'"
                />
              </template>
            </q-field>
          </div>
          <div v-if="!isJohnsonAndJohnson" class="col-12">
            <q-field label="Dose Count *" outlined stack-label>
              <template class="q-my-auto" #prepend>
                <q-icon name="vaccines" />
              </template>
              <template #control>
                <q-radio
                  v-model="vaccinationAmount"
                  label="One Dose"
                  :val="'Partial'"
                />
                <q-radio
                  v-model="vaccinationAmount"
                  label="Two Doses"
                  :val="'Full'"
                />
              </template>
            </q-field>
          </div>
        </q-form>
        <q-stepper-navigation>
          <q-btn
            color="primary"
            label="Continue"
            rounded
            @click="goToStepIfAllowed(2)"
          />
          <q-btn
            class="q-ml-sm"
            color="primary"
            flat
            label="Back"
            rounded
            @click="goToStepIfAllowed(0)"
          />
        </q-stepper-navigation>
      </q-step>
      <q-step
        :done="step > 2"
        icon="edit_note"
        :name="2"
        title="Dose Information"
      >
        <q-form greedy :ref="form[2]">
          <div class="row q-col-gutter-md">
            <message-box>
              Some information has been filled in for you. Please check the
              following for accuracy and make corrections as necessary. Fields
              marked with an asterisk (*) are required.
            </message-box>
            <div class="col-xs-12 col-sm-7 col-md-6">
              <date-picker
                v-model="firstDoseDate"
                close-btn
                label="First Dose Date *"
                minimal
                :navigation-max-year-month="maxYearMonth"
                :options="isDateAllowed"
                outlined
                :rules="[Rule.REQUIRED, Rule.NO_FUTURE_DATE]"
              />
            </div>
            <div class="col-xs-12 col-sm-5 col-md-auto">
              <q-input
                v-model="firstDoseLotNumber"
                label="First Dose Lot Number"
                maxlength="15"
                outlined
                stack-label
              />
            </div>
          </div>
          <div v-if="hasSecondDose" class="row q-col-gutter-md q-pt-sm">
            <div class="col-xs-12 col-sm-7 col-md-6">
              <date-picker
                v-model="secondDoseDate"
                close-btn
                label="Second Dose Date *"
                minimal
                :navigation-max-year-month="maxYearMonth"
                :options="isDateAllowed"
                outlined
                :rules="[Rule.REQUIRED, Rule.NO_FUTURE_DATE]"
              />
            </div>
            <div class="col-xs-12 col-sm-5 col-md-auto">
              <q-input
                v-model="secondDoseLotNumber"
                label="Second Dose Lot Number"
                maxlength="15"
                outlined
                stack-label
              />
            </div>
          </div>
        </q-form>
        <q-stepper-navigation>
          <q-btn
            color="primary"
            label="Continue"
            rounded
            @click="goToStepIfAllowed(3)"
          />
          <q-btn
            class="q-ml-sm"
            color="primary"
            flat
            label="Back"
            rounded
            @click="goToStepIfAllowed(1)"
          />
        </q-stepper-navigation>
      </q-step>
      <q-step :done="step > 3" icon="send" :name="3" title="Final Submission">
        By clicking <span class="text-bold">SUBMIT</span>, you attest to the
        following: <br /><br />
        I attest that the information provided in this form is accurate and true
        to the best of my knowledge. I understand that knowingly making a false
        statement on this form is a crime and can be punished by fine or
        imprisonment or both (HRS § 710-1063). I understand that making a false
        statement on this form may also result in disciplinary action, up to and
        including termination.
        <q-stepper-navigation>
          <q-btn
            color="primary"
            label="Submit"
            :loading="isSubmitting"
            rounded
            @click="submit"
          />
          <q-btn
            class="q-ml-sm"
            color="primary"
            :disable="isSubmitting"
            flat
            label="Back"
            rounded
            @click="goToStepIfAllowed(2)"
          />
        </q-stepper-navigation>
      </q-step>
    </q-stepper>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watch } from "vue";
import moment from "moment";
import MessageBox from "@/components/common/MessageBox";
import DatePicker from "@/components/common/DatePicker";
import NotificationHelper from "@/utils/helpers/NotificationHelper.js";
import * as ExtractionPipelineService from "@/services/ExtractionPipelineService";
import * as AccountService from "@/services/AccountService";
import Rule from "@/utils/Rule";

export default defineComponent({
  name: "VaccineRecordCompInputs",
  components: { MessageBox, DatePicker },
  props: {
    email: {
      type: String,
      default: "",
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const step = ref(0);
    const isUploading = ref(false);
    const form = [ref(null), ref(null), ref(null)];
    const file = ref();
    const dateOfBirth = ref();
    const manufacturer = ref();
    const vaccinationAmount = ref();
    const firstDoseDate = ref();
    const firstDoseLotNumber = ref();
    const secondDoseDate = ref();
    const secondDoseLotNumber = ref();
    const filename = ref(null);

    function resetFields() {
      file.value = null;
      dateOfBirth.value = null;
      manufacturer.value = "Pfizer";
      vaccinationAmount.value = "Full";
      firstDoseDate.value = null;
      firstDoseLotNumber.value = null;
      secondDoseDate.value = null;
      secondDoseLotNumber.value = null;
    }
    resetFields();

    const submissionData = computed(() => {
      return {
        filename: filename.value,
        dateOfBirth: dateOfBirth.value,
        vaccinationType: manufacturer.value,
        vaccinationAmount: vaccinationAmount.value,
        firstDoseDate: firstDoseDate.value,
        firstDoseLotNumber: firstDoseLotNumber.value,
        secondDoseDate: secondDoseDate.value,
        secondDoseLotNumber: secondDoseLotNumber.value,
      };
    });

    const isJohnsonAndJohnson = computed(function () {
      return manufacturer.value?.toLowerCase() === "johnson & johnson";
    });

    watch(isJohnsonAndJohnson, (newVal) => {
      if (newVal) {
        vaccinationAmount.value = "Full";
      }
    });

    const hasSecondDose = computed(
      () => vaccinationAmount.value === "Full" && !isJohnsonAndJohnson.value
    );

    watch(hasSecondDose, (newVal) => {
      if (!newVal) {
        secondDoseDate.value = null;
        secondDoseLotNumber.value = null;
      }
    });

    function resetStepper() {
      resetFields();
      step.value = 0;
    }

    async function goToStepIfAllowed(nextStep) {
      if (await inputsOfPreviousStepAreValid(nextStep)) {
        step.value = nextStep;
      }
    }

    async function inputsOfPreviousStepAreValid(currentStep) {
      const previousStep = currentStep - 1;
      const previousFormValidates = await form[previousStep]?.value?.validate();

      return previousFormValidates ?? true;
    }

    function submit() {
      emit("submit", submissionData.value);
    }

    async function uploadForExtraction() {
      const validates = await form[0].value.validate();
      if (!validates) {
        return;
      }

      isUploading.value = true;
      const dismiss = NotificationHelper.createNotification(
        "Uploading vaccination record...",
        { timeout: 0, type: "ongoing" }
      );

      try {
        const response = await ExtractionPipelineService.docAIExtractionUpload(
          file.value,
          {
            type: "VACCINE",
            firstName: userAccount.value?.firstName ?? null,
            lastName: userAccount.value?.lastName ?? null,
          }
        );
        filename.value = response.filename;

        prepopulateInputsFromResponse(response.extraction);
        step.value = 1;
      } catch (error) {
        NotificationHelper.createErrorNotification(
          "Vaccination record failed to upload. Please try again in a few minutes."
        );
      } finally {
        isUploading.value = false;
        dismiss();
      }
    }

    const maxYearMonth = computed(() => {
      return moment().format("YYYY/MM");
    });

    function isDateAllowed(value) {
      const today = moment().startOf("day");
      const date = moment(value, "YYYY/MM/DD").startOf("day");

      return date.isSameOrBefore(today);
    }

    const userAccount = ref();
    function getAccount() {
      if (props.email) {
        AccountService.getAccount(props.email).then((response) => {
          userAccount.value = response;
        });
      }
    }

    watch(
      () => props.email,
      () => getAccount(),
      { immediate: true }
    );

    function prepopulateInputsFromResponse(response) {
      firstDoseDate.value = grabValueAsFormattedDate(response, "1stDoseDate");
      firstDoseLotNumber.value = grabValueFromResponseIfExists(
        response,
        "1stDoseLOT#"
      );
      secondDoseDate.value = grabValueAsFormattedDate(response, "2ndDoseDate");
      secondDoseLotNumber.value = grabValueFromResponseIfExists(
        response,
        "2ndDoseLOT#"
      );
      dateOfBirth.value = grabValueAsFormattedDate(response, "dateOfBirth");
      vaccinationAmount.value = getVaccinationAmountFromResponse(response);
      manufacturer.value = getVaccinationTypeFromResponse(
        response,
        "manufacturer"
      );
    }

    function grabValueAsFormattedDate(response, key) {
      const dateAsUnixString = grabValueFromResponseIfExists(response, key);

      return dateAsUnixString
        ? moment.unix(dateAsUnixString).utc().format("YYYY-MM-DD")
        : null;
    }

    function grabValueFromResponseIfExists(response, key) {
      const results = response.results;
      return results[key]?.value ?? null;
    }

    function getVaccinationAmountFromResponse(response) {
      const manufacturer = grabValueFromResponseIfExists(
        response,
        "manufacturer"
      );
      if (manufacturer?.toLowerCase() === "johnson & johnson") {
        return "Full";
      } else {
        return responseContainsSecondDose(response) ? "Full" : "Partial";
      }
    }

    function getVaccinationTypeFromResponse(response) {
      const manufacturerMap = {
        pfizer: "Pfizer",
        moderna: "Moderna",
        "johnson & johnson": "Johnson & Johnson",
      };
      const manufacturer = grabValueFromResponseIfExists(
        response,
        "manufacturer"
      );
      return manufacturerMap[manufacturer] ?? "Pfizer";
    }

    function responseContainsSecondDose(response) {
      return Object.keys(response.results).find((entry) =>
        entry.includes("2ndDose")
      );
    }

    return {
      Rule,
      step,
      isUploading,
      form,
      file,
      dateOfBirth,
      manufacturer,
      vaccinationAmount,
      firstDoseDate,
      firstDoseLotNumber,
      secondDoseDate,
      secondDoseLotNumber,
      isJohnsonAndJohnson,
      hasSecondDose,
      resetStepper,
      goToStepIfAllowed,
      submit,
      uploadForExtraction,
      maxYearMonth,
      isDateAllowed,
    };
  },
});
</script>

<style lang="scss"></style>
