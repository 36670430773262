import OpenDeleteButtonCellRenderer from "@/components/table/helper-components/OpenDeleteButtonCellRenderer.vue";
import moment from "moment";

export const CheckInRecordTableConfig = {
  defaultColDef: {
    minWidth: 150,
    sortable: true,
    resizable: true,
  },
  overlay:
    '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  noRowsOverlay: "<span></span>",
  columns: [
    {
      headerName: "Date",
      field: "timeStamp",
      sort: "desc",
      comparator: function (valueA, valueB) {
        const valueADate = moment(valueA, "MM-DD-YYYY: h:mm:ss a").toDate();
        const valueBDate = moment(valueB, "MM-DD-YYYY: h:mm:ss a").toDate();
        return valueADate === valueBDate ? 0 : valueADate > valueBDate ? 1 : -1;
      },
    },
    {
      headerName: "Check-in Location",
      field: "checkInLocation",
      minWidth: 170,
    },
    {
      headerName: "Checked In By",
      field: "checkedInBy",
    },
  ],
  frameworkComponents: {
    openDeleteButtonCellRenderer: OpenDeleteButtonCellRenderer,
  },
};
