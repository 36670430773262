const PADDING = 8;
const BUTTON_WIDTH = 42;

export function getButtonColumnConfig({ buttons }) {
  return {
    cellClass: "no-border",
    cellStyle: {
      paddingLeft: `${PADDING}px`,
      paddingRight: `${PADDING}px`,
    },
    minWidth: PADDING * 2 + BUTTON_WIDTH * buttons,
    maxWidth: PADDING * 2 + BUTTON_WIDTH * buttons,
    lockPosition: true,
    lockVisible: true,
    pinned: "left",
    filter: false,
    sortable: false,
    suppressMovable: true,
    suppressNavigable: true,
  };
}
