import { render } from "./AdminPage.vue?vue&type=template&id=859e2bb8"
import script from "./AdminPage.vue?vue&type=script&lang=js"
export * from "./AdminPage.vue?vue&type=script&lang=js"

import "./AdminPage.vue?vue&type=style&index=0&id=859e2bb8&lang=scss"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QInput,QBtn,QCardSection});
