<template>
  <q-btn-dropdown v-if="userSignedIn" color="primary" no-caps unelevated>
    <template #label>
      <q-avatar size="30px">
        <img :src="googleProfileImage" />
      </q-avatar>
    </template>
    <div class="row justify-center no-wrap q-pt-md q-pb-md">
      <div class="column items-center full-width q-px-md q-pt-md">
        <q-avatar size="72px">
          <img :src="googleProfileImage" />
        </q-avatar>

        <div
          class="text-subtitle1 text-grey-10 q-mt-md"
          style="line-height: 1.25rem"
        >
          {{ displayName }}
        </div>
        <div class="text-grey-8 q-mx-lg q-mb-md" style="font-size: 0.875rem">
          {{ email }}
        </div>
        <div class="text-grey-8 q-mx-lg q-mb-xs" style="font-size: 0.875rem">
          <q-btn
            color="grey-4"
            no-caps
            outline
            rounded
            :to="{ name: 'ContractorPage', params: { email } }"
          >
            <span class="text-grey-9 text-weight-regular">
              Manage your account
            </span>
          </q-btn>
        </div>
        <q-separator class="full-width q-mt-sm q-mb-md" />
        <q-btn
          color="grey-4"
          no-caps
          outline
          padding="sm lg"
          @click="signOutClicked"
        >
          <span class="text-grey-9 text-weight-regular"> Sign out </span>
        </q-btn>
      </div>
    </div>
  </q-btn-dropdown>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserProfileNavigationDropdown",
  props: {
    userSignedIn: {
      type: Boolean,
      default: false,
    },
    displayName: {
      type: String,
      default: "",
    },
    googleProfileImage: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
  emits: ["signOutClicked"],
  setup(props, { emit }) {
    function signOutClicked() {
      emit("signOutClicked");
    }

    return {
      signOutClicked,
    };
  },
});
</script>

<style lang="scss"></style>
