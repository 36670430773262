import { render } from "./AdminNavigatorPage.vue?vue&type=template&id=3aa20299"
import script from "./AdminNavigatorPage.vue?vue&type=script&lang=js"
export * from "./AdminNavigatorPage.vue?vue&type=script&lang=js"
script.render = render

export default script
import QPage from 'quasar/src/components/page/QPage.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QCardSection,QBtn});
