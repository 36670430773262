<template>
  <div>
    <div class="message-box">
      <div v-if="title" class="title">
        {{ title }}
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessageBox",
  props: {
    title: {
      type: String,
      default: null,
    },
  },
});
</script>

<style lang="scss" scoped>
.message-box {
  background-color: #e5f0fa;
  border-color: #b1d1f1;
  border-radius: 4px;
  border-style: solid;
  border-width: 0 0 0 5px;
  letter-spacing: 0.1px;
  line-height: 1.35em;
  padding: 16px 24px;

  & > .title {
    padding-bottom: 8px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
</style>
