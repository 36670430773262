import appxios from "@/utils/Appxios";
import FunctionsHelper from "@/utils/helpers/FunctionsHelper";

export const DocumentType = {
  TEST: "TEST",
  VACCINE: "VACCINE",
};

export const Provider = {
  AZOVA: "Azova",
  CAPTURE_DIAGNOSTICS: "Capture Diagnostics",
  PUBLIC_HEALTH: "Public Health",
  COLOR: "Color",
  NOMI_HEALTH: "Nomi Health",
  TRUE_CARE: "True Care",
  NATIONAL_KIDNEY_FOUNDATION: "National Kidney Foundation",
  BASIS_DX: "BasisDx",
  KAUAI_TESTING_PROGRAM: "County of Kaua'i Voluntary Testing Program",
};

export async function docAIExtractionUpload(file, options) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", JSON.stringify(options.type));
  formData.append("firstName", JSON.stringify(options.firstName));
  formData.append("lastName", JSON.stringify(options.lastName));
  if (options.provider !== undefined && options.provider !== null) {
    formData.append("provider", JSON.stringify(options.provider));
  }

  const response = await appxios.post(
    FunctionsHelper.getURL(`docAIExtractionUpload`),
    formData,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  return response.data;
}
