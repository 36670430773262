<template>
  <div id="admin-page">
    <q-page class="q-pa-md column">
      <div class="row grow-1" style="flex-grow: 1">
        <div class="col-12">
          <card-comp>
            <template #content>
              <div class="full-height column">
                <div class="col-auto q-px-md q-pt-md">
                  <div class="row justify-between">
                    <div
                      class="col-auto"
                      :class="$q.screen.xs ? 'q-pb-md' : ''"
                    >
                      <span class="text-h4 text-primary">Vendors</span>
                    </div>
                    <div class="col-xs-12 col-sm-8 col-md-5 col-lg-4 q-mr-none">
                      <div class="row justify-between q-col-gutter-md">
                        <div class="col">
                          <q-input
                            v-model="tableSearchValue"
                            class="full-height"
                            dense
                            label="Search Vendors"
                            outlined
                          />
                        </div>
                        <div class="col-auto">
                          <q-btn
                            class="full-height full-width"
                            color="primary"
                            :disable="loading"
                            icon="download"
                            label="Export"
                            outline
                            @click="exportData"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <q-card-section class="full-height">
                    <table-comp
                      class="ag-theme-alpine grid full-height"
                      :column-defs="columns"
                      :context="context"
                      :default-col-def="defaultColumnDef"
                      :framework-components="frameworkComponents"
                      :loading="loading"
                      :overlay-loading-template="overlayLoadingTemplate"
                      :overlay-no-rows-template="overlayNoRowsTemplate"
                      :row-data="data"
                      @grid-ready="loadData"
                      ref="designGrid"
                    />
                  </q-card-section>
                </div>
              </div>
            </template>
          </card-comp>
        </div>
      </div>
    </q-page>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import TableComp from "@/components/table/TableComp";
import { AdminTableConfig } from "@/views/admin/AdminTableConfig";
import CPTService from "@/services/CPTService";
import router from "@/router";
import VendorService from "@/services/VendorService";
import * as AccountService from "@/services/AccountService";
import CardComp from "@/components/common/CardComp";
import { useAuthenticationHelper } from "@/utils/helpers/useAuthenticationHelper";
import NotificationHelper from "@/utils/helpers/NotificationHelper.js";
import VendorDataExportHelper from "@/utils/helpers/VendorDataExportHelper";

export default {
  name: "AdminPage",
  components: { CardComp, TableComp },
  setup() {
    const frameworkComponents = AdminTableConfig.frameworkComponents;
    const overlayLoadingTemplate = AdminTableConfig.overlay;
    const overlayNoRowsTemplate = AdminTableConfig.overlay;
    const defaultColumnDef = AdminTableConfig.defaultColDef;
    const columns = ref(AdminTableConfig.columns);

    const data = ref();
    const loading = ref(false);
    function loadData() {
      loading.value = true;
      CPTService.getCPTVendorDataWithContractorData().then((response) => {
        data.value = response.data;
        loading.value = false;
      });
    }

    const selectedVendorId = ref();
    function openAction(cellValue) {
      VendorService.doesVendorExist(cellValue).then((vendorExists) => {
        if (vendorExists) {
          goToVendorPage(cellValue);
        } else {
          createNewVendorWithId(cellValue).then(() =>
            goToVendorPage(cellValue)
          );
        }
      });
    }

    function createNewVendorWithId(vendorId) {
      return VendorService.saveVendor(vendorId, {
        contractors: [],
      });
    }

    function goToVendorPage(vendorId) {
      router.push(`/vendor/${vendorId}`);
    }

    const openDrawer = ref(false);

    const tableSearchValue = ref();
    const designGrid = ref();
    watch(
      () => tableSearchValue.value,
      (newVal) => designGrid.value.setQuickFilter(newVal)
    );

    function exportData() {
      if (data.value && data.value.length > 0) {
        VendorDataExportHelper.exportVendorData(data.value);
      } else {
        NotificationHelper.createErrorNotification(
          "There is no data available to export!"
        );
      }
    }

    return {
      filter: ref(""),
      columns,
      data,
      defaultColumnDef,
      frameworkComponents,
      loading,
      overlayLoadingTemplate,
      overlayNoRowsTemplate,
      openDrawer,
      openAction,
      loadData,
      selectedVendorId,
      tableSearchValue,
      designGrid,
      exportData,
    };
  },
  data() {
    return {
      context: {
        componentParent: this,
      },
    };
  },
  beforeCreate() {
    const { email } = useAuthenticationHelper();
    watch(
      () => email.value,
      (newValue) => {
        AccountService.getAccount(`${newValue}`).then((response) => {
          if (response.type !== AccountService.ADMIN_TYPE || !response.type) {
            router.push("/unauthorized");
          }
        });
      }
    );
  },
};
</script>

<style lang="scss">
#admin-page {
  #design-grid {
    flex-grow: 1;

    .grid {
      height: 100%;
    }
  }
}
</style>
