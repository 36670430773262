import { watch } from "vue";

export function useSyncModelValue(
  props,
  propertyName,
  emit,
  emitName,
  editableValue,
  modelValueFormatFunction,
  emitValueFormatFunction,
  emitOnBlur
) {
  watch(
    () => editableValue.value,
    (newVal) => emitNewValue(newVal),
    { deep: true }
  );

  function emitNewValue(newVal) {
    if (!emitOnBlur) {
      if (emitValueFormatFunction) {
        emit(emitName, emitValueFormatFunction(newVal));
      } else {
        emit(emitName, newVal);
      }
    }
  }

  watch(
    () => props[propertyName],
    (newVal) => {
      setEditableValue(newVal);
    },
    { deep: true, immediate: true }
  );

  function setEditableValue(newVal) {
    if (modelValueFormatFunction) {
      editableValue.value = modelValueFormatFunction(newVal);
    } else {
      editableValue.value = newVal;
    }
  }
}
