import firebase from "firebase/app";

export default {
  getLocations() {
    const getLocations = firebase.functions().httpsCallable("getLocations");
    return getLocations().then((response) => {
      return response.data;
    });
  },
};
