<template>
  <div class="row full-width q-col-gutter-md">
    <div class="self-center">
      <q-btn
        class="text-negative"
        flat
        icon="delete"
        round
        @click="$emit('delete', index)"
      />
    </div>
    <div class="self-center">{{ index + 1 }}.</div>
    <div class="col">
      <q-input
        v-model="editableContractor.email"
        autofocus
        dense
        label="Email *"
        lazy-rules
        :rules="[Rule.REQUIRED, Rule.UNUSED_EMAIL]"
        ref="emailInput"
      />
    </div>
    <div class="col">
      <q-input
        v-model="editableContractor.firstName"
        dense
        label="First Name *"
        :rules="[Rule.REQUIRED]"
        ref="firstNameInput"
      />
    </div>
    <div class="col">
      <q-input
        v-model="editableContractor.lastName"
        dense
        label="Last Name *"
        :rules="[Rule.REQUIRED]"
        ref="lastNameInput"
      />
    </div>
    <div class="col">
      <q-input
        v-model="editableContractor.phoneNumber"
        dense
        label="Phone Number"
        mask="(###) ### - ####"
        unmasked-value
        ref="phoneNumberInput"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useSyncModelValue } from "@/utils/helpers/useSyncModelValue";
import { useValidateInputs } from "@/utils/helpers/useValidateInputs";
import Rule from "@/utils/Rule";

export default defineComponent({
  name: "BulkDialogContractorRow",
  props: {
    contractor: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  emits: ["delete", "update:contractor"],
  setup(props, { emit }) {
    const editableContractor = ref();

    useSyncModelValue(
      props,
      "contractor",
      emit,
      "update:contractor",
      editableContractor
    );

    const emailInput = ref();
    const firstNameInput = ref();
    const lastNameInput = ref();
    const phoneNumberInput = ref();
    const inputRefs = [
      emailInput,
      firstNameInput,
      lastNameInput,
      phoneNumberInput,
    ];

    const { resetValidation, validate, hasError } =
      useValidateInputs(inputRefs);

    return {
      editableContractor,
      Rule,
      emailInput,
      firstNameInput,
      lastNameInput,
      phoneNumberInput,
      inputRefs,
      resetValidation,
      validate,
      hasError,
    };
  },
});
</script>

<style lang="scss"></style>
